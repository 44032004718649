import axios from "axios";
import { API_URL } from "../../api-routes";

export async function getMetrics() {
    try {
        let url = `${API_URL}/metrics`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}