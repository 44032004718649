<template>
    <div class="mainContainer sections full-height full-width bg--secondary-grey" style="position:relative">

        <div class="background full-width full-height" />

        <a-row
            class="full-width full-height center-row-center containerColumns"
            :gutter="[24,32]"
        >
            <a-col :span="12" :md="12" :sm="24" :xs="24" class="flex-column center-column-center">
                <a-space class="full-width" direction="vertical" :size="24">
                    <a-row :gutter="[8, 8]" style="width: 50%; margin: 0 auto" justify="space-between" align="middle">
                        <a-col :md="12" :xs="12" class="flex-column center-column-center" >
                            <div class="styleImage">
                                <img 
                                    :src="library.reports"
                                    class="image"
                                />
                            </div>
                        </a-col>
                        <a-col :md="12" :xs="12" class="flex-column center-column-center ">
                            <div class="styleImage">
                                <img 
                                    :src="library.books"
                                    class="image"
                                />
                            </div>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[8, 8]" style="width: 50%; margin: 0 auto" justify="space-between" align="middle">
                        <a-col :md="12" :xs="12" class="flex-column center-column-center ">
                            <div class="styleImage">
                                <img 
                                    :src="library.conferences"
                                    class="image"
                                />
                            </div>
                        </a-col>
                        <a-col :md="12" :xs="12" class="flex-column center-column-center ">
                            <div class="styleImage">
                                <img 
                                    :src="library.trends"
                                    class="image"
                                />
                            </div>
                        </a-col>
                    </a-row>

                </a-space>

            </a-col>

            <a-col :span="12" :md="12" :sm="24" :xs="24">

                <a-avatar
                    :size="70"
                    shape="square"
                    :src="library.icon"
                    class="icon"
                />

                <a-space :size="16" direction="vertical">
                    <Headers
                        :showLine=headers.line
                        :title=headers.title
                        :description=headers.description
                        :align="'right'"
                    />
                    <a-button
                        type="primary"
                        size="large"
                        style="float: right"
                        @click="goToSection()"
                    >
                        <span class="tp-regular uppercase">
                            {{ $t('HOME.LIBRARY.LINK')}}
                        </span>
                    </a-button>
                </a-space>

            </a-col>
        </a-row>
    </div>
</template>

<script>
import { IMAGES } from "@/constants/images.js";

export default {
    data: function() {
        return {
            headers: {
                title: "HOME.LIBRARY.TITLE",
                description: "HOME.LIBRARY.DESCRIPTION",
                line: true
            }
        };
    },

    computed: {
        background () {
            return IMAGES.backgrounds.library
        },
        library () {
            return IMAGES.home.library
        }
    },
    methods: {
        goToSection () {
            this.$router.push("/library");
        },
        getBackground () {
            return {
                "background-image": "url(" + this.background + ")",
            };
        },
    },
};
</script>


<style lang="scss" scoped>
@import '@/styles/scss/config.scss';

.containerColumns {
    display: block;

    @include breakpoint(md) {
        display: flex;
    }
}

.background {
    left: 0;
    opacity: 0.2;
    position: absolute;
    top: 0;
}

.icon {
    display: none;
    position: absolute;
    right: -70px;
    top: 70px;

    @include breakpoint(lg) {
        display: flex;
    }
}

.styleImage {
    border: solid 2px $color-primary;
    border-radius: 4px;
    padding: 16px;
    width: 100px;
    height: 100px;
    position: relative;
    background: $color-white;
    transition: all 0.2s 0.2s;

    @include breakpoint(md) {
        width: 100px;
        height: 100px;
    }
    
    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

</style>