<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",

  async created() {
    await this.detectDevice();
  },

  methods: {
    ...mapActions("device", {
      setDevice: "setDeviceType",
    }),

    detectDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const regexp = /android|iphone|kindle|ipad/i;
      const isMobile = regexp.test(userAgent);

      const payload = {
        userAgent,
        isMobile,
      };
      this.setDevice(payload);
    },
  },
};
</script>
