export default {
    SET_PERSONAL(state, personalData) {

    },

    SET_INSTITUTIONAL(state, institutionalData) {

    },

    SET_MEMBERSHIP(state, membershipData) {

    },

    SET_ACCOUNT(state, accountData) {

    },
}