<template>
    <div 
        class="flex-column start-column-center full-width full-height cardArticle"
        @click="goToDetail"
    >
        <a-tooltip placement="right" class="full-width full-height">
            <a-space direction="vertical" :size="8" slot="title" class="full-width">
                <span class="text text--level-3 full-width cl--secondary-white">
                    {{ data.title }}
                </span>
            </a-space>

            <div class="picture">
                <div class="mask" />
                <a-avatar 
                    :src="data.image.original || portrait"
                    shape="square"
                    class="avatar picture"
                />
                <span class="category cl--secondary-white text text--level-3">
                    {{ data.categoryID[language] }}
                </span>
            </div>


            <a-space direction="vertical" :size="4" class="containerInfo full-width">
                <span class="title text--level-3 cl--primary-blue name full-width left info header">
                    {{ data.title }}
                </span>

                <span class="text text--level-3 cl--primary-blue date italic full-width left info">
                    {{ `${$t('LIBRARY.PUBLISHED_IN')} ${formatDate(data.createdAt) || $t('LIBRARY.UNKNOWN')}` }}
                </span>
            </a-space>

            <div class="infoUser">    
                <a-list-item-meta v-if="data.authorID">
                    <a-avatar :src="data.authorID.image.original" slot="avatar" :size="40"/>
                    <span slot="title" class="title text--level-3 cl--primary-blue">
                        {{
                            `${name}
                            ${lastName}`
                        }}
                    </span>
                    <a-avatar
                        :src="getFlag(data.authorID.country)"
                        class="flag-avatar"
                        :size="32"
                        shape="square"
                        slot="description"
                    />
                </a-list-item-meta>
            </div>

        </a-tooltip>

    </div>
</template>

<style lang="scss">
@import '@/styles/scss/config.scss';

.cardArticle {
    box-shadow: -4px 4px 0px -1px rgba(0,94,189,1);
        -webkit-box-shadow: -4px 4px 0px -1px rgba(0,94,189,1);
        -moz-box-shadow: -4px 4px 0px -1px rgba(0,94,189,1);

    position: relative;
    width: 100%;
    border: solid 0.15px $color-primary-alt;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.15s 0.15s ease-in-out;

    .picture {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 200px;
        display: flex;

        .avatar {
            img {
                object-fit: cover;
            }
        }

    }

    .containerInfo {
        padding: 16px 24px;
    }

    .mask {
        background: rgba(252,252,252,0.7);
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        visibility: visible;
        opacity: 1;
        transition: all 0.18s 0.18s ease-in-out;
    }

    .category {
        background: $color-primary-alt;
        padding: 4px 12px;
        border-top-left-radius: 6px;
        width: auto;
        float: left;
        text-transform: capitalize;
        display: block;

        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 6;
    }

    .info {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        &.date {
            font-size: 11px;
        }

        &.header {
            text-transform: capitalize !important;
        }
    }

    .infoUser {
        padding: 0 24px;
        padding-bottom: 24px;
    }

    .flag-avatar {
        width: 40px !important;
        height: 24px !important;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

}

</style>

<script>
import { IMAGES } from '@/constants/images';
import * as utils from '@/utils';

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb'
import 'moment/locale/pt-br'


export default {
    data: () => {
        return { 
            portrait: IMAGES.backgrounds.article,
            languageKeys: [
                'es',
                'en',
                'pt',
            ]
        };
    },
    computed: {
        name() {
            const formatName = this.data.authorID.name.split(" ")[0];
            return formatName;
        },
        lastName () {
            const formatLastName = this.data.authorID.lastName.split(" ")[0];
            return formatLastName;
        },
        language () {
            const lngKey = this.$i18n.locale;
            return lngKey.toString().toUpperCase();
        }
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        goToDetail () {
            this.$router.push(`/ktdra-channel/${this.data._id}`);
        },

        getFlag (country) {
            return utils.getFlag(country);
        },

        formatDate (date) {
            moment.locale(this.$i18n.locale);
            return moment(date).format('LL');
        }
    },
}
</script>

