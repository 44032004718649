<template>
  <div>
    <a-modal 
        class="containerContact"
        centered
        v-model="showNotify"
        :closable="false"
        :footer="null"
        :bodyStyle="stylesForm"
    >
        <UpdateData @changeStateNotify="(value) => changeStateNotify(value)"/>
    </a-modal>

    <Navbar 
        @updateSection="updateSection"
        @handleNavbarMobile="(value) => handleNavbarMobile(value)"
    />
    <NavbarMobile 
        :active="activeNavbarMobile"
        @updateSection="(value) => updateSection(value)"
        @close="() => activeNavbarMobile = false"
    />
    <Portada />
    <Sections :activeSection="activeSection" />
    <ScheduleRectoral id="rectoral-board-events" />
    <Members id="signatory-members" />
    <Webinars id="webinars-rectoral" />
    <Portfolio id="briefcase" />
    <Library id="library" />
    <Ktdra id="ktdra" />
    <News id="board-news" />
    <Footer />
  </div>
</template>

<style lang="scss">
@import '@/styles/scss/config.scss';
</style>

<script>
import Portada from "@/components/home/Portada";
import Sections from "@/components/home/Sections";
import ScheduleRectoral from "@/components/home/ScheduleRectoral";
import Members from "@/components/home/Members";
import Webinars from "@/components/home/Webinars";
import Portfolio from "@/components/home/Portfolio";
import Library from "@/components/home/Library";
import Ktdra from "@/components/home/Ktdra.vue";
import News from "@/components/home/News.vue";

import UpdateData from "@/components/home/UpdateData";

import { scroller } from "vue-scrollto/src/scrollTo";

export default {
    name: "home",
    data: () => {
        return {
            stylesForm: {
                padding: '0',
                borderRadius: '8px !important'
            },
            showNotify: true,
            activeSection: "about-us",
            activeNavbarMobile: false,
        };
    },
    components: {
        Portada,
        Sections,
        ScheduleRectoral,
        Members,
        Webinars,
        Portfolio,
        Library,
        Ktdra,
        News,
        UpdateData,
    },
    mounted() {
        const fullPath = this.$route.fullPath;
        const section = fullPath.split("=")[1];
        this.updateSection(section);
    },
    methods: {
        updateSection(keySection) {
            let scroll = scroller();
            scroll(`#${keySection}`);
            this.activeSection = keySection;
        },
        handleNavbarMobile(value) {
            this.activeNavbarMobile = value;
        },
        changeStateNotify(value) {
            this.showNotify = value
        }
    },
};
</script>
