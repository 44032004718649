<template>
    <div v-if="event">
        <a-space direction="vertical" class="full-width" :size="12">
            <span slot="title" class="title title--level-2 cl--primary-blue full-width flex-column start-column-center">
                <a-space direction="horizontal" class="full-width">
                    {{ `${event.tag} ${$t('WEBINARS.INTERVIEW')}` }}
                    -
                    {{ $t(`WEBINARS.${event.category.keyName}.NAME`) }}
                </a-space>
                <span class="text text--level-2 cl--primary-blue justify" style="margin-top: 12px">
                    {{ $t(`WEBINARS.${event.category.keyName}.DESCRIPTION`) }}
                </span>
            </span>

            <a-row :gutter="[24, 24]" class="center-row-center full-height">
                <a-col :span="24" :md="24" :sm="24" :xs="24" class="full-height">
                    <a-avatar
                        v-if="event.banner.image !== ''"
                        :src="event.banner.image"
                        class="full-width full-height"
                        shape="square"
                    />
                    <div
                        class="flex-column center-column-center full-height"
                        v-else
                    >
                        <span class="title text--level-2 cl--primary-blue">
                            {{ $t('WEBINARS.NO_BANNER') }}
                        </span>
                    </div>
                </a-col>
            </a-row>

        </a-space>
    </div>
</template>

<script>
import { WEBINARS } from '@/constants/webinars';
import { IMAGES } from '@/constants/images';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb'
import 'moment/locale/pt-br'

export default {
    data: () => {
        return {
            zoomIcon: IMAGES.icons.zoom,
            transmissionChannels: [
                {
                    name: 'Facebook',
                    icon: IMAGES.transmission.facebook,
                    value: ''
                },
                {
                    name: 'UTPL',
                    icon: IMAGES.transmission.utpl,
                    value: ''
                },
                {
                    name: 'Youtube',
                    icon: IMAGES.transmission.youtube,
                    value: ''
                }
            ]
        }
    },
    computed: {
        webinarData () {
            return WEBINARS;
        }
    },
    props: {
        event: {
            type: Object,
            required: true
        }
    },
    methods: {
        changeStateModal(value) {
            this.$emit("changeStateModal", null, value);
        },

        goToLink(link) {
            window.open(link, '_blank');
        },

        formatDate (date) {
            moment.locale(this.$i18n.locale);
            return moment(date).format('LLLL');
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';
.link {
    background: #F5F5F5;
    padding: 8px 16px;
    border-radius: 6px;
    float: right;
}

.containerChannels {
    padding: 8px 16px;
    justify-content: space-between;
}

.channel {
    width: 180px;
    height: auto;
    margin: 0 12px;
}
</style>
