<template>
    <a-space direction="vertical" :size="40" class="full-width">

        <a-row :gutter="[16,24]" class="center-row-center full-width">
            <a-col :lg="16" :xs="24">
                <a-form-item class="full-width">
                    <a-input
                        :placeholder="$t('FORMS.SEARCH')"
                        v-model="search"
                        :disabled="loaded"
                        allowClear
                    />
                </a-form-item>
            </a-col>
            <a-col :lg="8" :xs="24">
                <a-select
                    allowClear
                    class="full-width"
                    v-model="category"
                    :disabled="loaded"
                    :placeholder="$t('FORMS.SELECT_CATEGORY')"
                >
                    <a-select-option
                        v-for="category in categories"
                        :value="category._id"
                        :key="category._id"
                    >
                        {{ category[language] }}
                    </a-select-option>
                </a-select>
            </a-col>
        </a-row>

        <Loader :type="type" v-if="loaded" />

        <div v-else class="containerListArticles">
            <a-list
                :grid="{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 4 }" 
                :data-source="articles"
            >
                <a-list-item slot="renderItem" slot-scope="item">
                    <Article :data=item />
                </a-list-item>
            </a-list>
        </div>
    </a-space>
</template>

<style lang="scss">
@import '@/styles/scss/config.scss';

.containerListArticles {
    height: 800px;
    max-height: 800px;
    overflow-y: scroll;
    overflow-x: hidden;
}
</style>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
    export default {
        data: () => {
            return {
                loaded: true,
                type: 1,
                params: {
                    limit: 100,
                    page: 1,
                },
                languageKeys: [
                    'es',
                    'en',
                    'pt',
                ]
            }
        },
        async created() {
            await this.initialData();
        },

        beforeDestroy() {
            this.clearArticles();
        },

        computed: {
            ...mapState({
                landing: "landing" 
            }),

            ...mapGetters("landing", {
                articles: "getArticles",
                meta: "getMetaArticles",
                categories: "getCategoriesArticles",
            }),

            language () {
                const lngKey = this.$i18n.locale;
                return lngKey.toString().toUpperCase();
            },

            search: {
                get() {
                    return this.landing.articles.search;
                },

                set(value) {
                    this.searchArticles(value);
                }
            },

            category: {
                get() {
                    return this.landing.articles.category;
                },

                set(value) {
                    this.landing.articles.category = value;
                }
            },
        },


        methods: {
            ...mapActions("landing", {
                getArticlesStore: "getArticles",
                searchArticles: "searchArticles",
                setDefaultArticlesStore: "setDefaultArticles",
            }),

            async initialData() {
                await this.clearArticles();
                if (!this.articles.length) {
                    await this.getArticlesStore(this.params);
                    this.loaded = false;
                }
            },

            async clearArticles() {
                await this.setDefaultArticlesStore();
            },
        },
    }
</script>