<template>
    <div class="comments">
        <div class="comments--comment" v-for="comment in comments" :key="comment._id">
            <a-page-header>
                <template slot="title">
                    <div className="flex-row start-row-center">
                        <a-avatar
                            :src="comment.userID.image.original || avatar"
                            :size="28"
                            style="vertical-align: middle;"
                        />
                        <span class="title text--level-2 left cl--primary-blue">
                            {{ `${comment.userID.name} ${comment.userID.lastName}` }}
                        </span>
                    </div>
                </template>
            </a-page-header>
            <div style="padding-left: 24px;">
                <a-space direction="vertical" :size="0">
                    <span class="text text--level-3 left cl--secondary-black">
                        {{comment.content}}
                    </span>
                    <span class="comments--date text">
                        {{formatDate(comment.createdAt)}}
                    </span>
                </a-space>

                <Metrics
                    :likes="comment.likes.length || 0"
                    :opinions="comment.responses.length || 0"
                />

                <div className="comments" style="padding-top: 16px; padding-left: 16px;">
                    <a-list
                        :data-source="comment.responses"
                        :split="false"
                    >
                        <a-list-item
                            slot="renderItem"
                            slot-scope="response"
                            :key="response._id"
                            style="padding: 8px;"
                        >
                            <div class="comments--comment response">
                                <a-page-header>
                                    <template slot="title">
                                        <div className="flex-row start-row-center">
                                            <a-avatar
                                                :src="response.userID.image.original || avatar"
                                                :size="28"
                                                style="vertical-align: middle;"
                                            />
                                            <span slot class="title text--level-2 left cl--primary-blue">
                                                {{ `${response.userID.name} ${response.userID.lastName}` }}
                                            </span>
                                        </div>
                                    </template>
                                </a-page-header>

                                <div style="padding-left: 24px;">
                                    <a-space direction="vertical" :size="0">
                                        <span class="text text--level-3 left cl--secondary-black">
                                            {{response.content}}
                                        </span>
                                        <span class="comments--date text">
                                            {{formatDate(response.createdAt)}}
                                        </span>
                                    </a-space>

                                    <Metrics
                                        :likes="response.likes.length || 0"
                                    />
                                </div>
                            </div>
                        </a-list-item>
                    </a-list>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import '@/styles/scss/config.scss';

.comments {
    margin-top: 24px;
    border-radius: 4px;
    
    &--comment {
        background-color: #deecf9;
        border-radius: 8px;
        padding: 8px 16px;
        padding-bottom: 16px;
        width: 100%;

        &.response {
            padding-left: 0;
            background-color: #eff6fc;
        }
    }

    &--date {
        font-size: 10px !important;
        font-weight: 400;
        color: #605e5c;
    }

    &--actions {
        margin-top: 12px;
        width: 100%;
        gap: 16px;
        span {
            color: $color-primary;
        }
    }
    
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { PageHeader } from "ant-design-vue";
import Metrics from "./Actions.vue"
import { IMAGES } from '@/constants/images';
import moment from 'moment';

export default {
    data: () => ({
        loaded: true,
        avatar: IMAGES.dashboard.default_avatar
    }),
    async created() {
        await this.initialData();
    },

    computed: {
        ...mapGetters("landing", {
            comments: "getComments",
        }),
    },

    components: {
        "a-page-header": PageHeader,
        Metrics,
    },

    methods: {
        ...mapActions("landing", {
            getCommentsStore: "getComments",
        }),

        async initialData() {
            const { articleID } = this.$route.params;
            if (articleID) {
                await this.getCommentsStore(articleID);
                this.loaded = false;
            }
        },

        formatDate (date) {
            moment.locale(this.$i18n.locale);
            return moment(date).fromNow();
        }
    }
}
</script>