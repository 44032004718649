<template>
    <div class="libraryDocument" @click="goToDocument(data.externalLink)">
        <a-tooltip placement="right" v-if="data.type === 'BENCHMARK'">
            <a-space direction="vertical" :size="8" slot="title" class="full-width">
                <span class="text text--level-3 full-width cl--secondary-white" v-if="data.title">
                    {{ data.title }}
                </span>
                <span class="text text--level-3 cl--secondary-white date italic full-width left info">
                    {{ data.date }}
                </span>
            </a-space>

            <div class="video">
                <span
                    class="videoFrame"
                    v-html="data.iframe"
                />
            </div>
        </a-tooltip>

        <a-tooltip placement="right" v-else>
            <a-space direction="vertical" :size="8" slot="title" class="full-width">
                <span class="text text--level-3 full-width cl--secondary-white">
                    {{ data.title }}
                </span>
                <span class="text text--level-3 full-width cl--secondary-white">
                    {{ data.dataSheet.author  || $t('LIBRARY.UNKNOWN')}}
                </span>
            </a-space>
            <div class="portrait">
                <a-avatar
                    :src="data.image.original || portrait"
                    shape="square"
                    class="avatar"
                />
            </div>
            <div>
                <span class="title text--level-3 cl--primary-blue name full-width left info">
                    {{ data.title }}
                </span>
                <span class="text text--level-3 cl--primary-blue author full-width left info">
                    {{ data.dataSheet.author || $t('LIBRARY.UNKNOWN')}}
                </span>
                <span class="text text--level-3 cl--primary-blue date italic full-width left info">
                    {{ data.dataSheet.publisherYear ? `${$t('LIBRARY.PUBLISHED_IN')} ${data.dataSheet.publisherYear}` : '' }}
                </span>
            </div>
        </a-tooltip>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
.libraryDocument {
    padding: 0 24px;
    margin-bottom: 32px;

    .portrait {
        margin-bottom: 16px;
        background-color: $color-primary;

        .avatar {
            width: 100%;
            height: auto;
            max-height: 200px;
            margin: 0 auto;
            display: block;
            overflow: hidden;

            img { object-fit: cover; }
        }
    }

    .info {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        &.date {
            font-size: 11px;
        }
    }

    .video {
        width: 100%;
        height: 100%;
    }

    .videoFrame {
        width: 100%;
        height: 100%;

        iframe{
            width: 100% !important;
            height: 100% !important;
        }
    }
}

</style>

<script>
import { IMAGES } from '@/constants/images'
export default {
    data: () => {
        return { 
            portrait: IMAGES.home.library.portrait
        };
    },
    props: {
        category: {
            type: Object,
            default: () => {}
        },
        data: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        goToDocument(url) {
            if (this.data.type !== 'BENCHMARK') {
                window.open(url, '_blank');
            }
        }
    }
}
</script>