<template>
    <div class="mainContainer sections full-width full-height">
        
        <Headers
            :showLine="headers.line"
            :title="headers.title"
            align="left"
        />

        <a-divider style="background: #1b4484"/>

        <a-row
            class="containerSections"
            align="middle"
            v-for="section in sections"
            :key="section.index"
        >
            <a-col :xs="24" :md="24" class="containerCategory">
                <a-space direction="horizontal" :size="16" style="width: 100%">
                    <a-list-item-meta :description="$t(section.target)">
                        <a-avatar 
                            :src="section.icon"
                            slot="avatar"
                            shape="square"
                            :size="56"
                        />
                        <span 
                            class="title text--level-1" 
                            :style="`color: ${section.color}`"
                            slot="title"
                        >
                            {{ $t(`MEMBERSHIPS.${section.category}`) }}
                        </span>
                    </a-list-item-meta>
                </a-space>
            </a-col>

            <a-col :xs="24" :md="24" :style="`background: ${section.color}`" class="containerPurpose">
                <span class="title text--level-3 cl--secondary-white" style="margin-bottom: 8px; display: flex">
                    {{ $t('INSTITUTIONAL.TARGET_AUDIENCES.PURPOSE') }}
                </span>
                <span class="text text--level-3 cl--secondary-white">
                    {{ $t(section.purpose) }}
                </span>
            </a-col>
        </a-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';

$border: 6px;

.containerSections {
    margin-top: 56px;
    height: auto;
    width: 100%;

    .containerCategory {
        height: 100%;
        padding: 16px;
        border-top-left-radius: $border;
        border-top-right-radius: $border;
        background: $color-grey-alt-1;
    }

    .containerPurpose {
        height: 100%;
        padding: 16px;
        border-bottom-left-radius: $border;
        border-bottom-right-radius: $border;
    }
}
</style>

<script>
import { MEMBERSHIPS } from "@/constants/memberships";
import { IMAGES } from "@/constants/images";

export default {
    data() {
        return {

            headers: {
                title: "INSTITUTIONAL.TARGET_AUDIENCES.HEADER",
                line: false
            },

            sections: [
                {
                    index: 0,
                    color: "#1b4484",
                    category: "GOLD",
                    icon: IMAGES.memberships.gold,
                    target: MEMBERSHIPS.GOLD.target,
                    purpose: "INSTITUTIONAL.TARGET_AUDIENCES.GOLD"
                },
                {
                    index: 1,
                    color: "#1976d2",
                    category: "PLATINUM",
                    icon: IMAGES.memberships.platinum,
                    target: MEMBERSHIPS.PLATINUM.target,
                    purpose: "INSTITUTIONAL.TARGET_AUDIENCES.PLATINUM"
                },
                {
                    index: 2,
                    color: "#005EBB",
                    category: "DIAMOND",
                    icon: IMAGES.memberships.diamond,
                    target: MEMBERSHIPS.DIAMOND.target,
                    purpose: "INSTITUTIONAL.TARGET_AUDIENCES.DIAMOND"
                }
            ]

        };
    }
}
</script>
