<template>
    <div class="full-width containerBriefcase">

        <!-- MODAL QUOTE -->
        <a-modal
            class="containerContact"
            v-model="openModalQuote"
            width="75%"
            :closable="false"
            :footer="null"
            :bodyStyle="stylesForm"
        >
            <QuoteMentoring @changeStatusModal="(status) => changeStatusModal(status)" />
        </a-modal>
        <!-- END MODAL QUOTE -->

        <div 
            class="imagePortrait"
            :style="getBackground()"
        >
            <a-avatar
                :src="icon"
                class="icon shadow"
            />
        </div>
        <div class="containerInfo container">
            <a-row justify="space-around" align="middle" :gutter="[24, 32]">
                <a-col :lg="16" :xs="24">
                    <a-space direction="vertical" :size="24">
                        <span class="title cl--primary-blue">
                            {{ $t(sections[sectionActive].title) }}
                        </span>
                        <div class="description">
                            <span class="text text--level-2 cl--primary-blue justify">
                                {{ $t(sections[sectionActive].description) }}
                            </span>
                        </div>
                    </a-space>
                </a-col>
                <a-col :lg="8" :xs="24">

                    <div class="containerOptions">
                        <a-row class="full-width">
                            <a-col  
                                v-for="(section, index) in sections"
                                :class="{
                                    option: true, 
                                    active: index === sectionActive ? true : false,
                                }"
                                :md="24" :xs="24"
                                :key="index"
                                @click="sectionActive = index"
                            >
                                <span class="title text--level-3 cl--primary-blue center">
                                    {{ $t(section.title) }}
                                </span>
                            </a-col>

                            <a-button
                                type="primary"
                                class="full-width button"
                                @click="changeStatusModal(true)"
                            >
                                <span class="title text--level-3 cl--secondary-white center">
                                    {{ $t('BRIEFCASE.SECTIONS.MENTORING_180.QUOTE_MENTORING') }}
                                </span>
                            </a-button>
                        </a-row>
                    </div>

                </a-col>
            </a-row>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
</style>

<script>
import { IMAGES } from "@/constants/images";
import QuoteMentoring from "@/components/briefcase/quotes/QuoteMentoring.vue"

export default {    
    data: () => {
        return {
            imageUrl: IMAGES.services.back_mentoring,
            icon: IMAGES.services.mentoring,
            sectionActive: 0,
            sections: [
                {
                    title: "BRIEFCASE.SECTIONS.MENTORING_180.TITLE",
                    description: "BRIEFCASE.SECTIONS.MENTORING_180.DESCRIPTION",
                },
                {
                    title: "BRIEFCASE.SECTIONS.MENTORING_180.ROAD_MAP",
                    description: "BRIEFCASE.SECTIONS.MENTORING_180.ROAD_MAP_DESCRIPTION",
                },
                {
                    title: "BRIEFCASE.SECTIONS.MENTORING_180.FEEDBACK",
                    description: "BRIEFCASE.SECTIONS.MENTORING_180.FEEDBACK_DESCRIPTION",
                },
                {
                    title: "BRIEFCASE.SECTIONS.MENTORING_180.COMPLIANCE",
                    description: "BRIEFCASE.SECTIONS.MENTORING_180.COMPLIANCE_DESCRIPTION",
                }
            ],
            openModalQuote: false,
            stylesForm: {
                padding: '0',
                borderRadius: '8px !important'
            },
        };
    },

    components: {
        QuoteMentoring,
    },

    methods: {
        getBackground () {
            return {
                "background-image": "url(" + this.imageUrl + ")",
            };
        },

        changeStatusModal(status) {
            this.openModalQuote = status;
        }
    },
}
</script>