<template>
    <div>
        <span
            slot="title"
            class="title title--level-3 cl--secondary-white bg--primary-blue full-width flex-row start-row-center headerContact"
        >
            {{ $t('BRIEFCASE.QUOTE_MENTORING.TITLE') }}
        </span>

        <a-form layout="vertical" @submit.prevent="sendQuoteMentoring" :form="form">
            <div class="containerForm full-width full-height" style="padding: 24px; max-height: 600px; overflow-y: scroll">
                <a-row :gutter="16" class="center-row-center">
                    <a-col :span="24" :md="24" :sm="24" :xs="24">
                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.NAMES')}`">
                            <a-input
                                v-decorator="[
                                    'names',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.NAMES')}`"
                            >
                                <a-icon slot="prefix" type="user" />
                            </a-input>
                        </a-form-item>
                        
                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.EMAIL')}`">
                            <a-input
                                v-decorator="[
                                    'email',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.EMAIL')}`"
                            >
                                <a-icon slot="prefix" type="mail" />
                            </a-input>
                        </a-form-item>

                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.INSTITUTE')}`">
                            <a-input
                                v-decorator="[
                                    'institute',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.INSTITUTE')}`"
                            >
                                <a-icon slot="prefix" type="bank" />
                            </a-input>
                        </a-form-item>

                        <CountryCodeSelector
                            @changeCountryCode="(value) => changeCountryCode(value)"
                            @changeMobile="(value) => changeMobile(value)"
                        />

                        <a-form-item
                            :label="`${$t('BRIEFCASE.QUOTES.TYPE')}`"
                        >
                            <a-select
                                class="full-width"
                                v-decorator="[
                                    'type',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :disabled="loading"
                                default-value="SINGLE"
                            >
                                <a-select-option
                                    v-for="type in mentoringTypes"
                                    :key="type"
                                    :value="type"
                                >
                                    {{ $t(`BRIEFCASE.QUOTES.${type}`) }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.CONTEXTUALIZE')}`">
                            <a-textarea
                                v-decorator="[
                                    'contextualize',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.CONTEXTUALIZE')}`"
                                :auto-size="{ minRows: 3, maxRows: 6 }"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>

            </div>
            <div class="flex-row end-row-end full-width" style="padding: 0 24px 24px">
                <a-space direction="horizontal" :size="24">

                    <a-button
                        ghost
                        size="large"
                        type="primary"
                        @click="changeStatusModal(false)"
                    >
                        {{ $t('CONTACT.CANCEL') }}
                    </a-button>

                    <a-button
                        :loading="loading"
                        size="large"
                        type="primary"
                        html-type="submit"
                    >
                        {{ $t('CONTACT.SEND') }}
                    </a-button>

                </a-space>
            </div>
        </a-form>


    </div>
</template>

<script>
import * as quotesAPI from "@/api/quotes.js";
import { notification } from "ant-design-vue";

export default {
    data: () => {
        return {
            loading: false,
            mentoringTypes: [
                "SINGLE",
                "COLLECTIVE"
            ],
            names: '',
            email: '',
            phone: '',
            institute: '',
            type: "SINGLE",
            contextualize: '',
            countryCode: '',
            mobile: '',
        }
    },

    beforeCreate() {
		this.form = this.$form.createForm(this, { name: "planRequest" });
	},

    components: {
        notification,
    },

    methods: {
        changeStatusModal(value) {
            this.$emit("changeStatusModal", value)
        },

        getFormValues (values) {
            const payload = {
                ...values,
                fullMobile: `${this.countryCode}${this.mobile}`,
            }
            return payload;
        },

        sendQuoteMentoring (e) {
            this.loading = true;
            e.preventDefault();
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    const payload = this.getFormValues(values);
                    const response = await quotesAPI.createMentoringQuote(payload);
                    this.mappedResponse(response);
                }
            });
            this.loading = false;
        },

        mappedResponse (response) {
            if (response.statusCode === 201) {
                notification.success({
                    message: this.$t('RESPONSES.SUCCESS'),
                    description: this.$t('BRIEFCASE.QUOTES.CREATED_QUOTE'),
                });
                this.changeStatusModal(false);
                this.mobile = '';
                this.form.resetFields();
            } else if (response.statusCode === 409) {
                notification.warning({
                    message: this.$t('RESPONSES.WARNING'),
                    description: this.$t('BRIEFCASE.QUOTES.ALREADY_QUOTE_EXISTS'),
                });
            } else {
                notification.error({
                    message: this.$t('RESPONSES.ERROR'),
                    description: this.$t('BRIEFCASE.QUOTES.ERROR_CREATING_QUOTE'),
                });
            }
        },

        changeCountryCode(value) {
            this.countryCode = value;
        },

        changeMobile(value) {
            this.mobile = value;
        },
    }
}
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';

.headerContact {
    padding: 16px 24px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
</style>