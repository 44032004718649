<template>
    <div class="flex-column center-column-center full-width">
        <a-space class="full-width" direction="vertical" :size="8">
            <a-icon type="loading" :style="{ fontSize: '32px', margin: '0 auto' }" class="cl--primary-blue flex-row center-row-center" />
            <span class="title text--level-2 cl--primary-blue center full-width">
                {{ type === 0 ? $t('LOADING') : $t('LOADING_DATA') }}
            </span>
        </a-space>
    </div>
</template>

<style lang="scss">
@import "@/styles/scss/config.scss";
</style>

<script>
export default {
    props: {
        type: {
            type: Number,
            default: 0
        }
    }
}
</script>