<template>
	<div>
		<Navbar
			:language_active="language.active"
			@updateLanguage="updateLanguage"
			@updateSection="updateSection"
		></Navbar>
		<div id="div_to_scrool" class="div_to_scrool"></div>
		<Portada :language_active="language.active"></Portada>

		<div 
			id="singleArticle"
			class="sigle-article flex-column-center start-column-center"
		>
			<div class="full-size flex-column-center start-column-center" v-if="article.exist">
				<div class="header-article flex-column-center start-column-center">
					<span class="title">{{ article.data.title}}</span>
					<div class="flex-row-center start-row-start">
						<div class="middle-left" v-if="article.data.url_image !== ''">
							<div class="cont-img-article">
								<img :src="article.data.url_image" />
							</div>
						</div>

						<div :class="article.data.url_image === '' ?  'no-margin full-width middle-right' : 'middle-right'">
							<span class="wt summary">{{ article.data.summary }}</span>
						</div>
					</div>
				</div>

				<pre class="wt description">{{ article.data.description }}</pre>

				<!-- Keywords -->
				<span class="bl">Keywords</span>
				<div class="cont-tags flex-row-center start-row-center">
					<span
						class="tag"
						:class="getStyleTag(index)"
						v-for="(tag, index) in article.data.keywords"
						:key="'tag_' + index"
					>
						{{ tag }}
					</span>
				</div>

				<!-- Coautores -->
				<span class="bl coautores" v-if="article.data.colabs.length > 0">{{ labels.coautores[language.active] }}</span>

				<div 
					class="cont-coautores flex-column-center start-column-start"
					v-if="article.data.colabs.length > 0"
				>

					<div class="slider">
						<div
							:key="s.key"
							v-for="s in article.slider.slides.items"
							:class="getClassSlideItem(s.index)"
						>
							<div
								:key="'colab_' + pos"
								v-for="(i, pos) in s.item"
								:class="getClassItem()"
								v-show="i !== null"
							>
								<span 
									v-if="i !== null" 
									class="coautor"
								>
									{{ i.names + " " + i.surnames }}
								</span>
							</div>
						</div>
					</div>

					<div class="buttons flex-row-center center-row-center" v-if="article.data.colabs.lenght > 2">
						<div
							:key="btn.key"
							v-for="btn in article.slider.slides.buttons"
							:class="getClassButton(btn.index)"
							@click="changeActiveSection(btn.index)"
						></div>
					</div>

				</div>

				<!-- Actions -->
				
				<span class="bl actions">{{ labels.actions[language.active] }}</span>

				<div class="cont-actions flex-row-center start-row-center">

					<div 
						class="cont-button flex-row-center center-row-center"
						v-for="(action, index) in actions"
						:key="'action_' + index"
						@click="getAction(index)"
					>
						<font-awesome-icon :icon="['fas', action.icon]" class="icon"/>
						<span class="txt-btn">{{ action.label[language.active] }}</span>
					</div>

					<span 
						class="alert"
						v-if="alert_copy.show"
					>
						{{ alert_copy.message[language.active] }}
					</span>

				</div>

				<!-- Comments -->
				<span class="bl comments">{{ labels.comments[language.active] }}</span>
				
				<!-- Aqui preguntamos la condicion si la lista de comentarios esta vacia -->
				<div 
					class="flex-row-center center-row-center full-width" 
					v-if="article.comments.length === 0"
					style="margin-bottom: 1vw;"
				>
					<important :message="labels.empty_comments[language.active]"></important>
				</div>

				<div class="cont-comments flex-column-center start-column-center">
					<div class="list flex-column-center start-column-center" v-if="article.comments.length > 0">
						<div
							v-for="comment in article.comments"
							:key="comment.id"
							class="comment"
						>
							<div :id="'comment_' + comment.id" class="id_comment">{{ comment.id }}</div>
							<div class="flex-row-center start-row-start">
								<div class="middle-left flex-row-center center-row-start">
									<div class="cont-picture">
										<img :src="comment.data.user.picture" alt="" />
									</div>
								</div>

								<div class="middle-right flex-column-center start-column-start">
									<span class="name">
										{{ comment.data.user.names + " " + comment.data.user.surnames }}
									</span>
									<span class="txt-comment">
										{{ comment.data.comment }}
									</span>

									<div class="date flex-row-center start-row-center">
										<span> 
											{{ getFormatDate(comment.data.date) }} 
										</span>
										<span class="action-response" v-if="user.status" @click="responseComment(comment.id)"> 
											{{ labels.response[language.active] }} 
										</span>
									</div>
								</div>
							</div>

							<!-- Aqui la respuesta del comentario -->
							<div :class="comment.data.responses.length === 0 ? 'list-responses list no-spacing' : 'list-responses list'">
								<div
									:class="comment.data.responses.length === index ? 'response last' : 'response'"
									v-for="(response, index) in comment.data.responses"
									:key="comment.id + index"
								>
									<div class="flex-row-center start-row-start">
										<div class="middle-left flex-row-center center-row-start">
											<div class="cont-picture">
												<img :src="response.user.picture" alt="" />
											</div>
										</div>

										<div class="middle-right flex-column-center start-column-start">
											<span class="name">
												{{ response.user.names + " " + response.user.surnames }}
											</span>
											<span class="txt-comment">
												{{ response.response }}
											</span>

											<div class="date flex-row-center start-row-center">
												<span> 
													{{ getFormatDate(response.date) }} 
												</span>
											</div>
										</div>
									</div>
								</div>

								<!-- formulario de respuestas -->

								<div class="flex-column-center start-column-center">

									<div
										:class="active_response === comment.id ? 'active cont-form-response flex-row-center start-row-start' : 'cont-form-response flex-row-center start-row-start'"
									>
										<div class="middle-left">
											<div class="cont-picture">
												<img :src="user.db_data.data.personal.picture" alt="" />
											</div>
										</div>

										<div class="middle-right form">
											<textarea 
												type="text" 
												:placeholder="form.response.placeholder[language.active]"
												v-model="form.response.value"
											/>
										</div>
									</div>

									<!-- fin de formulario de respuestas -->
									<div class="cont-alert-button flex-row-center end-row-center">
										<span 
											:class="alert_response.type === 0 ? 'alert success' : 'alert error'"
											v-if="alert_response.show && active_response === comment.id"
										>
											{{ alert_response.message[language.active] }}
										</span>

										<div 
											class="cont-button flex-row-center center-row-center"
											v-if="active_response === comment.id"
											@click="answerComment(comment.id, comment.data)"
										>
											<span class="txt-btn">
												{{ labels.response[language.active] }}
											</span>
											<font-awesome-icon :icon="['fas', 'reply']" class="icon"/>
										</div>
									</div>
								</div>

								
							</div>
						</div>
					</div>

					<div class="flex-column-center start-column-center" v-if="user.status === true">
						<div class="cont-form flex-row-center start-row-start">
							<div class="middle-left">
								<div class="cont-picture">
									<img :src="user.db_data.data.personal.picture" alt="" />
								</div>
							</div>
							<div class="middle-right form">
								<textarea 
									type="text" 
									:placeholder="form.comment.placeholder[language.active]"
									v-model="form.comment.value"
								/>
							</div>
						</div>

						<div class="cont-alert-button flex-row-center end-row-center">
							<span 
								:class="alert_comment.type === 0 ? 'alert success' : 'alert error'"
								v-if="alert_comment.show"
							>
								{{ alert_comment.message[language.active] }}
							</span>

							<div class="cont-button flex-row-center center-row-center" @click="commentArticle()">
								<span class="txt-btn">
									{{ labels.comment[language.active] }}
								</span>
								<font-awesome-icon :icon="['fas', 'paper-plane']" class="icon"/>
							</div>
						</div>
					</div>

					<div class="full-width flex-column-center center-column-center" v-else>
						<Important :message="labels.login[language.active]"></Important>
						<div 
							class="cont-button register flex-row-center center-row-center"
							@click="goToRegister()"
						>
							<span class="txt-btn">
								{{ labels.register[language.active] }}
							</span>
							<font-awesome-icon :icon="['fas', 'sign-in-alt']" class="icon"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<style lang="scss" scoped>
	@import "@/assets/css/app.scss";
	@import "@/assets/css/components/form.scss";
	@import "@/assets/css/components/ktdra/article.scss";
	.div_to_scrool {
		position: relative;
		top: -18vw;
	}
</style>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Important from "@/components/Important";
import Portada from "@/components/blog/Portada";
import moment from "moment";
import { db } from "@/config/firebase/db";
import { timestamp } from "@/config/firebase/app";
import { mapGetters } from "vuex";
import { scroller } from "vue-scrollto/src/scrollTo";

export default {
	name: "SingleArticle",
	data: function() {
		return {
			language: {
				active: 0
			},
			
			section_active: 0,
			active_response: null,

			article: {
				id: null,
				data: null,
				exist: false,
				comments: [],
				colabs: [],
				slider: {
					size: 0,
					slides: {
						active: 0,
						buttons: [],
						items: []
					}
				}
			},

			labels: {
				autor: ["Autor", "Author", "Autor"],
				coautores: ["Coautores", "Co-authors", "Co-autores"],
				actions: ["Acciones", "Actions", "Ações"],
				comments: ["Comentarios ", "Comments ", "Comentários "],
				comment: ["Comentar", "Comment", "Comente"],
				response: ["Responder", "Reply", "Responder"],
				empty_comments: [
					"Aún no se ha realizado ningún comentario",
					"No comment has been made yet",
					"Nenhum comentário foi feito ainda"
				],
				login: [
					"Para realizar un comentario es necesario ser miembro signatario de Rectoral Board",
					"To make a comment you must be a signatory member of the Rectoral Board",
					"Para fazer um comentário é necessário ser membro signatário do Rectoral Board"
				],
				register: ["Regístrate", "Sign up", "Cadastre-se"]
			},

			alert_comment: {
				show: false,
				type: 0, //0-success 1-error
				message: []
			},

			alert_response: {
				show: false,
				type: 0, //0-success 1-error
				message: []
			},

			alert_copy: {
				show: false,
				message: ["Enlace copiado", "Copied link", "Link copiado"]
			},

			actions: [
				{
					label: ["Compartir", "Share", "Compartilhar"],
					icon: "share-alt"
				},
				{
					label: ["Ver perfil", "Watch profile", "Ver Perfil"],
					icon: "user"
				},
				{
					label: ["Ver blogueros", "Watch bloguers", "Ver blogueiros"],
					icon: "users"	
				}
			],

			form: {
				comment: {
					value: null,
					placeholder: [
						"Escribe aquí tu comentario",
						"Write your comment here",
						"Escreva seu comentário aqui"
					]
				},
				response: {
					value: null,
					placeholder: [
						"Escribe aquí tu respuesta",
						"Write your response here",
						"Escreva seu resposta aqui"
					]
				}
			}
		};
	},
	props: {
		section: {
			type: Number,
			default: 0
		}
	},
	components: {
		Navbar,
		Portada,
		Important,
		Footer
	},
	beforeMount() {
		this.getArticle();
		let scrool = scroller();
      	scrool("#div_to_scrool");
	},
	computed: {
		...mapGetters({
			user: "alldata"
		})
	},
	methods: {
		updateLanguage: function(index) {
			this.language.active = index;
		},

		updateSection: function(index) {
			let scrool = scroller();
			scrool("#div_to_scrool");
			this.section_active = index;
		},

		getArticle: async function() {
			let path = this.$route.fullPath.split("/");
			let id_article = path[path.length - 1];
			let article = await db.collection("articles").doc(id_article).get();
			if (article.exists) {
				this.article.exist = true;
				this.article.id = id_article;
				this.article.data = article.data();
				this.article.colabs = article.data().colabs;
				this.loadInSlider(this.article.colabs, 3);
				this.loadTags(article.data().keywords);
				this.loadComments(id_article);
			}
		},

		loadComments: async function(id_article) {
			let comments = await db.collection("comments").where("id_article", "==", id_article).get();
			comments.forEach((comment) => {
				this.article.comments.push({
					id: comment.id,
					data: comment.data()
				});
			});

			this.orderComments(this.article.comments);
		},

		orderComments: function(list) {
			let prueba = list;
			prueba.sort((a, b) => {
				if (a.data.date < b.data.date) {
					return 1;
				}
				if (a.data.date > b.data.date) {
					return -1;
				}
				return 0;
			});
			list = [];
			list = prueba;
		},




		loadInSlider: function(list, total) {
			this.article.slider.size = list.length;
			let indices = 0;
			for (let i = 0; i < this.article.slider.size; i += total) {
				let aux = {
					key: "slide_" + i,
					index: indices,
					item: []
				};
				for (let j = 0; j < total; j++) {
					aux.item.push(
						i + j < this.article.slider.size ? list[i + j] : null
					);
				}
				this.article.slider.slides.items.push(aux);
				indices += 1;
			}
			for (let j = 0; j < this.article.slider.slides.items.length; j++) {
				this.article.slider.slides.buttons.push({
					key: "button_" + j,
					index: j
				});
			}
		},

		getClassSlideItem: function(index) {
			return {
				slide: true,
				active: this.article.slider.slides.active === index,
                "flex-row-center": true,
                "start-row-start": true,
			};
		},

		getClassItem: function() {
			return {
				shadow: true,
				item: true,
				"flex-row-center": true,
                "start-row-center": true
			};
		},

		changeActiveSection: function(index) {
            this.article.slider.slides.active = index;
        },

        getClassButton: function(index) {
			return {
				shadow: true,
				button: true,
				active: this.article.slider.slides.active === index
			};
		},

		/* Formateo de keywords */
		loadTags: function(tags) {
			let formatedTags = tags.replace(/ /g, "");
			let tag;
			let newtags = [];
			formatedTags += ",";
			for (let i = 0; i <= formatedTags.length; i++) {
				tag = formatedTags.split(",")[i];
				if (tag !== "") {
					if (tag !== undefined) {
						newtags.push(tag);
					} else {
						break;
					}
				}
			}
			this.article.data.keywords = newtags; 
		},

		getStyleTag(index) {
			return {
				a: index === 0,
				b: index === 1,
				c: index === 2,
				d: index === 3,
				e: index === 4,
				f: index === 5,
				g: index === 6
			}
		},

		formatNameToParams: function(names, surnames) {
			let newName = names + " " + surnames;
			let formatedName = newName.split(" ").join("-").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g,"");
			return formatedName;
		},


		getAction(index) {
			switch (index) {
				case 0:
					this.shareArticle();
					break;
				case 1:
					this.goToBloguer();
					break;
				case 2:
					this.$router.push({ path: "/blog" });
					break;
				default:
					break;
			}
		},

		shareArticle() {
			if (
				document.queryCommandSupported &&
				document.queryCommandSupported("copy")
			) {
				var textarea = document.createElement("textarea");
				textarea.textContent = "https://rectoralboard.com" + this.$route.fullPath;
				textarea.style.position = "fixed";
				document.body.appendChild(textarea);
				textarea.select();
				try {
					this.alert_copy.show = true;
					return document.execCommand("copy");
				} catch (ex) {
					return false;
				} finally {
					document.body.removeChild(textarea);
				}
			}
			setTimeout(() => {
				this.alert_copy.show = false;
			}, 1500);
		},

		goToBloguer() {
			let name = this.$route.fullPath.split("/");
			let path = "/blog/" + name[2];
			this.$router.push({ path: path });
		},
		/* Comentarios */
		getFormatDate(date) {
			moment.locale("es");
			return moment(date.toDate()).fromNow();
		},

		responseComment(id_comment) {
			this.active_response = null;
			this.active_response = id_comment;
		},

		commentArticle: async function() {
			if (this.form.comment.value !== null) {
				let comment = await this.getDataComment();
				let response = await this.saveComment(comment);
				if(response.status) {
					this.form.comment.value = null;
					this.setAlertComment(0, ["Comentario enviado", "Comment sent", "Comentário enviado"], true);
					this.article.comments.push({
						id: response.data,
						data: comment
					});
				} else {
					this.setAlertComment(1, ["Error al comentar", "Comment error", "Erro de comentário"], true);
				}
			} else {
				this.setAlertComment(1, ["Comentario vacío", "Empty comment", "Comentário vazio"], true);
			}
		},

		getDataComment() {
			let path = this.$route.fullPath.split("/");
			let id_article = path[path.length - 1];
			return {
				comment: this.form.comment.value,
				date: timestamp.now(),
				id_article: id_article,
				responses: [],
				user: {
					country: this.user.db_data.data.contact.country,
					names: this.user.db_data.data.personal.names,
					surnames: this.user.db_data.data.personal.surnames,
					picture: this.user.db_data.data.personal.picture,
					id_user: this.user.db_data.id
				}
			}
		},


		saveComment: async function(comment) {
			try {
				let snapshot = await db.collection("comments").add(comment);
				let activity_comment = this.activityCommentData(comment);
				if (snapshot.id) {
					activity_comment.id_comment = snapshot.id;
					let snapshot_activity = await db.collection("activity_comment").add(activity_comment);
					if(snapshot_activity.id) {
						return { data: snapshot.id, status: true };
					}
				} else {
					return { data: "", status: false };
				}
			} catch (error) {
				if (error) {
					this.setAlertComment(1, ["Error en la base de datos", "Database error", "Erro de banco de dados"], true);
				}
			}
		},

		activityCommentData(data_comment) {
			let path = this.$route.fullPath.split("/");
			let name_formated = path[2];
			
			return {
				date: data_comment.date,
				article: data_comment.id_article,
				comment: data_comment.comment,
				id_comment: "",
				to: name_formated,
				from: {
					id: data_comment.user.id_user,
					names: data_comment.user.names,
					surnames: data_comment.user.surnames
				},
				see: false
			}
		},


		answerComment: async function(id_comment, data_comment) {
			if (this.form.response.value !== null) {

				let answer = await this.getDataAnswer();
				let result = await this.saveAnswer(answer, id_comment, data_comment);

				if(result.status) {
					this.form.response.value = null;
					this.setAlertResponse(0, ["Respuesta enviada", "Answer sent", "Resposta enviado"], true);	
				}
			} else {
				this.setAlertResponse(1, ["Respuesta vacía", "Empty answer", "Resposta vazia"], true);
			}
		},

		getDataAnswer() {
			return {
				response: this.form.response.value,
				date: timestamp.now(),
				user: {
					country: this.user.db_data.data.contact.country,
					names: this.user.db_data.data.personal.names,
					surnames: this.user.db_data.data.personal.surnames,
					picture: this.user.db_data.data.personal.picture,
					id_user: this.user.db_data.id
				}
			}
		},

		saveAnswer: async function(answer, id_comment, data_comment) {
			try {
				data_comment.responses.push(answer);
				await db.collection("comments").doc(id_comment).set(data_comment);

				let activity_answer = this.activityAnswerData(answer, id_comment, data_comment);
				let snapshot = await db.collection("activity_answer").add(activity_answer);
				if(snapshot.id) {
					return { status: true };
				}
			} catch (error) {
				if (error) {
					this.setAlertComment(1, ["Error en la base de datos", "Database error", "Erro de banco de dados"], true);
				}
			}
		},

		activityAnswerData(answer, id_comment, data_comment) {
			return {
				date: answer.date,
				comment: id_comment,
				article: data_comment.id_article,
				response: answer.response,
				to: {
					id: data_comment.user.id_user,
					names: data_comment.user.names,
					surnames: data_comment.user.surnames
				},
				from: {
					id: answer.user.id_user,
					names: answer.user.names,
					surnames: answer.user.surnames
				},
				see: false
			}
		},

		setAlertComment(type, message, show) {
			this.alert_comment.type = type;
			this.alert_comment.message = message;
			this.alert_comment.show = show;

			setTimeout(() => {
				this.alert_comment.message = [];
				this.alert_comment.show = false;
			}, 1500);
		},

		setAlertResponse(type, message, show) {
			this.alert_response.type = type;
			this.alert_response.message = message;
			this.alert_response.show = show;

			setTimeout(() => {
				this.alert_response.message = [];
				this.alert_response.show = false;
			}, 1500);
		},

		goToRegister() {
			this.$router.push({ path: "/signatarios", query: { section: 2}})
		}
	}
};
</script>
