<template>
    <div class="headers full-width">
        <span class="title title--level-1 tp-bold cl--primary-blue uppercase full-width" :style="getAlignToText()">
            {{ $t(title) }}
        </span>
        <div v-if="showLine" class="line" :style="getAlignToLine()"/>
        <span class="text text--level-2 cl--primary-blue tp-medium full-width" :style="getAlignToText()">
            {{ $t(description) }}
        </span>
    </div>
</template>

<script>
export default {
    data: () =>{
        return {

        };
    },
    props: {
        title: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        showLine: {
            type: Boolean,
            default: false
        },
        align: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: ""
        },
    },

    methods: {
        getAlignToLine() {
            return {
                "float": this.align
            }
        },
        getAlignToText() {
            return {
                "display": "inline-grid",
                "text-align": this.align,
                "color": `${this.color} !important`,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';

.headers {
    position: relative;
    z-index: 3;
    
    .line {
        border-radius: 2px;
        background: $color-yellow;
        height: 3px;
        width: 150px;
        margin: 4px 0 16px 0;

        @include breakpoint(md) {
            height: 4px;
            width: 200px;
        }
    }
}

</style>
