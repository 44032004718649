<template>
    <a-space
        direction="vertical"
        :size="4"
        class="full-width"
        style="margin-bottom: 24px"
    >
        <span class="title title--level-2 cl--primary-blue tp--medium">
            {{ $t(header) }}
        </span>
        <span class="text title--level-3 cl--primary-blue tp--medium">
            {{ $t(description, { name: 'Luciano' }) }}
        </span>
    </a-space>
</template>

<script>
export default {
    name: "HeaderDashboard",
    data: () => {
        return { };       
    },
    props: {
        header: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';
</style>

