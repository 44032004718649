export default { 
    SET_SESSION(state, data_user) {
        state.session = data_user.session;
        state.id = data_user.id;
        state.data = data_user.data;
    },

    CLOSE_SESSION(state) {
        state.session = false;
        state.id = null;
        state.data = null;
    }
}