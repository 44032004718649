<template>
    <div class="sections full-width full-height bg--secondary-grey-alt">
        <a-space class="full-width" :size="24" direction="vertical">
            <div>
                <Headers
                    :showLine="headers.line"
                    :title="headers.title"
                    :align="headers.align"
                />

                <a-divider style="background: #1b4484"/>
            </div>

            <a-row :gutter="[16,24]" class="center-row-center full-width">
                <a-col :lg="16" :xs="24">
                    <a-form-item class="full-width">
                        <a-input
                            :placeholder="$t('FORMS.SEARCH')"
                            v-model="search"
                            :disabled="loaded"
                            allowClear
                        />
                    </a-form-item>
                </a-col>
                <a-col :lg="8" :xs="24">
                    <a-select
                        allowClear
                        class="full-width"
                        v-model="country"
                        :disabled="loaded"
                        :placeholder="$t('FORMS.SELECT_COUNTRY')"
                    >
                        <a-select-option
                            v-for="country in countries"
                            :value="country.alpha3Code"
                            :key="country.alpha3Code"
                        >
                            <a-space direction="horizontal" :size="8">
                                <a-avatar
                                    :src="getFlag(country.alpha3Code)"
                                    class="flag-avatar"
                                    :size="32"
                                    shape="square"
                                />
                                {{ country.name }}
                            </a-space>
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>

            <Loader :type="type" v-if="loaded"/>

            <div class="containerMembers" v-else>
                <a-list
                    :grid="{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 4 }" :data-source="partners"
                >
                    <a-list-item slot="renderItem" slot-scope="item">
                        <Member :data="item" />
                    </a-list-item>
                </a-list>
            </div>
        </a-space>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
.containerMembers {
    height: 800px;
    overflow-y: scroll;
}
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import countries from "../../constants/countries.json";
import * as utils from '@/utils';

export default {
    data: () => {
        return {
            headers: {
                title: "INSTITUTIONAL.BOARD_MEMBERS",
                line: false,
                align: "left",
            },
            type: 1,
            loaded: true,
            params: {
                limit: 100,
                page: 1,
                isPartner: true,
                role: "SIGNATORY"
            },
            countries: countries,
        };
    },

    async created() {
        await this.initialData();
    },

    beforeDestroy() {
        this.clearPartners();
    },

    computed: {
        ...mapState({
            landing: "landing" 
        }),

        ...mapGetters("landing", {
            partners: "getPartners",
            meta: "getMetaPartners",
        }),

        search: {
            get() {
                return this.landing.partners.search;
            },

            set(value) {
                this.searchPartners(value);
            }
        },

        country: {
            get() {
                return this.landing.partners.country;
            },

            set(value) {
                this.landing.partners.country = value;
            }
        },
    },

    methods: {
        ...mapActions("landing", {
			getPartnersStore: "getPartners",
            searchPartners: "searchPartners",
            setDefaultPartnersStore: "setDefaultPartners",
		}),

        async initialData() {
            if (!this.partners.length) {
                await this.getPartnersStore(this.params);
                this.loaded = false;
            };
        },

        async clearPartners() {
            await this.setDefaultPartnersStore();
        },

        getFlag (country) {
            return utils.getFlag(country);
        }
    }

};
</script>