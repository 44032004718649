export const LIBRARY_PATH = {
    "BOOKS": {
        code: 0,
        name: "LIBRARY.BOOKS",
        path: "books",
        keyName: "BOOK"
    },
    "REPORTS": {
        code: 1,
        name: "LIBRARY.REPORTS",
        path: "reports",
        keyName: "REPORT"
    },
    "CONFERENCES": {
        code: 2,
        name: "LIBRARY.CONFERENCES",
        path: "conferences",
        keyName: "BENCHMARK"
    },
    "TRENDS": {
        code: 3,
        name: "LIBRARY.TRENDS",
        path: "trends",
        keyName: "TREND"
    }
};