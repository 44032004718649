import * as api from "@/api/users.js";

export const signIn = async ({ commit }, payload) => {
    try {
        
        let response = await api.signIn(payload);

        if (response.status === 200) { 
            commit('SET_SESSION', 
                { 
                    session: true, 
                    id: response.body.id, 
                    data: response.body.data 
                }
            );
            localStorage.userID = response.body.id;
        }
        return response.message;
        
    } catch (error) {

        if(error.code === "auth/user-not-found")
            return "No existe un usuario con este correo";
        else if(error.code === "auth/wrong-password")
            return "La contraseña es incorrecta";
        else return error;
        
    }
}

export const initializeData = async({ commit }, userID ) => {
    try {

        let response  = await api.getDataUser(userID);

        if (response.status === 200) { 
            commit('SET_SESSION', 
                { 
                    session: true, 
                    id: response.body.id, 
                    data: response.body.data 
                }
            );
        }

        return response.status;
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
}

export const logout = async({ commit }) => {
    try {
        
        commit('CLOSE_SESSION')
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("userID");

        return;
        
    } catch (error) {
        return error;
    }
}