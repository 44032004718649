import axios from "axios";
import { DASHBOARD_API_URL, API_URL } from "../../api-routes";
import queryString from 'querystring';

export async function getArticles(params) {
    try {
        let url = `${DASHBOARD_API_URL}/articles?${queryString.stringify(params)}`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}

export async function getArticleDetail(articleID) {
    try {
        let url = `${DASHBOARD_API_URL}/articles/detail/${articleID}`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}

export async function getComments(articleID) {
    try {
        const params = new URLSearchParams();
        params.append('articleID', articleID);
        let url = `${API_URL}/comments?${params.toString()}`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}
