<template>
        <a-space direction="horizontal" :size="16" class="full-width locale__switcher">
            <a-select ref="select" v-model="selectedLanguage">
                <a-select-option 
                    :key="lng.key"
                    v-for="(lng) in language.icons"
                    @click="switchLocale(lng.key)"
                >
                    <span class="locale__switcher--item">
                        {{ $t(`${lng.key}`) }}  
                    </span>
                </a-select-option>
            </a-select>
        </a-space>
</template>

<script>
    export default {
        data: () => {
            return {
                language: {
                    active: 0,
                    icons: [
                        {
                            name: "LANGUAGES.SPANISH",
                            key: "es",
                        },
                        {
                            name: "LANGUAGES.ENGLISH",
                            key: "en",
                        },
                        {
                            name: "LANGUAGES.PORTUGUESE",
                            key: "pt",
                        }
                    ]
                },
                selectedLanguage: 'es'
            }
        },
        methods: {
            switchLocale(lngKey) {
                if (this.$i18n.locale !== lngKey) {
                    this.$i18n.locale = lngKey;
                }
            }
        },
    }
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';

.locale__switcher {
    justify-content: flex-start;
    width: 100%;

    @include breakpoint(md) {
        justify-content: end;
    }

    .language {
        display: none;

        @include breakpoint(md) {
            display: block;
        }
    }

    &--item {
        text-transform: uppercase;
    }
}
</style>

