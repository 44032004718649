export const getDataWelcomePhrases = state => {
    return state.welcomePhrases;
}

export const getContributions = state => state.contributions.data;

export const getMetaContributions = state => state.articles.meta;

export const getLineBackers = state => {
    return state.lineBackers;
}

export const getWebinarsCalendar = state => {
    return state.webinarsCalendar;
}

export const getOutsideHoldings = state => {
    return state.outsideHoldings;
}

export const getMetrics = state => {
    return state.metrics;
}

export const getNews = state => {
    return state.news;
}

export const getMetaArticles = state => state.articles.meta;

export const getCategoriesArticles = state => state.articles.categories;

export const getArticles = state => {
    const { data, search, category } = state.articles;
    
    let articles = data;
    const searchQuery = search.toLowerCase();

    if (search.length > 2) {
        articles = data.filter(article =>
            article.title.toLowerCase().includes(searchQuery) ||
            article.authorID.name.toLowerCase().includes(searchQuery) ||
            article.authorID.lastName.toLowerCase().includes(searchQuery)
        );
    }

    if (category) {
        articles = articles.filter(article => article.categoryID._id === category);
    }

    return articles;
}

export const getArticleDetail = state => state.article;

export const getComments = state => state.comments;

export const getMetaPartners = state => state.partners.meta;

export const getPartners = state => {
    const { data, search, country } = state.partners;
    
    let partners = data;
    const searchQuery = search.toLowerCase();

    if (search.length > 2) {
        partners = data.filter(partner =>
            partner.name.toLowerCase().includes(searchQuery) ||
            partner.lastName.toLowerCase().includes(searchQuery)
        );
    }

    if (country) {
        partners = partners.filter(partner => partner.country === country);
    }

    return partners;
}

export const getWebinarsByCategory = state => state.webinarsByCategory;

export const getKPIS = state => {
    return state.KPIS;
}

export const getBoardNews = state => {
    return state.boardNews;
}

export const getLibrary = state => {
    const { data, search, category } = state.libraries;
    
    let library = data;
    const searchQuery = search.toLowerCase();

    if (search.length > 2) {
        library = data.filter(doc =>
            doc.title.toLowerCase().includes(searchQuery) ||
            doc.registerByID.name.toLowerCase().includes(searchQuery) ||
            doc.registerByID.lastName.toLowerCase().includes(searchQuery)
        );
    }

    if (category) {
        library = library.filter(doc => doc.categoryID._id === category);
    }
    return library;
}

export const getMetaLibrary = state => state.libraries.meta;

export const getCategoriesLibrary = state => state.libraries.categories;

export const getExternalParticipations = state => {
    return state.participations;
}
