<template>
    <div class="mainContainer sections first full-width full-height bg--secondary-grey-alt">

        <Headers
            :showLine="headers.line"
            :title="headers.title"
            align="center"
        />

        <div class="containerResponsabilities">
            <a-collapse accordion :bordered="false">
                <a-collapse-panel 
                    v-for="(item, index) in data"
                    :key="`item_${index}`" 
                >
                    <span slot="header" class="title title--level-3 cl--primary-blue">
                        {{ $t(`INSTITUTIONAL.GOVERNANCE.${item}.TITLE`) }}
                    </span>
                    <Responsabilities
                        :position="index"
                        :section="item"
                    />
                </a-collapse-panel>
            </a-collapse>
        </div>

        <BoardMembers />

    </div>
</template>

<script>
import BoardMembers from "@/components/institucional/BoardMembers";
import { Collapse } from 'ant-design-vue';
import Responsabilities from '@/components/institucional/subcomponents/Responsabilities';

export default {
    data: () => {
        return {
            headers: {
                title: "INSTITUTIONAL.GOVERNANCE.HEADER",
                line: false,
            },
            data: [
                "EXECUTIVE_MANAGEMENT",
                "REGIONAL_VICE_PRESIDENCY",
                "GABINETE",
                "ADMINISTRATIVE"
            ]
        };
    },
    
    components: {
        'a-collapse': Collapse,
        'a-collapse-panel': Collapse.Panel,
        Responsabilities,
        BoardMembers,
    }
}

</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';

.containerResponsabilities {
    margin-top: 24px;
    
    .ant-collapse {
        background: none;
        border: none;
    }
    .ant-collapse-item {
        border: none;
        background: $color_white;
        margin-bottom: 16px;
        border-radius: 8px;
    }
}
</style>