<template>
    <div class="bannerWebinars flex-row center-row-center">
        <a-space direction="vertical" :size="4" class="full-width">
            <span class="title cl--secondary-white left full-width">
                Webinars
            </span>
            <span class="title text--level-2 cl--secondary-white left" style="width: 80%">
                {{ $t('WEBINARS.WELCOME') }}
            </span>
        </a-space>
        <a-avatar 
            class="imageWebinar"
            :src="imageWebinar"
            shape="square"
        />
    </div>
</template>

<style lang="scss">
@import "@/styles/scss/config.scss";
@import "@/styles/scss/views/webinars.scss";
</style>


<script>
import { IMAGES } from '@/constants/images'
    export default {
        data: () => {
            return {
                imageWebinar: IMAGES.icons.webinars
            }
        }
    }
</script>