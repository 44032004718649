<template>
    <div class="full-width containerKpi flex-row start-row-end">
        <div class="line"/>
        <a-space direction="horizontal" :size="16" class="full-width" style="align-items: end; margin-bottom: 6px">
            <a-avatar :src="icon" :size="82" shape="square"/>
            <span class="title title--level-3 uppercase" style="white-space: break-spaces; display: table-caption">
                {{ $t(name) }}
            </span>
        </a-space>
        <span class="title " style="align-items: end">
            {{ total }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default:""
        },
        total: {
            type: Number,
            default:0
        },
        name: {
            type: String,
            default:""
        },

    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
.containerKpi {
    margin-bottom: 24px;
    justify-content: space-between;
    position: relative;

    .line {
        position: absolute;
        height: 0.7px;
        background: $color-primary;
        width: calc(100% - 82px);
        bottom: 0;
        right: 0;
    }

    span {
        color: $color-primary;
    }
}
</style>