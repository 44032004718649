<template>
    <div>
        <span slot="title" class="title title--level-3 cl--secondary-white bg--primary-blue full-width flex-row start-row-center headerContact">
            {{ $t('UPDATE_DATA.TITLE') }}
        </span>

        <div class="containerForm full-width full-height" style="padding: 24px">
            {{ $t('UPDATE_DATA.DESCRIPTION') }}
        </div>
        <div class="flex-row end-row-end full-width" style="padding: 0 24px 24px">
            <a-space direction="horizontal" :size="24">

                <a-button size="small" type="primary" ghost @click="changeStateNotify(false)">
                    {{ $t('UPDATE_DATA.CANCEL') }}
                </a-button>

                <a-button :loading="loading" size="small" type="primary" html-type="submit" @click="goToUpdate()">
                    {{ $t('UPDATE_DATA.UPDATE') }}
                </a-button>

            </a-space>
        </div>
    </div>
</template>

<script>
import { DASHBOARD_URL } from '@/config/environments';

export default {
    data: () => {
        return { };
    },

    methods: {
        changeStateNotify(value) {
            this.$emit("changeStateNotify", value)
        },
        goToUpdate() {
            const url = `${DASHBOARD_URL}/login`;
            window.open(url, "_blank");
            this.$emit("changeStateNotify", false);
        }
    }

};
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';

.headerContact {
    padding: 16px 24px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
</style>