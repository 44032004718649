import * as getters from './getters';
import * as actions from './actions';
import mutations from './mutations';

const defaultMeta = {
    page: 1,
    limit: 10,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
};

const state = { 
    welcomePhrases: [],
    lineBackers: {},
    contributions: {
        data: [],
        meta: defaultMeta,
    },
    webinarsCalendar: [],
    outsideHoldings: {
        data: [],
        meta: {},
    },
    metrics: {
        countries: 0,
        totalUsers: 0,
        usersByCountry: [],
        usersByGender: [],
        usersByMembership: {},
    },
    news: {
        data: [],
        meta: defaultMeta,
    },
    articles: {
        search: "",
        category: null,
        data: [],
        categories: [],
        meta: defaultMeta,
    },
    article: {},
    comments: [],
    partners: {
        search: "",
        country: null,
        data: [],
        totalCountries: 0,
        meta: defaultMeta,
    },
    webinarsByCategory: {
        data: [],
        total: 0,
        meta: defaultMeta,
    },
    libraries: {
        search: "",
        category: null,
        data: [],
        categories: [],
        meta: defaultMeta,
    },
    KPIS: [],
    boardNews: {
        firstNew: {},
        news: []
    },
    filter: {
        query: ""
    },
    participations: {
        list: [],
        total: 0,
    },
}

export default { 
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}