export const LEADERSHIP_QUOTES = [
    {
        index: 0,
        key: "PROGRAM_1",
        hours: 2,
        value: false,
    },
    {
        index: 1,
        key: "PROGRAM_2",
        hours: 2,
        value: false,
    },
    {
        index: 2,
        key: "PROGRAM_3",
        hours: 2,
        value: false,
    },
    {
        index: 3,
        key: "PROGRAM_4",
        hours: 2,
        value: false,
    },
    {
        index: 4,
        key: "PROGRAM_5",
        hours: 2,
        value: false,
    },
    {
        index: 5,
        key: "PROGRAM_6",
        hours: 2,
        value: false,
    },
    {
        index: 6,
        key: "PROGRAM_7",
        hours: 2,
        value: false,
    },
    {
        index: 7,
        key: "PROGRAM_8",
        hours: 2,
        value: false,
    },
    {
        index: 8,
        key: "PROGRAM_9",
        hours: 2,
        value: false,
    },
    {
        index: 9,
        key: "PROGRAM_10",
        hours: 2,
        value: false,
    },
    {
        index: 10,
        key: "PROGRAM_11",
        hours: 2,
        value: false,
    },
    {
        index: 11,
        key: "PROGRAM_12",
        hours: 2,
        value: false,
        description: ""
    },
];