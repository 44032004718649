<template>
    <div>
        <span
            slot="title"
            class="title title--level-3 cl--secondary-white bg--primary-blue full-width flex-row start-row-center headerContact"
        >
            {{ $t('BRIEFCASE.QUOTE_LEADERSHIP.TITLE') }}
        </span>

        <a-form layout="vertical" @submit.prevent="sendQuoteToolBox" :form="form">
            <div class="containerForm full-width full-height" style="padding: 24px; max-height: 600px; overflow-y: scroll">
                <a-row :gutter="16" class="center-row-center">
                    <a-col :span="24" :md="24" :sm="24" :xs="24">
                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.NAMES')}`">
                            <a-input
                                v-decorator="[
                                    'names',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.NAMES')}`"
                            >
                                <a-icon slot="prefix" type="user" />
                            </a-input>
                        </a-form-item>
                        
                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.EMAIL')}`">
                            <a-input
                                v-decorator="[
                                    'email',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.EMAIL')}`"
                            >
                                <a-icon slot="prefix" type="mail" />
                            </a-input>
                        </a-form-item>

                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.INSTITUTE')}`">
                            <a-input
                                v-decorator="[
                                    'institute',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.INSTITUTE')}`"
                            >
                                <a-icon slot="prefix" type="bank" />
                            </a-input>
                        </a-form-item>

                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.PROFILE_PUBLIC')}`">
                            <a-input
                                v-decorator="[
                                    'profilePublic',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.PROFILE_PUBLIC')}`"
                            >
                                <a-icon slot="prefix" type="profile" />
                            </a-input>
                        </a-form-item>

                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.AUDIENCE')}`">
                            <a-input
                                v-decorator="[
                                    'audience',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.AUDIENCE')}`"
                            >
                                <a-icon slot="prefix" type="tag" />
                            </a-input>
                        </a-form-item>

                        <CountryCodeSelector
                            @changeCountryCode="(value) => changeCountryCode(value)"
                            @changeMobile="(value) => changeMobile(value)"
                        />

                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.CONTEXTUALIZE')}`">
                            <a-textarea
                                v-decorator="[
                                    'contextualize',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.CONTEXTUALIZE')}`"
                                :auto-size="{ minRows: 3, maxRows: 6 }"
                            />
                        </a-form-item>

                        <a-space :size="16" direction="vertical" class="full-width">

                            <span class="title text--level-2 cl--secondary-black full-width flex-row start-row-center">
                                {{ $t('BRIEFCASE.QUOTE_TOOL_BOX.CHOOSE_TOOLS') }}
                            </span>

                            <div class="container__programs">

                                <a-list
                                    :grid="{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 3 }" 
                                    :data-source="program"
                                >
                                    <a-list-item
                                        slot="renderItem"
                                        slot-scope="item"
                                        :class="getStyleProgram(item.index)"
                                        class="container__programs--program"
                                    >
                                        <div class="container__programs--program-header">
                                            <span class="text--level-3 title cl--secondary-white" style="font-size: 12px">
                                                {{ `${$t('BRIEFCASE.QUOTE_LEADERSHIP.PROGRAM')} ${item.index + 1}` }}
                                            </span>
                                            <a-switch
                                                v-decorator="[
                                                    `${item.value}`,
                                                    {
                                                        valuePropName: 'checked',
                                                        initialValue: false,
                                                    },
                                                ]"
                                                @change="onChange(item.index)"
                                            />
                                        </div>

                                        <a-form-item
                                            class="full-width"
                                            v-if="item.key === 'PROGRAM_12'"
                                        >
                                            <a-textarea
                                                v-model="item.description"
                                                :auto-size="{ minRows: 1, maxRows: 2 }"
                                            />
                                        </a-form-item>

                                        <div class="container__programs--program-module" v-else>
                                            <span class="text text--level-3 cl--secondary-white" style="font-size: 12px">
                                                {{ $t('BRIEFCASE.QUOTE_LEADERSHIP.MODULE') }}
                                            </span>
                                            <span class="title text--level-3 cl--secondary-white">
                                                {{ $t(`BRIEFCASE.QUOTE_LEADERSHIP.${item.key}`) }}
                                            </span>
                                        </div>

                                        <div
                                            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; width: 100%; padding: 0; margin-bottom: 16px;"
                                        >
                                            <span class="text--level-3 title cl--secondary-white" style="font-size: 14px">
                                                {{ $t('BRIEFCASE.QUOTE_LEADERSHIP.HOURS') }}
                                            </span>
                                            <a-input-number
                                                v-model="item.hours"
                                                :min="2"
                                                :max="50"
                                                :step="2"
                                            />
                                        </div>
                                    </a-list-item>
                                </a-list>
                                                                
                            </div>
                        </a-space>
                    </a-col>
                </a-row>
            </div>
            <div class="flex-row end-row-end full-width" style="padding: 0 24px 24px">
                <a-space direction="horizontal" :size="24">

                    <a-button
                        ghost
                        size="large"
                        type="primary"
                        @click="changeStatusModal(false)"
                    >
                        {{ $t('CONTACT.CANCEL') }}
                    </a-button>

                    <a-button
                        :loading="loading"
                        size="large"
                        type="primary"
                        html-type="submit"
                    >
                        {{ $t('CONTACT.SEND') }}
                    </a-button>

                </a-space>
            </div>
        </a-form>


    </div>
</template>

<script>
import * as quotesAPI from "@/api/quotes.js";
import { notification } from "ant-design-vue";
import { LEADERSHIP_QUOTES } from '@/constants/leadershipQuotes.js';
import { Switch, InputNumber } from "ant-design-vue";

export default {
    data: () => {
        return {
            loading: false,
            names: '',
            email: '',
            phone: '',
            institute: '',
            profilePublic: '',
            audience: '',
            contextualize: '',
            countryCode: '',
            mobile: '',
            program: LEADERSHIP_QUOTES,
        }
    },

    beforeCreate() {
		this.form = this.$form.createForm(this, { name: "planRequest" });
	},

    components: {
        notification,
        "a-switch": Switch,
        "a-input-number": InputNumber,
    },

    methods: {
        changeStatusModal(value) {
            this.$emit("changeStatusModal", value)
        },

        getFormValues (values) {
            const payload = {
                ...values,
                program: this.program,
                fullMobile: `${this.countryCode}${this.mobile}`,
            }
            return payload;
        },

        sendQuoteToolBox (e) {
            this.loading = true;
            e.preventDefault();
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    const payload = this.getFormValues(values);
                    const response = await quotesAPI.createLeadershipQuote(payload);
                    this.mappedResponse(response);
                }
            });
            this.loading = false;
        },

        mappedResponse (response) {
            if (response.statusCode === 201) {
                notification.success({
                    message: this.$t('RESPONSES.SUCCESS'),
                    description: this.$t('BRIEFCASE.QUOTES.CREATED_QUOTE'),
                });
                this.changeStatusModal(false);
                this.resetForm();
            } else if (response.statusCode === 409) {
                notification.warning({
                    message: this.$t('RESPONSES.WARNING'),
                    description: this.$t('BRIEFCASE.QUOTES.ALREADY_QUOTE_EXISTS'),
                });
            } else {
                notification.error({
                    message: this.$t('RESPONSES.ERROR'),
                    description: this.$t('BRIEFCASE.QUOTES.ERROR_CREATING_QUOTE'),
                });
            }
        },

        changeCountryCode(value) {
            this.countryCode = value;
        },

        changeMobile(value) {
            this.mobile = value;
        },

        onChange(programIndex) {
            const program = this.program[programIndex];
            const index = this.program.findIndex((item) => item.key === program.key);
            this.program[index].value = !this.program[index].value;
        },

        getStyleProgram(index) {
			return {
				a: index === 0,
				b: index === 1,
				c: index === 2,
				d: index === 3,
				e: index === 4,
				f: index === 5,
				g: index === 6,
                h: index === 7,
                i: index === 8,
                j: index === 9,
                k: index === 10,
                l: index === 11,
			}
		},

        resetForm() {
            this.form.resetFields();
            this.mobile = '';
            
            this.program.forEach(program => {
                program.value = false;
                program.hours = 2;
                if (program.key === 'PROGRAM_12') program.description = '';
            })
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';

.headerContact {
    padding: 16px 24px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.container {
    &__programs {
        width: 100%;
        overflow: hidden;
        overflow-y: scroll;
        background-color: #f8fbff;
        border-radius: 4px;
        border: solid 1px #d9d9d9;

        &--program {
            margin-bottom: 16px;
            height: 210px;
            
            .ant-list-item {
                width: 100% !important;
                gap: 16px;
                padding: 12px 24px !important;
                display: flex !important;
                flex-direction: column;
                margin-bottom: 0;
                align-items: stretch;
                height: 100%;
            }

            &.a, &.d, &.g, &.j { background: #4596d6; }
            &.b, &.e, &.h, &.k { background: rgb(54, 136, 198); }
            &.c, &.f, &.i { background: rgb(48, 122, 178); }

            &.l {
                span {
                    color: $color-primary !important;
                }
            }

            &-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &-module {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
            }
        }

    }
}
</style>