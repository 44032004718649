<template>
    <div class="mainContainer sections full-width full-height bg--secondary-white" style="position: relative">

        <Headers
            :showLine=headers.line
            :title=headers.title
            :description=headers.description
            align="left"
        />

        <div class="containerMembers">
            <a-row class="full-width full-height" :gutter="[32,16]">
                <a-col :span="12" :md="12" :sm="24" :xs="24">
                    <Kpi
                        :name="countries.name"
                        :icon="countries.icon"
                        :total="metrics.countries"
                    />
                    <Kpi
                        :name="users.name"
                        :icon="users.icon"
                        :total="metrics.totalUsers"
                    />

                    <div class="containerCountries">
                        <splide
                            :options="slideOptions"
                            :slides="metrics.usersByCountry"
                        >
                            <splide-slide
                                v-for="item of metrics.usersByCountry"
                                :key="item._id"
                            >
                        
                                <div class="flex-column end-column-center containerFlag">
                                    <a-avatar 
                                        :src="item.flag"
                                        :alt="`flag_${item.name}`"
                                        class="flag"
                                        shape="square"
                                    />
                                    <span class="total text text--level-3 tp-medium cl--secondary-white bg--primary-blue">
                                        {{ item.count }}
                                    </span>
                                </div>
                            </splide-slide>
                        </splide>
                    </div>

                </a-col>

                <a-col :span="12" :md="12" :sm="24" :xs="24">
                    <Kpi
                        v-if="gold"
                        :name="gold.name"
                        :icon="gold.icon"
                        :total="metrics.usersByMembership.GOLD.count"
                    />
                    <Kpi
                        :name="platinum.name"
                        :icon="platinum.icon"
                        :total="metrics.usersByMembership.PLATINUM.count"
                    />
                    <Kpi
                        :name="diamond.name"
                        :icon="diamond.icon"
                        :total="metrics.usersByMembership.DIAMOND.count"
                    />
                </a-col>
            </a-row>

            <a-row class="full-width full-height" :gutter="[32,16]">
                <a-col :span="12" :md="12" :sm="24" :xs="24">
                    <Kpi
                        :name="male.name"
                        :icon="male.icon"
                        :total="male.count"
                    />
                </a-col>
                <a-col :span="12" :md="12" :sm="24" :xs="24">
                    <Kpi
                        :name="female.name"
                        :icon="female.icon"
                        :total="female.count"
                    />
                </a-col>
            </a-row>
        </div>

    </div>
</template>

<script>
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import { mapActions, mapGetters } from "vuex";
import { IMAGES } from "@/constants/images";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
    data: () => {
        return {
            headers: {
                title: "HOME.MEMBERS.SIGNATORY_MEMBERS",
                description: "HOME.MEMBERS.DESCRIPTION",
                line: true,
            },
            loaded: false,
            slideOptions: {
                pagination: false,
                arrows: false,
                rewind: true,
                autoplay: true,
                perPage: 5,
                autoWidth: true,
            },
        };
    },

    async created() {
        await this.initialData();
    },

    components: {
        Splide,
        SplideSlide,
    },

    computed: {
        ...mapGetters("landing", {
			metrics: "getMetrics",
		}),

        gold() {
            return {
                name: "MEMBERSHIPS.GOLD",
                icon: IMAGES.memberships.gold
            }
        },

        platinum() {
            return {
                name: "MEMBERSHIPS.PLATINUM",
                icon: IMAGES.memberships.platinum
            }
        },

        diamond() {
            return {
                name: "MEMBERSHIPS.DIAMOND",
                icon: IMAGES.memberships.diamond
            }
        },

        male() {
            return {
                name: "HOME.MEMBERS.MALE",
                icon: IMAGES.kpis.male,
                count: this.metrics.usersByGender[0].count,
            }
        },

        female() {
            return {
                name: "HOME.MEMBERS.FEMALE",
                icon: IMAGES.kpis.female,
                count: this.metrics.usersByGender[1].count,
            }
        },

        countries() {            
            return {
                name: "HOME.MEMBERS.COUNTRIES",
                icon: IMAGES.kpis.countries
            };
        },

        users() {
            return {
                name: "HOME.MEMBERS.SIGNATORIES",
                icon: IMAGES.kpis.users,
            };
        }
    },

    methods: {
        ...mapActions("landing", {
			getMetricsStore: "getMetrics",
		}),

        async initialData() {
            await this.getMetricsStore();
        },

    },
    
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
.containerMembers {
    margin-top: 24px;

    .containerCountries {
        height: 72px;
        margin-bottom: 32px;

        @include breakpoint(md) {
            margin-bottom: 0px;
        }

        .containerFlag {
            width: 84px;
            margin-right: 16px;
            height: 100%;
            position: relative;
            margin-top: 0;

            .flag {
                display: block;
                margin: 0 auto;
                width: 100%;
                height: 60%;

                :global(.ant-avatar > img) {
                    object-fit: unset;
                }
            }

            span.total {
                position: absolute;
                bottom: 0;
                right: 0;
                border-top-left-radius: 2px;
                font-size: 12px;
                padding: 2px 4px;
            }
        }
    }
}
</style>