<template>
    <a-card class="memberCard bg--primary-blue flex-column center-column-center">
        <div class="data flex-column center-column-center">
            <a-space class="full-width" direction="vertical" :size="16">
                <a-avatar :src="data.image.original" :size="84" class="picture" />

                <a-avatar
                    :src="getFlag(data.country)"
                    class="avatar flag"
                />

                <a-space class="full-width" direction="vertical" :size="4">
                    <span class="cl--secondary-white title text--level-3 center full-width">
                        {{ `${data.name} ${data.lastName}` }}
                    </span>
                    <span class="cl--secondary-white text text--level-3 center full-width">
                        {{ data.academic.jobPosition }}
                    </span>
                </a-space>
            </a-space>
        </div>
    </a-card>
</template>

<script>
import * as utils from '@/utils';
export default {
    data: () => {
        return {
            
        };
    },

    methods: {
        getFlag (country) {
            return utils.getFlag(country);
        }
    },

    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }   
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';

.memberCard{
    border-radius: 8px;
    border: none;
    height: 250px;

    .data {
        position: relative;
        left: 0;
        right: 0;
        margin: auto;
    }

    .picture {
        border: solid 4px $color-white;
        margin: 0 auto;
        display: block;
    }

    .avatar {
        img { object-fit: cover; }
        
        &.flag {
            width: 32px;
            height: 24px;
            border-radius: 2px;
            display: block;
            margin: -32px auto 0 auto;

            img { 
                width: 100%;
                height: 100%;
            }
        }
    }
}

</style>