import countries from "../constants/countries.json";

export function removeDuplicates(list, property) {
    var newList = [];
    var lookupObject = {};

    for (var i in list) {
        lookupObject[list[i][property]] = list[i];
    }

    for (i in lookupObject) {
        newList.push(lookupObject[i]);
    }
    return newList;
}

export function getUrlWhatsMe(phone) {
    let prefixMessage = "Saludos";
    let simplePhone = phone.split("+")[1];
    let formatPhone = simplePhone.replace(/ /g, "");
    
    let url =  `https://wa.me/${formatPhone}?text=${prefixMessage}`;

    return url;
}

export function getFlag(country) {
    const flag = countries.filter(item => item.alpha3Code === country)[0].flag;
    return flag;
}

export function getNameCountry(country) {
    const name = countries.filter(item => item.alpha3Code === country)[0].nativeName;
    return name;
}