<template>
    <div class="containerSection full-width">
        <!-- Modal VIdeo -->

        <a-modal
            v-model="showPresentation"
            :footer="null"
            :closable="false"
        >
            <a-row style="width: 100%; display: flex; justify-content: space-between; align-items: center; margin-bottom: 8px;">
                <span 
                    style="width: 100%;"
                    class="title title--level-3 cl--primary-blue tp-medium"
                >
                    {{ $t('HOME.SECTIONS.ABOUT_US.WHAT_IS_REACTORAL_BOARD') }} 
                </span>
                <a-button
                    type="link"
                    class="button button--primary"
                    @click="setShowPresentation(false)"
                >
                    X
                </a-button>
            </a-row>
            <my-video
                :sources="video.sources"
                :options="video.options"
            />
        </a-modal>

        <!-- End modal video -->
        <a-row class="full-width full-height">
            <a-col :span="12" :md="12" :sm="24" :xs="24" class="containerLeft full-height">
                <div class="maskImage" />
                <img :src="about_us" alt="about_us" class="imageSection" />

                <div class="textSection full-width">
                    <span class="title  cl--secondary-white full-width">
                        {{ $t('HOME.SECTIONS.ABOUT_US.DESCRIPTION') }} 
                    </span>
                </div>
                <span 
                    class="title title--level-3 cl--primary-blue link"
                    @click="goToSection(pathInstitutional)"
                >
                    {{ $t('HOME.SECTIONS.ABOUT_US.LINK') }} 
                </span>
            </a-col>
            <a-col :span="12" :md="12" :sm="24" :xs="24" class="containerRight">
                <div
                    v-html="$t('HOME.SECTIONS.ABOUT_US.SUMMARY')"
                >
                </div>
                <span 
                    style="text-align: center"
                    class="title title--level-3 cl--primary-blue tp-medium link"
                    @click="goToSection(pathRegister)"
                >
                    {{ $t('HOME.SECTIONS.ABOUT_US.JOIN_TO_RECTORAL_BOARD') }} 
                </span>
                <span style="text-align: center">
                    <a-button
                        type="link"
                        class="button button--primary"
                        @click="setShowPresentation(true)"
                    >
                        {{ $t('HOME.SECTIONS.ABOUT_US.SHOW_PRESENTATION') }} 
                    </a-button>
                </span>
            </a-col>
        </a-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
.textSummary {
    white-space: break-spaces;
    text-align: justify;
    margin-bottom: 32px;
} 
</style>

<script>
import { IMAGES, VIDEOS } from '@/constants/images';
import myVideo from 'vue-video'

    export default {
        data: () => {
            return {
                about_us: IMAGES.home.about_us,
                pathRegister: "/offer",
                pathInstitutional: "/institutional",
                showPresentation: false,

                video: {
                    sources: [{
                        src: VIDEOS.presentation,
                        type: 'video/mp4'
                    }],
                    options: {
                        autoplay: false,
                        volume: 0.6,
                    }
                }
            };
        },
        components: {
            myVideo
        },
        methods: {
            goToSection(path) {
                this.$router.push({ path })
            },

            setShowPresentation(status) {
                console.log({ status });
                this.showPresentation = status;
            }
        }
    }
</script>
