<template>
    <div>
        <div class="mainContainer sections full-width full-height bg--secondary-white">
            <a-space direction="vertical" :size="40" class="full-width">
                <div class="flex-column start-column-start full-width header">
                    <a-space direction="vertical" :size="32">
                        <Back :color="'dark'" path="/library" />
                        <span class="cl--primary-blue title">
                            {{ `${$t(categoryPath.name)} (${meta.itemCount || 0})` }}
                        </span>
                    </a-space>
                </div>

                <a-row :gutter="[16,24]" class="center-row-center full-width" v-if="categoryPath.keyName !== 'BENCHMARK'">
                    <a-col :lg="16" :xs="24">
                        <a-form-item class="full-width">
                            <a-input
                                :placeholder="$t('FORMS.SEARCH')"
                                v-model="search"
                                :disabled="loaded"
                                allowClear
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :lg="8" :xs="24">
                        <a-select
                            allowClear
                            class="full-width"
                            v-model="category"
                            :disabled="loaded"
                            :placeholder="$t('FORMS.SELECT_CATEGORY')"
                        >
                            <a-select-option
                                v-for="category in categoriesLibrary"
                                :value="category._id"
                                :key="category._id"
                            >
                                {{ category[language] }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                </a-row>

                <Loader :type="type" v-if="loaded"/>

                <div v-else class="containerList">
                    <a-list
                        :grid="{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 4 }" 
                        :data-source="library"
                        class="full-width full-height"
                    >
                        <a-list-item slot="renderItem" slot-scope="item">
                            <Document :category="category" :data=item />
                        </a-list-item>
                    </a-list>
                </div>
            </a-space>
        </div>
    </div>
</template>

<style lang="scss">
@import '@/styles/scss/config.scss';

.containerList {
    height: 800px;
    max-height: 800px;
    overflow-y: scroll;

    @include breakpoint(md) { height: 1200px; max-height: 1200px;}
}
</style>

<script>
import { LIBRARY_PATH } from "@/constants/library";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    data: () => {
        return { 
            categories: LIBRARY_PATH,
            loaded: true,
            type: 1,
            params: {
                limit: 500,
                page: 1,
            },
            languageKeys: [
                "es",
                "en",
                "pt",
            ]
        }
    },

    async created() {
        await this.initialData();
    },

    beforeDestroy() {
        this.clearLibrary();
    },

    computed: {
        ...mapState({
            landing: "landing" 
        }),

        ...mapGetters("landing", {
            library: "getLibrary",
            meta: "getMetaLibrary",
            categoriesLibrary: "getCategoriesLibrary",
        }),

        language () {
            const lngKey = this.$i18n.locale;
            return lngKey.toString().toUpperCase();
        },

        categoryPath () {
            const path = this.$route.params.category.toUpperCase();
            return this.categories[path];
        },

        typeLibrary () {
            const path = this.$route.params.category.toUpperCase();
            return this.categories[path].keyName;
        },

        search: {
            get() {
                return this.landing.libraries.search;
            },

            set(value) {
                this.searchLibrary(value);
            }
        },

        category: {
            get() {
                return this.landing.libraries.category;
            },

            set(value) {
                this.landing.libraries.category = value;
            }
        },
    },

    methods: {
        ...mapActions("landing", {
			getLibraryStore: "getLibrary",
            searchLibrary: "searchLibrary",
            setDefaultLibraryStore: "setDefaultLibrary",
		}),

        async initialData() {
            await this.clearLibrary();
            if (!this.library.length) {
                const params = { ...this.params, type: this.typeLibrary };
                await this.getLibraryStore(params);
                this.loaded = false;
            }
        },

        async clearLibrary() {
            await this.setDefaultLibraryStore();
        },
    },
}
</script>