<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
</style>

<template>
    <div class="mainContainer bg--secondary-grey-alt">
        <div class="containerSections" style="position: relative">
            <a-space 
                :size="0" 
                direction="vertical" 
                class="full-width containerMenu"
            >
                <a-row 
                    class="full-width containerCards" 
                    justify="space-between" 
                    align="middle"
                >
                    <a-col
                        class="full-height"
                        span="6"
                        :key="index"
                        v-for="(section, index) in sections.list"
                        @click="changeSectionActive(index)"
                    >
                        <a-card :class="getSectionClass(index)">
                            <span slot="title" class="title text--level-3">
                                {{ $t(`${section}`) }}
                            </span>
                        </a-card>    
                    </a-col>
                </a-row>
            </a-space>
            <div class="bg--secondary-grey-alt containerContent">
                <About v-if="sections.active === 0" />
                <ExternalSchedule v-if="sections.active === 1" />
                <Contributions v-if="sections.active === 2" />
                <Apporters v-if="sections.active === 3" />
            </div>
        </div>
    </div>
</template>

<script>
import About from "./subcomponents/About";
import Contributions from "./subcomponents/Contributions";
import ExternalSchedule from "./subcomponents/ExternalSchedule";
import Apporters from "./subcomponents/Apporters";

export default {
	data: function() {
		return {
            keySections: [
                "about-us",
                "external-schedule",
                "technical-contributions",
                "institutional-supporters"
            ],
			sections: {
				active: 0,
				size: 4,
				list: [
                    'HOME.SECTIONS.ABOUT_US.TITLE',
                    'HOME.SECTIONS.EXTERNAL_SCHEDULE.TITLE',
                    'HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.TITLE',
                    'HOME.SECTIONS.INSTITUTIONAL_SUPPORTERS.TITLE',
				]
			}
		};
	},
	props: {
		activeSection: {
            type: String,
            default: "about-us"
        }
	},
	watch: {
		activeSection: function(index) {
			this.sections.active = this.keySections.indexOf(index);
		}
	},
	components: {
		About,
        Contributions,
        ExternalSchedule,
        Apporters,
	},
	methods: {
        getSectionClass(position) {
            return {
                cardSections: true,
				active: this.sections.active == position ? true : false
			};
        },
		changeSectionActive(key) {
			this.sections.active = key;
		},
	}
};
</script>
