import Vue from "vue"

/** 
 * GENERAL COMPONENTS
*/ 

import Navbar from "@/components/Navbar"
import NavbarMobile from "@/components/NavbarMobile"
import Portrait from "@/components/Portrait"
import Footer from "@/components/Footer"
import LocaleSwitcher from "@/components/LocaleSwitcher"

Vue.component("Navbar", Navbar);
Vue.component("NavbarMobile", NavbarMobile);
Vue.component("Portrait", Portrait);
Vue.component("Footer", Footer);
Vue.component("LocaleSwitcher", LocaleSwitcher);

/** 
 * DASHBAORD COMPONENTS
 */ 
import Sidebar from "@/components/profile/Sidebar"
import HeaderDashboard from "@/components/profile/Header"

Vue.component("Sidebar", Sidebar);
Vue.component("HeaderDashboard", HeaderDashboard);

/** 
 * WIDGETS
 */ 
import Calendar from "@/widgets/Calendar"
import Headers from "@/widgets/Headers"
import HeaderSection from "@/widgets/HeaderSection"
import Kpi from "@/widgets/Kpi"
import CopyRight from "@/widgets/CopyRight"
import CountryCodeSelector from "@/widgets/CountryCodeSelector"
import CountrySelector from "@/widgets/CountrySelector"
import InstitutionSelector from "@/widgets/InstitutionSelector"
import ComingSoon from "@/widgets/ComingSoon"
import Back from "@/widgets/Back"
import Loader from "@/widgets/Loader"

Vue.component("Calendar", Calendar);
Vue.component("Headers", Headers);
Vue.component("Kpi", Kpi);
Vue.component("CopyRight", CopyRight);
Vue.component("Sidebar", Sidebar);
Vue.component("HeaderDashboard", HeaderDashboard);
Vue.component("HeaderSection", HeaderSection);
Vue.component("CountryCodeSelector", CountryCodeSelector);
Vue.component("CountrySelector", CountrySelector);
Vue.component("InstitutionSelector", InstitutionSelector);
Vue.component("ComingSoon", ComingSoon);
Vue.component("Back", Back);
Vue.component("Loader", Loader);

/** 
 * CARDS
*/ 

import ActivityCard from "@/widgets/Cards/Activity"
import ProgressCard from "@/widgets/Cards/Progress"
import Member from "@/widgets/Cards/Member"
import Article from "@/widgets/Cards/Article"
import Document from "@/widgets/Cards/Document"

Vue.component("ProgressCard", ProgressCard);
Vue.component("ActivityCard", ActivityCard);
Vue.component("Member", Member);
Vue.component("Article", Article);
Vue.component("Document", Document);
