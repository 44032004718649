<template>
    <div :class="`scheduleWebinar full-width ${category.class}`">
        <div :class="`cover ${category.class}`"/>
        <a-row justify="space-around" align="middle" :gutter="[24, 32]" class="noMargin full-width containerSchedules">
            <a-col :xs="24"  :xxl="12">
                    <div class="containerVideo flex-column center-column-center">
                        <div
                            v-if="video.isAvailable"
                            v-html="video.frame"
                            class="full-width full-height"
                        />
                        <a-space class="full-width" :size="8" direction="vertical" v-else>
                            <a-icon
                                slot="icon"
                                type="video-camera"
                                :style="{ 
                                    fontSize: '48px',
                                    color: '#1b4484',
                                    margin: '0 auto',
                                    display: 'block',
                                }" 
                            />
                            <span class="title text--level-2 cl--primary-blue center full-width">
                                {{ $t('WEBINARS.VIDEO_IS_NOT_AVAILABLE') }}
                            </span>
                        </a-space>
                    </div>
            </a-col>
            
            <a-col :xs="24"  :xxl="12">

                <a-space direction="vertical" :size="32" class="full-width">
                    <a-space direction="vertical" :size="2" class="full-width">
                        <span class="title title--level-3 tp-light full-width">
                            {{ $t('WEBINARS.DATE') }}
                        </span>
                        <span class="title title--level-1 date full-width">
                            {{ formatDate(date) }}
                        </span>
                    </a-space>

                    <a-space direction="vertical" :size="8" class="full-width">
                        <span class="title title--level-2 full-width">
                            {{ $t('WEBINARS.LIMITED_VOUCHERS') }}
                        </span>

                        <a-button 
                                type="primary"
                                ghost
                                @click="goToLink(link)"
                                v-if="isNext"
                            >
                                {{ $t('WEBINARS.INSCRIPTION_ME') }}
                            </a-button>

                            <span v-else class="text text--level-2 full-width left">
                                {{ $t('WEBINARS.NO_INSCRIPTION_LINK') }}
                            </span>

                    </a-space>
                    
                    <a-space direction="vertical" :size="16" class="full-width">
                        <span class="title title--level-2 full-width">
                            {{ $t('WEBINARS.TRANSMISSION_SCHEDULES') }}
                        </span>

                        <div class="flex-row start-row-center">
                            <div
                                class="schedule flex-column start-column-start"
                                v-for="schedule in schedules"
                                :key="schedule.id"
                                style="margin-right: 16px"
                            >
                                    <a-avatar
                                        :src="getFlag(schedule.country)"
                                        shape="square"
                                        class="avatar flag"
                                    />
                                    <span class="center title text--level-3 full-width">
                                        {{ schedule.time }}
                                    </span>
                            </div>
                        </div>
                    </a-space>

                    <a-space direction="vertical" :size="16" class="full-width">
                        <span class="title title--level-2 full-width left">
                            {{ $t('WEBINARS.TRANSMISSION_LIVE') }}
                        </span>
                        <span class="text text--level-2 full-width left">
                            {{ $t('WEBINARS.TRANSMISSION_DESCRIPTION') }}
                        </span>

                        <div class="flex-row start-row-center containerChannel">
                            <div
                                class="channel flex-column start-column-start"
                                v-for="channel in transmissions"
                                :key="channel.id"
                                style="margin-right: 16px"
                            >
                                <a-space direction="vertical" :size="8" class="full-width">
                                    <a-avatar
                                        :src="channel.logo"
                                        shape="square"
                                        style="width: 150px"
                                    />
                                </a-space>
                            </div>
                        </div>

                    </a-space>
                </a-space>
            </a-col>
        </a-row>
    </div>
</template>

<style lang="scss">
@import "@/styles/scss/config.scss";
@import "@/styles/scss/views/categorySelection.scss";
.containerVideo {
    height: 420px;
    border: solid 1px;
    border-radius: 4px;
}
</style>

<script>
import * as utils from '@/utils';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb'
import 'moment/locale/pt-br'

export default {
    props: {
        schedules: {
            type: Array,
            default: () => {}
        },
        video: {
            type: Object,
            default: () => {}
        },
        date: {
            type: String,
            default: ""
        },
        link: {
            type: String,
            default: ""
        },
        transmissions: {
            type: Array,
            default: () => {}
        },
        category: {
            type: Object,
            default: () => {}
        },
        isNext: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        goLink(link) {
            window.open(link, '_blank');
        },
        getFlag (country) {
            return utils.getFlag(country);
        },

        formatDate (date) {
            moment.locale(this.$i18n.locale);
            return moment(date).format('LLL');
        }
    },
}
</script>