import axios from "axios";
import { BASE_URL, routes, paths } from '../../api-routes';
import queryString from 'querystring'

export async function getWelcomePhrases(queryParams) { 
    try {

        let url = `${BASE_URL}${paths.LANDING}${routes.GET_WELCOME_PHRASE}?${queryString.stringify(queryParams)}`;
        let response = await axios.get(url);
        return response.data;
        
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}

export async function getLineBackers() { 
    try {

        let url = `${BASE_URL}${paths.LANDING}${routes.GET_LINE_BACKERS}`;

        let response = await axios.get(url);
        return response.data;
        
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}

export async function getContributions() { 
    try {

        let url = `${BASE_URL}${paths.LANDING}${routes.GET_CONTRIBUTIONS}`;

        let response = await axios.get(url);
        return response.data;
        
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}

export async function getSelfEvents() { 
    try {

        let url = `${BASE_URL}${paths.LANDING}${routes.GET_SELF_EVENTS}`;

        let response = await axios.get(url);
        return response.data;
        
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}

export async function getKPIS() { 
    try {

        let url = `${BASE_URL}${paths.LANDING}${routes.GET_KPIS}`;

        let response = await axios.get(url);
        return response.data;
        
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}

export async function getBoardNews(queryParams) {
    try {

        let url = `${BASE_URL}${paths.LANDING}${routes.GET_BOARD_NEWS}?${queryString.stringify(queryParams)}`;

        let response = await axios.get(url);
        return response.data;
        
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }   
}

export async function getWebinarsByCategory(queryParams) {
    try {
        let url = `${BASE_URL}${paths.LANDING}${routes.GET_WEBINARS_BY_CATEGORY}?${queryString.stringify(queryParams)}`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            code: 404,
            message: error.response.data.message
        }
    }
}

export async function getPartners() {
    try {
        let url = `${BASE_URL}${paths.LANDING}${routes.GET_PARTNERS}`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            code: 404,
            message: error.response.data.message
        }
    }
}

export async function getLibrary(queryParams) {
    try {
        let url = `${BASE_URL}${paths.LANDING}${routes.GET_LIBRARY}?${queryString.stringify(queryParams)}`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            code: 404,
            message: error.response.data.message
        }
    }
}

export async function getArticles(queryParams) {
    try {
        let url = `${BASE_URL}${paths.LANDING}${routes.GET_ARTICLES}?${queryString.stringify(queryParams)}`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            code: 404,
            message: error.response.data.message
        }
    }
}

export async function getExternalParticipations() {
    try {
        let url = `${BASE_URL}${paths.LANDING}${routes.GET_EXTERNAL_PARTICIPATIONS}`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            code: 404,
            message: error.response.data.message
        }
    }
}

export async function getArticleDetail(articleID) {
    try {
        let url = `${BASE_URL}${paths.LANDING}${routes.GET_ARTICLE_DETAIL}?articleID=${articleID}`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            code: 404,
            message: error.response.data.message
        }
    }
}