<template>
    <div>
        <Navbar 
            @updateSection="updateSection"
            @handleNavbarMobile="(value) => handleNavbarMobile(value)"
        />
        <NavbarMobile 
            :active="activeNavbarMobile"
            @updateSection="(value) => updateSection(value)"
            @close="() => activeNavbarMobile = false"
        />
        <Portrait 
            :imageUrl="imageUrl"
            :header="header"
            :description="description"    
        />
        <Sections :activeSection="activeSection" />
        <Footer/>
    </div>
</template>
<style>
</style>

<script>
import Sections from "@/components/briefcase/Sections";
import { IMAGES } from "@/constants/images"

export default {
    name: "briefcase",
    data: () => {
        return {
            imageUrl: IMAGES.services.portrait,
            header: "BRIEFCASE.HEADER",
            description: "BRIEFCASE.DESCRIPTION",
            activeSection: "mentoring-180",
            activeNavbarMobile: false,
        }
    },
    mounted() {
        const fullPath = this.$route.fullPath;
        const section = fullPath.split("=")[1];
        this.updateSection(section);
    },
    components: {
        Sections,
    },
    methods: {
        updateSection(keySection) {
            this.activeSection = keySection;
        },
        handleNavbarMobile(value) {
            this.activeNavbarMobile = value;
        }
    },
}
</script>