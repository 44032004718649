<template>
    <div class="full-width containerBriefcase">
        <a-space direction="vertical" :size="32" class="full-width">
            <span class="cl--primary-blue title title--level-1 center">
                {{ $t('BRIEFCASE.SECTIONS.SCHEMA.DESCRIPTION') }}
            </span>
            
            <img :src="pyramid" class="img-pyramid"/>
            <img :src="groups" class="img-groups"/>
        </a-space>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
.containerBriefcase {
    padding-top: 45px;
    overflow-y: scroll;
    position: relative;
}
.img-pyramid, .img-groups {
    width: 100%;
    display: block;
    margin: 0 auto;
}
</style>

<script>
import { IMAGES } from "@/constants/images";

export default {    
    data: () => {
        return {
            pyramid: IMAGES.services.pyramid,
            groups: IMAGES.services.groups
        };
    }
}
</script>