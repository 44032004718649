<template>
    <div class="scheduleWebinar full-width" style="margin: 0">
        <div :class="`cover ${category.class}`"/>
        <a-row
            justify="space-around"
            align="middle"
            :gutter="[24, 32]"
            class="noMargin full-width containerSchedules"
        >
            <a-col :xs="24" :xl="14" :xxl="12">
                    <a-space direction="vertical" :size="2" class="full-width">
                        <span class="title title--level-3 tp-light full-width">
                            {{ $t('WEBINARS.DATE') }}
                        </span>
                        <span class="title title--level-1 date full-width">
                            {{ formatDate(date) }}
                        </span>
                    </a-space>
            </a-col>
            
            <a-col :xs="24" :xl="10" :xxl="12">
                

                
            </a-col>
        </a-row>
    </div>
</template>

<style lang="scss">
@import "@/styles/scss/config.scss";
@import "@/styles/scss/views/categorySelection.scss";
</style>

<script>
import * as utils from '@/utils';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb'
import 'moment/locale/pt-br'

export default {
    props: {
        schedules: {
            type: Array,
            default: () => {}
        },
        video: {
            type: Object,
            default: () => {}
        },
        date: {
            type: String,
            default: ""
        },
        link: {
            type: String,
            default: ""
        },
        transmissions: {
            type: Array,
            default: () => {}
        },
        category: {
            type: Object,
            default: () => {}
        },
        isNext: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        goLink(link) {
            window.open(link, '_blank');
        },
        getFlag (country) {
            return utils.getFlag(country);
        },

        formatDate (date) {
            moment.locale(this.$i18n.locale);
            return moment(date).format('LLL');
        }
    },
}
</script>