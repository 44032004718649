<template>
    <a-layout-sider v-model="collapsed" collapsible breakpoint="md" collapsed-width="0">
		<div class="logo" style="padding: 24px"> 
            <img :src="logo" alt=""  class="full-width"/>
        </div>
		<a-menu theme="dark" :default-selected-keys="[activeSection]">
			<a-menu-item
				v-for="(option, index) in menu"
				:key="index"
				@click="changeSection(index, option.path)"
			>
				<a-icon :type="option.icon" size="40" />
				<span 
                    class="text text--level-3 cl--secondary-white"
                    style="display: inline-block;"
                >
                    {{ $t(option.keyName) }}
                </span>
			</a-menu-item>
		</a-menu>
	</a-layout-sider>
</template>

<script>
import { IMAGES } from '@/constants/images'

export default {
	data: () => {
		return {
            logo: IMAGES.logos.vertical_svg,
            collapsed: true,
            activeSection: 0,
            menu: [
                {
                    keyName: "PROFILE.MENU.HOME",
                    icon: "home",
                    path: ""
                },
                {
                    keyName: "PROFILE.MENU.ACCOUNT",
                    icon: "user",
                    path: "mi-cuenta",
                },
                {
                    keyName: "PROFILE.MENU.SERVICES",
                    icon: "apartment",
                    path: "servicios"
                },
                {
                    keyName: "PROFILE.MENU.KTDRA",
                    icon: "form",
                    path: "mi-blog",
                },
                {
                    keyName: "PROFILE.MENU.CONTRIBUTIONS",
                    icon: "global",
                    path: "mis-aportes",
                },
                {
                    keyName: "PROFILE.MENU.LIBRARY",
                    icon: "book",
                    path: "mi-biblioteca",
                },
                {
                    keyName: "PROFILE.MENU.LOGOUT",
                    icon: "logout",
                    path: ""
                }
            ],
		};
	},
    
    beforeMount() { this.trackingPath() },

	methods: {
        trackingPath() {
            let path = this.$route.fullPath;
            let section = path.split("/")[2];

            this.menu.map((option, index) => {
                if (section !== undefined) {
                    if (option.path === section) {
                        this.activeSection = index;
                    }
                }
            });
        },

        changeSection(index, path) {
            console.log(path);
            if (index === 6) {
                this.logout();
            } else {
                this.$router.push({ path: "../perfil/" + path });
                this.activeSection = index;
            }
        },

        logout() {
            //TO DO cerrar sesión
            console.log("close session");
        }
	}
};
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';
</style>