import * as api from "@/api/landing.js";
import * as phrasesAPI from "@/api/phrases.js";
import * as supportersAPI from "@/api/supporters.js";
import * as webinarsAPI from "@/api/webinars.js";
import * as outsideHoldingsAPI from "@/api/outside-holdings.js";
import * as metricsAPI from "@/api/metrics.js";
import * as newsAPI from "@/api/news.js";
import * as articlesAPI from "@/api/articles.js";
import * as usersAPI from "@/api/users.js";
import * as contributionsAPI from "@/api/contributions.js";
import * as libraryAPI from "@/api/library.js";

export const getWelcomePhrases = async({ commit }, queryParams) => {
    try {
        let response  = await phrasesAPI.getWelcomePhrases(queryParams);
        if (response.statusCode === 200) commit('SET_WELCOME_PHRASES', response.data);
        return response.statusCode;
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
}

export const getLineBackers = async({ commit }) => {
    try {
        let response  = await supportersAPI.getLineBackers();
        if (response.statusCode === 200) commit('SET_LINE_BACKERS', response.data);
        return response.statusCode;
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
}

export const getContributions = async({ commit }, queryParams) => {
    try {
        let response  = await contributionsAPI.getContributions(queryParams);
        const { data, meta } = response;
        if (response.statusCode === 200) commit('SET_CONTRIBUTIONS', data, meta);
        return response.statusCode;
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
}

export const getWebinarsCalendar = async({ commit }) => {
    try {
        let response  = await webinarsAPI.getWebinarsCalendar();
        if (response.statusCode === 200) commit('SET_WEBINARS_CALENDAR', response.data);
        return response.statusCode;
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
}

export const getOutsideHoldings = async ({ commit }, queryParams) => {
    try {
        let response  = await outsideHoldingsAPI.getOutsideHoldings(queryParams);
        const { data, meta } = response;
        if (response.statusCode === 200) commit('SET_OUTSIDE_HOLDINGS', data, meta);
        return response.statusCode;
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
}

export const getMetrics = async({ commit }) => {
    try {
        let response  = await metricsAPI.getMetrics();
        if (response.statusCode === 200) commit('SET_METRICS', response.data);
        return response.statusCode;
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
}

export const getNews = async({ commit }, queryParams) => {
    try {
        let response  = await newsAPI.getNews(queryParams);
        const { data, meta } = response;
        if (response.statusCode === 200) commit('SET_NEWS', data, meta);
        return response.statusCode;
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
}

export const getArticles = async({ commit }, queryParams) => {
    try {
        let response  = await articlesAPI.getArticles(queryParams);
        const { data, meta } = response;
        if (response.statusCode === 200) commit('SET_ARTICLES', data, meta);
        return response.statusCode;
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
}

export const setDefaultArticles = async({ commit }) => commit('SET_DEFAULT_ARTICLES');

export const searchArticles = async({ commit }, value) => commit('SET_QUERY_ARTICLES', value);

export const getArticleDetail = async({ commit }, articleID) => {
    try {
        let response  = await articlesAPI.getArticleDetail(articleID);
        const { data } = response;
        if (response.statusCode === 200) commit('SET_ARTICLE_DETAIL', data);
        return response.statusCode;
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
}

export const getComments = async ({ commit }, articleID) => {
    try {
        let response  = await articlesAPI.getComments(articleID);
        const { data } = response;
        if (response.statusCode === 200) commit('SET_ARTICLE_COMMENTS', data);
        return response.statusCode;
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }

}

export const getPartners = async({ commit }, queryParams) => {
    try {
        let response  = await usersAPI.getPartners(queryParams);
        const { data, meta } = response;
        if (response.statusCode === 200) commit('SET_PARTNERS', data, meta);
        return response.statusCode;
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
}

export const getWebinarsByCategory = async ({ commit }, queryParams) => {
    try {
        let response  = await webinarsAPI.getWebinarsByCategory(queryParams);
        const { data, meta } = response;
        if (response.statusCode === 200) commit('SET_WEBINARS_BY_CATEGORY', data, meta);
        return response.statusCode;
                
    } catch (error) {
        return {
            code: -1,
            message: error.response.data.message
        }
    }
}

export const clearWebinarsByCategory = async({ commit }) => {
    commit('SET_DEFAULT_WEBINARS_BY_CATEGORY');
}

export const getLibrary = async ({ commit }, queryParams) => {
    try {
        let response  = await libraryAPI.getLibrary(queryParams);
        if (response.statusCode === 200) commit('SET_LIBRARY', response);
        return response.statusCode;
                
    } catch (error) {
        return {
            code: -1,
            message: error
        }
    }
}

export const setDefaultLibrary = async({ commit }) => commit('SET_DEFAULT_LIBRARY');

export const searchLibrary = async({ commit }, value) => commit('SET_QUERY_LIBRARY', value);


export const setDefaultPartners = async({ commit }) => commit('SET_DEFAULT_PARTNERS');

export const searchPartners = async({ commit }, value) => commit('SET_QUERY_PARTNERS', value);

export const getKPIS = async({ commit }) => {
    try {
        let response  = await api.getKPIS();
        if (response.status === 200) commit('SET_KPIS', response.body);
        return response.status;
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
} 

export const getBoardNews = async({ commit }, queryParams) => {
    try {
        let response  = await api.getBoardNews(queryParams);
        if (response.status === 200) commit('SET_BOARD_NEWS', response.body);
        return response.status;
        
    } catch (error) {
        return {
            code: -1,
            message: error.response.data.message
        };
    }
}

export const getInstitutionalPartners = async ({ commit }) => {
    try {
        let response  = await api.getPartners();
        
        if (response.status === 200) commit('SET_PARTNERS', response.body);
        return response.status;
                
    } catch (error) {
        return {
            code: -1,
            message: error.response.data.message
        }
    }
}

export const clearWebinarsCalendar = async({ commit }) => {
    commit('SET_DEFAULT_WEBINARS_CALENDAR');
}

export const getExternalParticipations = async({ commit }) => {
    try {
        let response  = await api.getExternalParticipations();
        if (response.status === 200) commit('SET_EXTERNAL_PARTICIPATIONS', response.body);
        return response.status;
        
    } catch (error) {
        return {
            code: -1,
            message: error.response.data.message
        };
    }
}
