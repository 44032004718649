<template>
    <div class="mainContainer sections full-width full-height">

        <Headers
            :showLine="headers.line"
            :title="headers.title"
            align="center"
        />

        <div class="subSections">
            <a-row justify="space-between" align="middle">
                <a-col :span="8" :md="8" :sm="24" :xs="24"
                    v-for="section in sections"
                    :key="section.index"
                    :style="`background: ${section.color}`"
                    class="inst__section flex-column start-column-start"
                >
                    <div class="full-width full-height flex-column start-column-center">
                        <div class="flex-row start-row-start containerTitle">
                            <span class="title title--level-2 cl--secondary-white">
                                {{ $t(`${section.name}.TITLE`) }}
                            </span>
                        </div>
                        <div class="containerDescription">
                            <span 
                                class="title text--level-2 cl--secondary-white" 
                                style="margin-bottom: 16px; display: flex"
                            >
                                {{ $t('INSTITUTIONAL.ACTION_SECTORS.VALUE_PROPOSAL') }}
                            </span>
                            <span class="text text--level-2 cl--secondary-white item">
                                {{ $t(`${section.name}.DESCRIPTION`) }}
                            </span>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>
        
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';

.inst__section {
    max-height: fit-content !important;
    min-height: fit-content !important;
    height: fit-content !important;

    @include breakpoint(md) {
        max-height: 475px !important;
        min-height: 475px !important;
    }
}

.containerDescription {
    height: fit-content !important;

    @include breakpoint(md) { height: calc(260px - 170px); }
}

</style>

<script>
export default {
    data() {
        return {
            headers: {
                title: "INSTITUTIONAL.ACTION_SECTORS.HEADER",
                line: false,
            },
            sections: [
                {
                    index: 0,
                    color: "#1b4484",
                    name: "INSTITUTIONAL.ACTION_SECTORS.IES",
                    countItems: 2,
                },
                {
                    index: 1,
                    color: "#1976d2",
                    name: "INSTITUTIONAL.ACTION_SECTORS.NETWORKS",
                    countItems: 2,
                },
                {
                    index: 2,
                    color: "#1b4484",
                    name: "INSTITUTIONAL.ACTION_SECTORS.POLITICS",
                    countItems: 5,
                }
            ]
        }
    }
}
</script>