import Vue from "vue";
import i18n from '@/plugins/i18n'

import "@/plugins/components"
import "@/plugins/vue-moment"
import "@/plugins/font-awesome"
import "@/plugins/ant-design"
import "@/plugins/google-maps"
import "@/plugins/vue-paginate"

import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

import axios from "axios";
import VueAxios from "vue-axios";

import { auth } from "./config/firebase/auth";

import EasySlider from 'vue-easy-slider'

import VueTelInput from "vue-tel-input";
import VueScrollTo from "vue-scrollto";
import "animate.css";
import AudioVisual from "vue-audio-visual";
import VueAudio from "vue-audio-better";
import QrcodeVue from "qrcode.vue";
import VueHtml2pdf from "vue-html2pdf";
import JsonCSV from "vue-json-csv";
/* import "sass-loader"; */
import ToastSnackbar from "vue-toaster-snackbar";
import "vue-toaster-snackbar/public/toast.scss";
import VueLazyload from 'vue-lazyload'
import Meta from "vue-meta";

Vue.config.productionTip = false;

Vue.use(VueTelInput, {
    placeholder: "Ingresa tu número de celular"
});

axios.defaults.headers.common["Access-Control-Allow-CrossOrigin"] = "*";

Vue.use(VueAxios, axios);
Vue.use(AudioVisual);
Vue.use(VueAudio);
Vue.use(QrcodeVue);
Vue.use(VueHtml2pdf);
Vue.use(ToastSnackbar);
Vue.use(EasySlider);

Vue.component("downloadCsv", JsonCSV);

Vue.use(VueScrollTo);

Vue.use(VueLazyload)
Vue.use(Meta);

let app = null;

auth.onAuthStateChanged(async user => {
    if (user && localStorage.userID) {
        let response = await store.dispatch('users/initializeData', user.uid);
        if (response != 200) await auth.signOut();
    } else {
        await store.dispatch('users/logout')
    }
    if (!app) {
        app = new Vue({
            router,
            store,
            i18n,
            render: h => h(App)
        }).$mount("#app");
    }
});
