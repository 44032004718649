var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainContainer sections full-width full-height bg--secondary-grey-alt"},[_c('a-modal',{staticClass:"modalEvent",attrs:{"width":'800px',"footer":null},model:{value:(_vm.showEvent),callback:function ($$v) {_vm.showEvent=$$v},expression:"showEvent"}},[_c('Event',{attrs:{"event":_vm.eventSelected},on:{"changeStateModal":(eventData, state) => _vm.changeStateModal(eventData, state)}})],1),_c('a-space',{staticClass:"full-width",attrs:{"direction":"vertical","size":40}},[_c('Headers',{attrs:{"showLine":_vm.headers.line,"title":_vm.headers.title,"description":_vm.headers.description,"align":"left"}}),(_vm.loaded)?_c('Loader',{attrs:{"type":_vm.type}}):_c('div',{staticClass:"flex-row center-row-center full-width containerCalendar"},[_c('a-row',{staticClass:"full-width full-height",attrs:{"gutter":0,"justify":"space-between","align":"middle"}},[_c('a-col',{staticStyle:{"overflow":"hidden"},attrs:{"span":24,"md":24,"sm":24,"xs":24}},[_c('div',{staticClass:"flex-row start-column-start containerEvents"},_vm._l((_vm.webinarsCalendar),function(event,position){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(event.month !== 0),expression:"event.month !== 0"}],key:position,staticClass:"cl--primary-blue flex-column center-column-center events",class:{
                                'first': position === 0,
                                'last': position === event.length - 1
                            }},[_c('div',{staticClass:"month flex-column start-column-center"},[_c('span',{staticClass:"title text--level-1 cl--primary-blue monthText"},[_vm._v(" "+_vm._s(_vm.$t(`MONTHS.${event.name}`))+" ")]),_c('div',{staticClass:"containerDays flex-row start-row-center"},_vm._l((event.days),function(day,INDEX){return _c('span',{key:`month_${position}_day_${day}`,staticClass:"dayMonth title text--level-2 cl--primary-blue active",class:{
                                            'first': INDEX === 0,
                                            'last': INDEX === event.days.length - 1,
                                        }},[_vm._v(" "+_vm._s(day)+" ")])}),0)]),_vm._l((event.list),function(webinar){return _c('div',{key:webinar._id,staticClass:"flex-column center-column-center listWebinars"},[_c('div',{staticClass:"full-width full-height"},[_c('div',{staticClass:"webinar flex-column start-column-start full-height",class:webinar.category.class,on:{"click":function($event){return _vm.changeStateModal(webinar, true)}}},[_c('a-space',{staticClass:"full-width",attrs:{"direction":"horizontal","size":8}},[_c('a-avatar',{staticClass:"logoWebinar",attrs:{"shape":"square","src":webinar.category.logo}}),_c('div',[_c('span',{staticClass:"title text--level-2"},[_vm._v(" "+_vm._s(`${webinar.tag} ${_vm.$t('WEBINARS.INTERVIEW')}`)+" ")]),_c('span',{staticClass:"title text--level-3"},[_vm._v(" "+_vm._s(webinar.category.name)+" ")])])],1),_c('span',{staticClass:"text text--level-3 date"},[_vm._v(" "+_vm._s(_vm.formatDate(webinar.date))+" ")]),(webinar.isNext)?_c('span',{staticClass:"isNext title text--level-3 right full-width"},[_vm._v(" "+_vm._s(_vm.$t('WEBINARS.IS_NEXT'))+" ")]):_vm._e()],1)])])})],2)}),0)])],1)],1),_c('OutsideHoldings')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }