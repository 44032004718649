<template>
    <div class="mainContainer sections full-width full-height">

        <Headers
            :showLine="headers.line"
            :title="headers.title"
            align="center"
        />
        
        <div class="subSections">
            <a-row justify="space-between" align="middle">
                <a-col :span="8" :md="8" :sm="24" :xs="24"
                    v-for="section in sections"
                    :key="section.index"
                    :style="`background: ${section.color}`"
                    class="inst__section flex-column start-column-start"
                >
                    <div class="full-width full-height flex-column start-column-center">
                        <div class="flex-row start-row-start containerTitle">
                            <span class="title title--level-2 cl--secondary-white">
                                {{ $t(`${section.name}.TITLE`) }}
                            </span>
                        </div>
                        <div class="containerDescription">
                            <span
                                class="text text--level-2 cl--secondary-white item"
                                v-for="(items, index) in section.countItems"
                                :key="`${section}_${index}`"
                            >
                                {{ "- " +  $t(`${section.name}.${items}`) }}
                            </span>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>


<script>

export default {
    data: () => {
        return {
            headers: {
                title: "INSTITUTIONAL.IDENTITY.HEADER",
                line: false,
            },
            sections: [
                {
                    index: 0,
                    color: "#1b4484",
                    name: "INSTITUTIONAL.IDENTITY.MISION",
                    countItems: 1,
                },
                {
                    index: 1,
                    color: "#1976d2",
                    name: "INSTITUTIONAL.IDENTITY.VISION",
                    countItems: 4,
                },
                {
                    index: 2,
                    color: "#1b4484",
                    name: "INSTITUTIONAL.IDENTITY.BE_RECTORAL_BOARD",
                    countItems: 5,
                }
            ]
        }
    }
}

</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
</style>