<template>
    <div>
        <span
            slot="title"
            class="title title--level-3 cl--secondary-white bg--primary-blue full-width flex-row start-row-center headerContact"
        >
            {{ $t('BRIEFCASE.QUOTE_TOOL_BOX.TITLE') }}
        </span>

        <a-form layout="vertical" @submit.prevent="sendQuoteToolBox" :form="form">
            <div class="containerForm full-width full-height" style="padding: 24px; max-height: 600px; overflow-y: scroll">
                <a-row :gutter="16" class="center-row-center">
                    <a-col :span="24" :md="24" :sm="24" :xs="24">
                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.NAMES')}`">
                            <a-input
                                v-decorator="[
                                    'names',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.NAMES')}`"
                            >
                                <a-icon slot="prefix" type="user" />
                            </a-input>
                        </a-form-item>
                        
                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.EMAIL')}`">
                            <a-input
                                v-decorator="[
                                    'email',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.EMAIL')}`"
                            >
                                <a-icon slot="prefix" type="mail" />
                            </a-input>
                        </a-form-item>

                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.INSTITUTE')}`">
                            <a-input
                                v-decorator="[
                                    'institute',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.INSTITUTE')}`"
                            >
                                <a-icon slot="prefix" type="bank" />
                            </a-input>
                        </a-form-item>

                        <CountryCodeSelector
                            @changeCountryCode="(value) => changeCountryCode(value)"
                            @changeMobile="(value) => changeMobile(value)"
                        />

                        <a-form-item :label="`${$t('BRIEFCASE.QUOTES.CONTEXTUALIZE')}`">
                            <a-textarea
                                v-decorator="[
                                    'contextualize',
                                    {
                                        rules: [{
                                            required: true,
                                            message: $t('FORMS.REQUIRED'),
                                        }],
                                    },
                                ]"
                                :placeholder="`${$t('BRIEFCASE.QUOTES.CONTEXTUALIZE')}`"
                                :auto-size="{ minRows: 3, maxRows: 6 }"
                            />
                        </a-form-item>

                        <a-space :size="16" direction="vertical" class="full-width">

                            <span class="title text--level-2 cl--secondary-black full-width flex-row start-row-center">
                                {{ $t('BRIEFCASE.QUOTE_TOOL_BOX.CHOOSE_TOOLS') }}
                            </span>

                            <div class="container__tools">
                                <div
                                    v-for="(tool, indexTool) in toolBox"
                                    :key="tool.key"
                                    style="margin-bottom: 48px"
                                >
                                    <div class="container__tools--box">
                                        <span class="title text--level-2 cl--primary-blue full-width flex-row start-row-center">
                                            {{ $t(`BRIEFCASE.QUOTE_TOOL_BOX.${tool.name}`) }}
                                        </span>
                                    </div>

                                    <div
                                        class="container__tools--box-tool"
                                        v-for="(box, index) in tool.tools"
                                        :key="`${tool.key}_${index}`"
                                    >
                                        <span
                                            class="text--level-2"
                                            style="font-size: 15px; margin-right: 12px"
                                            v-if="box.item !== 'OTHERS'"
                                        >
                                            {{ $t(`BRIEFCASE.QUOTE_TOOL_BOX.${box.item}`) }}
                                        </span>

                                        <a-form-item
                                            class="full-width"
                                            v-if="box.item === 'OTHERS'"
                                            :label="`${$t('BRIEFCASE.QUOTE_TOOL_BOX.OTHERS')}`"
                                        >
                                            <a-textarea
                                                v-model="toolBoxOthers[tool.key]"
                                                :placeholder="`${$t('BRIEFCASE.QUOTE_TOOL_BOX.OTHERS_PLACEHOLDER')}`"
                                                :auto-size="{ minRows: 3, maxRows: 6 }"
                                            />
                                        </a-form-item>

                                        <a-switch
                                            v-else
                                            v-decorator="[
                                                `${box.item.value}`,
                                                {
                                                    valuePropName: 'checked',
                                                    initialValue: false,
                                                },
                                            ]"
                                            @change="onChange(indexTool, index)"
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        </a-space>
                    </a-col>
                </a-row>
            </div>
            <div class="flex-row end-row-end full-width" style="padding: 0 24px 24px">
                <a-space direction="horizontal" :size="24">

                    <a-button
                        ghost
                        size="large"
                        type="primary"
                        @click="changeStatusModal(false)"
                    >
                        {{ $t('CONTACT.CANCEL') }}
                    </a-button>

                    <a-button
                        :loading="loading"
                        size="large"
                        type="primary"
                        html-type="submit"
                    >
                        {{ $t('CONTACT.SEND') }}
                    </a-button>

                </a-space>
            </div>
        </a-form>


    </div>
</template>

<script>
import * as quotesAPI from "@/api/quotes.js";
import { notification } from "ant-design-vue";
import { TOOL_BOX_QUOTES } from '@/constants/toolBoxQuotes.js';
import { Switch } from "ant-design-vue";

export default {
    data: () => {
        return {
            loading: false,
            names: '',
            email: '',
            phone: '',
            institute: '',
            contextualize: '',
            countryCode: '',
            mobile: '',
            toolBox: TOOL_BOX_QUOTES,
            toolBoxOthers: {
                PLANNING: "",
                PROCESS: "",
                PLATFORM: "",
                PEOPLE: "",
                PROJECTS: "",
            }
        }
    },

    beforeCreate() {
		this.form = this.$form.createForm(this, { name: "planRequest" });
	},

    components: {
        notification,
        "a-switch": Switch
    },

    methods: {
        changeStatusModal(value) {
            this.$emit("changeStatusModal", value)
        },

        getFormValues (values) {

            const box = this.toolBox.map((box) => {
                const tools = box.tools.map((tool) => {
                    if (tool.item === 'OTHERS') {
                        return {
                            item: tool.item,
                            value: this.toolBoxOthers[box.key],
                        }
                    }
                    return {
                        item: tool.item,
                        value: tool.value,
                    }
                })
                return {
                    key: box.key,
                    name: box.name,
                    tools,
                }
            });

            const payload = {
                ...values,
                box: box,
                fullMobile: `${this.countryCode}${this.mobile}`,
            }
            return payload;
        },

        sendQuoteToolBox (e) {
            this.loading = true;
            e.preventDefault();
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    const payload = this.getFormValues(values);
                    const response = await quotesAPI.createToolBoxQuote(payload);
                    this.mappedResponse(response);
                }
            });
            this.loading = false;
        },

        mappedResponse (response) {
            if (response.statusCode === 201) {
                notification.success({
                    message: this.$t('RESPONSES.SUCCESS'),
                    description: this.$t('BRIEFCASE.QUOTES.CREATED_QUOTE'),
                });
                this.changeStatusModal(false);
                this.resetToolBox();
            } else if (response.statusCode === 409) {
                notification.warning({
                    message: this.$t('RESPONSES.WARNING'),
                    description: this.$t('BRIEFCASE.QUOTES.ALREADY_QUOTE_EXISTS'),
                });
            } else {
                notification.error({
                    message: this.$t('RESPONSES.ERROR'),
                    description: this.$t('BRIEFCASE.QUOTES.ERROR_CREATING_QUOTE'),
                });
            }
        },

        changeCountryCode(value) {
            this.countryCode = value;
        },

        changeMobile(value) {
            this.mobile = value;
        },

        onChange(box, tool) {
            const toolBox = this.toolBox[box].tools[tool];
            if (toolBox.item !== 'OTHERS') {
                const index = this.toolBox[box].tools.findIndex(item => item.item === toolBox.item);
                this.toolBox[box].tools[index].value = !this.toolBox[box].tools[index].value;
            }
        },

        resetToolBox() {
            this.form.resetFields();
            this.mobile = '';
            this.toolBox.forEach(box => {
                box.tools.forEach(tool => {
                    if (tool.item !== 'OTHERS') tool.value = false;
                    else tool.value = '';
                })
            })
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';

.headerContact {
    padding: 16px 24px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.container {
    &__tools {
        width: 100%;
        max-height: 350px;
        overflow: hidden;
        overflow-y: scroll;

        padding: 24px 16px;
        padding-right: 12px;
        background-color: #f8fbff;
        border-radius: 4px;
        border: solid 1px #d9d9d9;

        &:last-child { margin-bottom: 0; }

        &--box {
            padding: 8px 12px;
            margin-bottom: 12px;
            border: solid 1.4px $color-primary;
            border-left: transparent;
            border-right: transparent;

            &-tool {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 4px 8px;
                margin-bottom: 12px;

                &:hover {
                    transition: all 0.2s ease-in-out;
                }
            }
        }

    }
}
</style>