<template>

    <div class="activityCard">
        <a-list-item-meta>
            <a-avatar
                slot="avatar"
                :src="iconCard"
                shape="square"
                :size="48"
            />

            <div slot="title">
                <a-space direction="vertical" :size="6" class="full-width">
                    <span class="title text--level-3 cl--secondary-white italic right full-width">
                        {{ `${card.author} ${ $t(`ACTIVITY.ARTICLE.${card.subType}`) }` }}
                    </span>
                    <span class="title text--level-2 cl--secondary-white uppercase left full-width">
                        {{ card.title }}
                    </span>
                    <span class="title text--level-3 cl--secondary-grey left full-width">
                        {{ card.date }}
                    </span>
                    
                    <span class="title text--level-3 cl--primary-blue tp-medium right cardButton">
                        {{ $t(action) }}
                    </span>
                </a-space>
            </div>
        </a-list-item-meta>
    </div>
</template>

<script>
import { IMAGES } from '@/constants/images'

export default {
	name: "ActivityCard",
	data: () => {
		return {
            actions: {
                ARTICLE: "ACTIONS.SEE_ARTICLE",
                FEATURE: "ACTIONS.SEE_FEATURE",
                BOOK: "ACTIONS.SEE_BOOK"
            }
        }
    },
    props: {
        card: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        iconCard() {
            return IMAGES.icons[this.card.type.toLowerCase()]
        },

        action() {
            return this.actions[this.card.type]
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';
</style>