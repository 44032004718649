import { IMAGES } from "./images";

export const WEBINARS = {
    LEGACY: {
        code: 0,
        logo: IMAGES.webinars.legacy,
        name: "LEGACY",
        class: "legacy",
        path: "legacy"
    },
    THINK_TANK_BOARD: {
        code: 1,
        logo: IMAGES.webinars.think_tank_board,
        name: "THINK_TANK_BOARD",
        class: "think_tank",
        path: "think-tank-board"
    },
    BRANDS_RECTORAL: {
        code: 2,
        logo: IMAGES.webinars.brands_rectoral,
        name: "BRANDS_RECTORAL",
        class: "brands",
        path: "rectoral-brands"
    },
    ROAD_MAP_RECTORAL: {
        code: 3,
        logo: IMAGES.webinars.road_map_rectoral,
        name: "ROAD_MAP_RECTORAL",
        class: "road_map",
        path: "road-map-rectoral"
    }
};

export const WEBINARS_PATH = {
    "LEGACY": {
        code: 0,
        id: "632f422ef6487efcb0fb5a66",
        logo: IMAGES.webinars.legacy,
        name: "LEGACY",
        class: "legacy",
        path: "legacy"
    },
    "THINK-TANK-BOARD": {
        code: 1,
        id: "63326f9efa22683db9b005b4",
        logo: IMAGES.webinars.think_tank_board,
        name: "THINK_TANK_BOARD",
        class: "think_tank",
        path: "think-tank-board"
    },
    "RECTORAL-BRANDS": {
        code: 2,
        id: "63327019fa22683db9b005b5",
        logo: IMAGES.webinars.brands_rectoral,
        name: "BRANDS_RECTORAL",
        class: "brands",
        path: "rectoral-brands"
    },
    "ROAD-MAP-RECTORAL": {
        code: 3,
        id: "6332705efa22683db9b005b6",
        logo: IMAGES.webinars.road_map_rectoral,
        name: "ROAD_MAP_RECTORAL",
        class: "road_map",
        path: "road-map-rectoral"
    }
};

export const WEBINARS_NAME = [
    WEBINARS.LEGACY,
    WEBINARS.THINK_TANK_BOARD,
    WEBINARS.BRANDS_RECTORAL,
    WEBINARS.ROAD_MAP_RECTORAL,
]