<template>
    <div class="containerSection full-width">
        <a-row class="full-width full-height">
            <a-col :span="12" :md="12" :sm="24" :xs="24" class="containerLeft full-height">
                <div class="maskImage" />
                <img :src="contributionsImage" alt="image-contributions" class="imageSection" />
                <div class="textSection full-width">
                    <span class="title cl--secondary-white full-width">
                        {{ $t('HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.DESCRIPTION') }} 
                    </span>
                </div>
                <span class="title title--level-3 cl--secondary-white full-width textSection">
                    {{ $t('HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.SUB_DESCRIPTION') }} 
                </span>
            </a-col>
            <a-col :span="12" :md="12" :sm="24" :xs="24" class="containerRight">
                
                <!-- MODAL CONTRIBUTION -->
                <a-modal v-model="showModal" v-if="contributionSelected !== null" :footer="null" >
                    <span   slot="title" class="title title--level-3 cl--primary-blue">
                        {{ selected.entity }}
                    </span>
                    <a-space direction="vertical" :size="16" class="full-width">
                        
                        <span class="title text--level-2 full-width">{{ selected.project }}</span>

                        <a-space class="typeContribution" direction="horizontal" :size="12">
                            <a-space class="typeContribution" direction="horizontal" :size="8">
                                <img :src="selected.type.icon" style="width: 26px"/>
                                <span class="title text--level-3 cl--primary-blue">
                                    {{ $t(`${selected.type.name}`) }}
                                </span>
                            </a-space>
                             <a-avatar
                                :src="getFlag(selected.country)"
                                class="flag-avatar"
                                :size="32"
                                shape="square"
                                slot="description"
                            />
                        </a-space>
                        <a-space direction="horizontal" :size="16" class="full-width">
                            <a-avatar 
                                style="object-fit: contain"
                                :src="selected.logo" 
                                :size="84"
                                shape="square"
                            />
                            <a-space direction="vertical" :size="8">
                                <span class="text text--level-3">
                                    <strong class="title text--level-3">
                                        {{ $t('HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.MODAL.AUTHOR') }}
                                    </strong>
                                    {{ selected.author }}
                                </span>
                                <span class="text text--level-3">
                                    <strong class="title text--level-3">
                                        {{ $t('HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.MODAL.LOCATION') }}
                                    </strong>
                                    {{ selected.location }}
                                </span>
                                <span class="text text--level-3">
                                    <strong class="title text--level-3">
                                        {{ $t('HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.MODAL.YEAR') }}
                                    </strong>
                                    {{ formatDate(selected.date) }}
                                </span>
                            </a-space>
                        </a-space>

                        <a-tooltip placement="top" >
                            <span slot="title" v-if="!selected.link">
                                {{ $t('HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.MODAL.NO_LINK') }}
                            </span>

                            <a-button 
                                type="primary"
                                ghost
                                :disabled="!selected.link"
                                style="float: right"
                                @click="viewContributionURL(selected.link)"
                            >

                                {{ $t('HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.MODAL.BUTTON') }}
                            </a-button>
                        </a-tooltip>
                    </a-space>    
                </a-modal>
                
                <!-- LEGEND -->
                <div class="flex-column center-column-center containerLegend">
                    <a-tooltip 
                        placement="right"
                        :key="`legend-${legend.type}-${legend.status}`"
                        v-for="legend in legends"
                    >
                        <span slot="title">
                            {{ $t(`${legend.name}`) }}
                        </span>
                        <div class="containerIcon">
                            <img
                                :src="legend.icon" 
                                :alt="`icon-${legend.type}-${legend.status}`"
                            />
                        </div>
                    </a-tooltip>
                </div>

                <!-- CONTAINER MAP -->
                <gmap-map
					:options="map.options"
					:center="map.center"
					:zoom="map.zoom"
					style="width:100%;height:100%;"
				>
					<gmap-marker
						:key="contribution._id"
						:id="`marker-${contribution._id}`"
						v-for="contribution in contributions"
						:position="contribution.location.coordinates"
						:icon="getIcon(contribution.category, contribution.status)"
                        @click="viewContribution(contribution)"
						ref="marker"
					/>
				</gmap-map>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { IMAGES } from '@/constants/images';
import { mapActions, mapGetters } from "vuex";
import { getFormatDate } from "@/utils/util-dates.js"
import * as utils from '@/utils';

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb'
import 'moment/locale/pt-br'

export default {
    data: () => {
        return {
            params: {
                limit: 100,
                page: 1,
            },
            legends: [
                {
                    key: 0,
                    active: false,
                    type: "IES",
                    status: 'ACTIVE',
                    icon: require("@/assets/image/icons/ies-active.png"),
                    name: "HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.LEGEND.IES_ACTIVE"  
                },
                {
                    key: 1,
                    active: false,
                    type: "IES",
                    status: 'FINISHED',
                    icon: require("@/assets/image/icons/ies-finished.png"),
                    name: "HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.LEGEND.IES_FINISHED"  
                },
                {
                    key: 2,
                    active: false,
                    type: "GOVERNMENT",
                    status: 'ACTIVE',
                    icon: require("@/assets/image/icons/gob-active.png"),
                    name: "HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.LEGEND.GOBS_ACTIVE"  
                },
                {
                    key: 3,
                    active: false,
                    type: "GOVERNMENT",
                    status: 'FINISHED',
                    icon: require("@/assets/image/icons/gob-finished.png"),
                    name: "HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.LEGEND.GOBS_FINISHED"  
                },
                {
                    key: 4,
                    active: false,
                    type: "NETWORK",
                    status: 'ACTIVE',
                    icon: require("@/assets/image/icons/red-active.png"),
                    name: "HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.LEGEND.NETWORK_ACTIVE"  
                },
                {
                    key: 5,
                    active: false,
                    type: "NETWORK",
                    status: 'FINISHED',
                    icon: require("@/assets/image/icons/red-finished.png"),
                    name: "HOME.SECTIONS.TECHNICAL_CONTRIBUTIONS.LEGEND.NETWORK_FINISHED"  
                },
                
            ],
            contributionsImage: IMAGES.home.contributions,
            map: {
                zoom: 3,
				center: { lat: 35.508, lng: -73.587 },
				options: {
					zoomControl: true,
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: false,
					rotateControl: false,
					fullscreenControl: false,
					disableDefaultUi: false
				},
                userPosition: null,
            },
            showModal: false,
            contributionSelected: null
        };
    },

    async created() {
        await this.initialData();
        this.geolocate();
    },

    computed: {
        ...mapGetters("landing", {
			contributions: "getContributions",
		}),

        dateContribution() {
            return getFormatDate(this.contributionSelected.data.date);
        },

        getTypeContribution() {
            let type = this.contributionSelected.data.type.name;
            let status = this.contributionSelected.data.project.state.code;
            let icon = this.getIcon(type, status);
            let legend = this.legends.filter(legend => legend.type === type && legend.status === status);
            return { icon, legend };
        },

        selected() {
            let { category, status, project, location, image } = this.contributionSelected;

            let legend = this.legends.filter(legend => 
                legend.type === category && legend.status === status
            );

            let icon = legend[0].icon;
            let name = legend[0].name;

            let address = location.city;
            
            return {
                author: project.leadership,
                project: project.name,
                entity: project.name,
                link: project.externalLink,
                location: address,
                country: location.country,
                logo: image.original,
                type: { icon, name },
                date: project.executeDate,
            }
        }
    },

    methods: {
        ...mapActions("landing", {
			getContributionsStore: "getContributions",
		}),

        async initialData() {
            if(this.contributions.length === 0) {
                await this.getContributionsStore(this.params);
            }
        },

        geolocate: function() {
            navigator.geolocation.getCurrentPosition(position => {
                this.map.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                this.map.userPosition = position.cords;
            })
        },

        getPosition(location) {
            return {
                lat: parseInt(location.lat),
                lng: parseInt(location.lng)
            }
        },

        getIcon(type, status) {
            switch (type) {
                case "IES":
                    return status === 'FINISHED' 
                    ? { url: require("@/assets/image/icons/ies-finished.png") }
                    : { url: require("@/assets/image/icons/ies-active.png") }
                case "GOVERNMENT":
                    return status === 'FINISHED'
                    ? { url: require("@/assets/image/icons/gob-finished.png") }
                    : { url: require("@/assets/image/icons/gob-active.png") }
                case "NETWORK":
                    return status === 'FINISHED'
                    ? { url: require("@/assets/image/icons/red-finished.png") }
                    : { url: require("@/assets/image/icons/red-active.png") }
                default:
                    break;
            }
		},

        viewContribution(contribution) {
            this.contributionSelected = null;
            this.contributionSelected = contribution;
            this.showModal = true;
        },

        viewContributionURL(url) {
            window.open(url, '_blank');
        },

        getFlag (country) {
            return utils.getFlag(country);
        },

        formatDate (date) {
            moment.locale(this.$i18n.locale);
            return moment(date).format('LL');
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
.containerRight {
    padding: 0px 0px 0px 56px !important;
    height: calc(350px - 10px) !important;

    @include breakpoint(md) {
        height: 100% !important;
    }

    .containerLegend{
        background: $color-primary;
        box-shadow: 0px 5px 12px rgba(105, 105, 105, 0.25);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 56px;

    }
    .containerIcon{
        height: 30px;
        margin: 8px 0;
        width: 30px;

        img {
            height: 100%;
            object-fit: contain;
            width: 100%;
        }
    }
}

/** 
    --Style Modal
*/ 

.typeContribution {
    background: $color-grey-alt;
    padding: 8px 16px;
    border-radius: 6px;
}
</style>