<template>
    <div class="mainContainer sections full-height full-width bg--secondary-white" style="position:relative">
        <a-row class="full-width full-height center-row-center containerColumns" :gutter="[24,32]">
            <a-col :span="12" :md="12" :sm="24" :xs="24">

                <a-avatar
                    :size="70"
                    shape="square"
                    :src="portfolio.icon"
                    class="icon"
                />

                <a-space :size="16" direction="vertical">
                    <Headers
                        :showLine=headers.line
                        :title=headers.title
                        :description=headers.description
                        :align="'left'"
                    />
                    <a-button
                        type="primary"
                        size="large"
                        style="float: left"
                        @click="goToSection()"
                    >
                        <span class="tp-regular uppercase">
                            {{ $t('HOME.BRIEFCASE.LINK')}}
                        </span>
                    </a-button>
                </a-space>

            </a-col>

            <a-col :span="12" :md="12" :sm="24" :xs="24" class="flex-column center-column-center ">
                <a-space direction="horizontal" :size="16">
                    <div class="styleImage">
                        <img 
                            :src="portfolio.mentoring"
                            class="image"
                        />
                    </div>
                    <div class="styleImage">
                        <img 
                            :src="portfolio.lidership"
                            class="image"
                        />
                    </div>
                </a-space>
        
                <div class="styleImage">
                    <img 
                        :src="portfolio.toolbox"
                        class="image"
                    />
                </div>

            </a-col>
        </a-row>
    </div>
</template>

<script>
import { IMAGES } from "@/constants/images.js";

export default {
    data: function() {
        return {
            headers: {
                title: "HOME.BRIEFCASE.TITLE",
                description: "HOME.BRIEFCASE.DESCRIPTION",
                line: true
            }
        };
    },

    computed: {
        portfolio () {
            return {
                icon: IMAGES.home.portfolio.icon,
                lidership: IMAGES.services.eagles,
                mentoring: IMAGES.services.mentoring,
                toolbox: IMAGES.services.toolbox
            }
        }
    },
    methods: {
        goToSection () {
            this.$router.push("/briefcase");
        }
    },
};
</script>


<style lang="scss" scoped>
@import '@/styles/scss/config.scss';

.containerColumns {
    display: block;

    @include breakpoint(md) {
        display: flex;
    }
}

.icon {
    display: none;
    position: absolute;
    left: -70px;
    top: 70px;

    @include breakpoint(lg) {
        display: flex;
    }
}

.styleImage {
    border: solid 2px $color-primary;
    padding: 4px;
    border-radius: 100%;
    width: 120px;
    height: 120px;
    position: relative;

    @include breakpoint(md) {
        width: 120px;
        height: 120px;
        padding: 4px;
    }
    
    img {
        border-radius: 50%;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

</style>