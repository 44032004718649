<template>
    <a-form-item :label="$t('FORMS.INSTITUTIONAL_TYPE')">
        <a-select default-value="IES_PUBLIC" class="full-width" @change="changeInstituteType">
            <a-select-option 
                v-for="institute in institutes"
                :key="institute.key"
                :value="institute.keyName"
            >
                {{ institute.name }}
            </a-select-option>
        </a-select>
    </a-form-item>
</template>

<script>
import { institutesType } from '@/constants/institutesType.js';
export default {
    data: () => {
        return {
            institutes: institutesType,
            instituteType: null,
        };
    },
    methods: {
        changeInstituteType(newInstituteType) {
            this.$emit('changeInstituteType', newInstituteType);
        },
    }
}
</script>