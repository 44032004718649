<template>
    <div>
        <Navbar 
            @updateSection="updateSection" 
            @handleNavbarMobile="(value) => handleNavbarMobile(value)"
        />
        <NavbarMobile 
            :active="activeNavbarMobile"
            @updateSection="(value) => updateSection(value)"
            @close="() => activeNavbarMobile = false"
        />
        <div class="mainContainer sections full-width full-height flex-column end-column-center">
            <a-space direction="vertical" :size="40" class="full-width">
                <div class="flex-column start-column-start full-width header" style="margin-top: 32px">
                    <a-space direction="vertical" :size="8">
                        <span class="cl--primary-blue title">
                            {{ $t('KTDRA_CHANNEL.HEADER') }}
                        </span>
                        <span class="cl--primary-blue text text--level-2">
                            {{ $t('KTDRA_CHANNEL.SUBHEADER') }}
                        </span>
                    </a-space>
                </div>
            </a-space>
            <div class="mainContainer sections full-width full-height bg--secondary-white noPaddingLat">
                <div>
                    <Articles :showSearch="true" :requireLimited="false" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Articles from "@/components/ktdra/Articles";
import { scroller } from "vue-scrollto/src/scrollTo";

    export default {
        data: () => {
            return {
                activeNavbarMobile: false,
            };
        },
        components: {
            Articles
        },
        methods: {
            updateSection(keySection) {
                let scroll = scroller();
                scroll(`#${keySection}`);
                this.activeSection = keySection;
            },
            handleNavbarMobile(value) {
                this.activeNavbarMobile = value;
            }
        },
    }
</script>