import * as getters from './getters';
import * as actions from './actions';
import mutations from './mutations';

const state = {
    personal: {
        names: "",
        picture: {
            original: "",
            thumbnail: ""
        },
        surnames: ""
    },
    articles: [],
    dateRegister: "",
    isPartner: false,
    slogan: {
        show: false,
        content: ""
    },
    services: [],
    account: {
        email: "",
        password: "",
    },
    academic: {
        caracterization: "",
        position: "",
        institution: "",
        synthesis: "",
        title: "",
    },
    contact: {
        country: "",
        email: "",
        countryCode: "",
        phone: "",
        social: {
            fb: "",
            li: "",
            tw: ""
        }
    },
    membership: {
        code: "",
        keyName: "",
        target: ""
    }
}

export default { 
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}