import Vue from 'vue'

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faEnvelope,
    faLock,
    faLink,
    faUserClock,
    faMapMarked,
    faUser,
    faSignOutAlt,
    faMapMarkedAlt,
    faMapMarker,
    faLocationArrow,
    faInfoCircle,
    faParking,
    faTimes,
    faSearch,
    faStar,
    faHeart,
    faCamera,
    faUserCircle,
    faSignInAlt,
    faUserTie,
    faUserPlus,
    faArrowAltCircleRight,
    faBars,
    faArrowCircleLeft,
    faTimesCircle,
    faArrowCircleRight,
    faArrowLeft,
    faArrowRight,
    faHome,
    faUniversity,
    faBriefcase,
    faSignature,
    faBook,
    faUsers,
    faEnvelopeOpenText,
    faCalendarAlt,
    faProjectDiagram,
    faExpand,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faComment,
    faChevronUp,
    faPaperPlane,
    faCheckCircle,
    faSpinner,
    faCameraRetro,
    faSave,
    faExclamationTriangle,
    faArrowDown,
    faPaperclip,
    faCaretDown,
    faUserTimes,
    faUserEdit,
    faCalendarPlus,
    faCalendarCheck,
    faCalendar,
    faCalendarTimes,
    faNewspaper,
    faPlus,
    faAnkh,
    faCircle,
    faPlusCircle,
    faMinusCircle,
    faQuestionCircle,
    faFire,
    faTag,
    faRss,
    faBell,
    faChevronCircleRight,
    faGlobe,
    faClone,
    faShareAlt,
    faUndo,
    faReply,
    faVolumeUp,
    faFileAudio,
    faFileImage,
    faFileVideo,
    faVideo,
    faImage,
    faCogs,
    faSitemap,
    faPencilAlt,
    faIdBadge,
    faChartLine,
    faGraduationCap,
    faHandshake,
    faVrCardboard,
    faCubes,
    faListAlt,
    faTrash,
    faEye,
    faFileAlt,
    faSignal,
    faTags,
    faFile,
    faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import {
    faLinkedin,
    faTwitter,
    faFacebook,
    faYoutube,
    faInstagram,
    faYoutubeSquare
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
    faCaretDown,
    faEnvelope,
    faLink,
    faUserClock,
    faUserFriends,
    faLock,
    faMapMarked,
    faUser,
    faFacebook,
    faYoutube,
    faInstagram,
    faUserTimes,
    faUserEdit,
    faCalendarPlus,
    faCalendarTimes,
    faCalendarCheck,
    faCalendar,
    faEnvelope,
    faGlobe,
    faNewspaper,
    faComment,
    faPaperclip,
    faSignOutAlt,
    faClone,
    faShareAlt,
    faMapMarkedAlt,
    faMapMarker,
    faExclamationTriangle,
    faMinusCircle,
    faQuestionCircle,
    faFire,
    faBell,
    faLocationArrow,
    faInfoCircle,
    faParking,
    faReply,
    faVolumeUp,
    faFileAudio,
    faFileImage,
    faFileVideo,
    faChevronRight,
    faChevronCircleRight,
    faTimes,
    faTimesCircle,
    faSearch,
    faStar,
    faPlus,
    faUndo,
    faSignOutAlt,
    faVideo,
    faHeart,
    farHeart,
    faCamera,
    faUserCircle,
    faSignInAlt,
    faUserTie,
    faUserPlus,
    faArrowAltCircleRight,
    faBars,
    faArrowCircleLeft,
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowLeft,
    faArrowRight,
    faArrowDown,
    faBars,
    faAnkh,
    faHome,
    faImage,
    faUniversity,
    faBriefcase,
    faSignature,
    faBook,
    faTag,
    faRss,
    faUsers,
    faCircle,
    faEnvelopeOpenText,
    faInfoCircle,
    faCalendarAlt,
    faProjectDiagram,
    faExpand,
    faPaperPlane,
    faPlusCircle,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faTwitter,
    faLinkedin,
    faPaperPlane,
    faTimesCircle,
    faTimesCircle,
    faCheckCircle,
    faSpinner,
    faSearch,
    faCameraRetro,
    faSave,
    faExclamationTriangle,
    faCogs,
    faSitemap,
    faPencilAlt,
    faIdBadge,
    faChartLine,
    faGraduationCap,
    faHandshake,
    faVrCardboard,
    faCubes,
    faListAlt,
    faTrash,
    faShareAlt,
    faEye,
    faFileAlt,
    faSignal,
    faTags,
    faTag,
    faFile,
    faYoutubeSquare,
);

Vue.component("font-awesome-icon", FontAwesomeIcon);