export const MEMBERSHIPS = {
    GOLD: {
        code: 0,
        color: "#AD9434",
        keyName: "gold",
        name: "Gold",
        target: "MEMBERSHIP_TARGET.GOLD"
    },
    PLATINUM: {
        code: 1,
        color: "#3E3E3E",
        keyName: "platinum",
        name: "Platinum",
        target: "MEMBERSHIP_TARGET.PLATINUM"
    },
    DIAMOND: {
        code: 2,
        color: "#4F88EC",
        keyName: "diamond",
        name: "Diamond",
        target: "MEMBERSHIP_TARGET.DIAMOND"
    }
};

export const NAMES = {
    GOLD: "GOLD",
    DIAMOND: "DIAMOND",
    PLATINUM: "PLATINUM"
};