<template>
    <a-space direction="vertical" :size="16" class="full-width full-height">
        <div class="liveHeader">
            <div class="liveOptions flex-row start-row-center full-width">
                <a-space direction="horizontal" :size="8">
                    <div class="point" />
                    <span class="title title--level-2 cl--primary-blue left full-width">
                        {{ $t('WEBINARS.LIVE') }}
                    </span>
                </a-space>
                <a-icon slot="icon" type="fullscreen" :style="{ fontSize: '24px', color: '#1b4484' }" />
            </div>
        </div>
        <div class="full-width flex-column center-column-center containerVideoLive">
            <a-space class="full-width" :size="8" direction="vertical">
                <a-icon
                    slot="icon"
                    type="video-camera"
                    :style="{ 
                        fontSize: '48px',
                        color: '#1b4484',
                        margin: '0 auto',
                        display: 'block',
                    }" 
                />
                <span class="title text--level-2 cl--primary-blue center full-width">
                    {{ $t('WEBINARS.NO_LIVE') }}
                </span>
            </a-space>
        </div>
    </a-space>
</template>

<style lang="scss">
@import "@/styles/scss/config.scss";
@import "@/styles/scss/views/webinars.scss";

.containerVideoLive { 
    height: 240px; 
    border: solid .5px $color-primary;
    border-radius: 6px;
}
</style>

<script>
    export default {
        data: () => {
            return {
                
            }
        },
    }
</script>