<template>
    <div class="mainContainer sections full-width full-height bg--secondary-grey-alt principles">
        <div
            :style="getBackground()"
            class="background full-width full-height"
        />
        <div class="__image"/>
        <Headers
            :showLine="headers.line"
            :title="headers.title"
            align="left"
            color="#fff"
        />
        
        <div class="subSections">
            <a-space direction="vertical" class="full-width" :size="32">
                <span class="title title--level-2 cl--secondary-white">
                    {{ $t('INSTITUTIONAL.PRINCIPLES.WE_PRAY_THAT') }}:
                </span>

                <div>
                    <div 
                        class="full-width flex-row start-row-center principle" 
                        v-for="(principle, index) in 10" 
                        :key="index"
                    >
                        <font-awesome-icon
                            :icon="['fa', 'circle']"
                            class="icon"
                        />
                        <span class="text text--level-2 cl--secondary-white">
                            {{ $t(`INSTITUTIONAL.PRINCIPLES.${index + 1}`) }}
                        </span>
                    </div>
                </div>
            </a-space>
        </div>
    </div>
</template>


<script>
import { IMAGES } from '@/constants/images';
export default {
    data: () => {
        return {
            headers: {
                title: "INSTITUTIONAL.PRINCIPLES.HEADER",
                line: false,
            },
        }
    },
    methods: {
        getBackground () {
            return {
                "background-image": "url(" + this.background + ")",
                "background-repeat": "no-repeat",
                "background-size": "cover",
                "background-position": "center center",
            };
        },
    },

    computed: {
        background () {
            return IMAGES.backgrounds.principles;
        }
    },
}

</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';

.principles {
    position: relative;

    .__image {
        background-color: $color_primary;
        mix-blend-mode: multiply;
        opacity: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
    }

    .background {
        left: 0;
        opacity: 0.6;
        position: absolute;
        top: 0;
    }

    .subSections { z-index: 3; }

    .icon {
        font-size: 8px;
        margin-right: 8px;
        color: $color-white;
    }

    .pray {
        padding: 8px 16px;
        border-radius: 8px;
        width: fit-content;
    }

    .principle {
        margin-bottom: 16px;
    }
}

</style>