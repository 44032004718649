export const personalData = state => {
    return state.personal;
}

export const contactData = state => {
    return state.contact;
}

export const accountData = state => {
    return state.account;
}