<template>
    <a-space direction="vertical" :size="16" class="full-width">
        <div class="liveHeader">
            <a-space direction="horizontal" :size="8">
                <a-icon slot="icon" type="file-protect" :style="{ fontSize: '24px', color: '#1b4484' }" />
                <span class="title title--level-2 cl--primary-blue left full-width">
                    {{ $t('CERTIFICATES.HEADER') }}
                </span>
            </a-space>
        </div>

        <ComingSoon />
        
    </a-space>
</template>

<style lang="scss">
@import "@/styles/scss/config.scss";
@import "@/styles/scss/views/webinars.scss";
</style>

<script>
    export default {
        data: () => {
            return {
                
            }
        },
    }
</script>