<template>
    <div>
        <a-form-item 
            class="containerPhone flex-row center-row-center"
            :label="$t('FORMS.PHONE')"
        >
            <a-select 
                default-value="+593" 
                class="full-height code"
                showSearch
                optionFilterProp="children"
                :filter-option="filterCountryCode"
                @change="changeCountryCode"
            >
                <a-select-option
                    v-for="(codeNumber, index) in codesNumber"
                    :key="`code_${index}_${codeNumber}`"
                    :value="codeNumber.code"
                    class="flex-row center-row-center full-height"
                >
                    <a-avatar 
                        :src="codeNumber.flag" class="flagCode"
                        shape="square"
                    />
                    <span class="text text--level-3">{{ codeNumber.code }}</span>
                </a-select-option>
                
            </a-select>

            <a-input 
                class="number"
                placeholder="Ej. 987654321"
                :maxLength="10"
                v-model="mobile"
                @change="changeMobile(mobile)"
            />
        </a-form-item>
        
    </div>
</template>
<script>
import countries from "@/constants/countries.json";
import * as utils from "@/utils/";
export default {
    data: () => {
        return {
            codesNumber: [],
            mobile: null,
        };
    },

    beforeMount() {
        this.loadCodesNumber();
        this.changeCountryCode("+593");
    },

    methods: {
        
        loadCodesNumber() {
            let aux = [];
            countries.map((country) => {
                if (country.callingCodes[0] !== "") {
                    aux.push({
                        flag: country.flag,
                        code: `+${country.callingCodes[0]}`,
                        country: country.name
                    });
                }
            });
            this.codesNumber = utils.removeDuplicates(aux, "code");
        },

        filterCountryCode(input, option) {
            return ( 
                option.componentOptions.propsData.value.indexOf(input) >=0
            );
        },

        changeCountryCode(newCountryCode) {
          this.$emit('changeCountryCode', newCountryCode);  
        },

        changeMobile(newMobile) {
            this.$emit('changeMobile', newMobile);
        }

    },
}
</script>