<template>
    <div class="headers full-width" style="margin-bottom: 16px">
        <a-space direction="horizontal" :size="8" class="full-width">
            <font-awesome-icon :icon="['fas', icon]" class="cl--secondary-white" />
            <span class="title text--level-2 cl--secondary-white">
                {{ $t(section) }}
            </span>
        </a-space>
    </div>
</template>

<script>
export default {
    name: "HeaderSection",
    props: {
        section: {
            type: String,
            default: ""
        },
        icon: {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
</style>