<template>
    <div class="mainContainer sections full-width full-height containerInsight">
        <a-avatar :size="150" :src="decoration.circle_a" class="circle a"/>
        <a-avatar :size="110" :src="decoration.circle_b" class="circle b"/>
        <a-avatar :size="32" :src="decoration.circle_c" class="circle c"/>

        <a-row 
            align="middle" 
            justify="space-between" 
            class="containerSection"
        >
            <a-col :xs="24" :md="12" class="containerLeft">
                <div class="maskImage"/>
                <img :src="insight" class="imageSection" />
                <div class="textSection full-width">

                    <a-space direction="vertical" :size="24">

                        <span class="title title--level-2 cl--secondary-white full-width uppercase">
                            {{ $t('INSTITUTIONAL.INSIGHT.HEADER') }} 
                        </span>
                        
                        <a-space direction="vertical" :size="16">
                            <span class="text text--level-2 cl--secondary-white full-width justify">
                                {{ $t('INSTITUTIONAL.INSIGHT.CITE_TEXT') }} 
                            </span>

                            <span class="title text--level-3 cl--secondary-white full-width">
                                {{ $t('INSTITUTIONAL.INSIGHT.CITE_AUTHOR') }} 
                            </span>
                        </a-space>
                    </a-space>

                </div>

            </a-col>
            <a-col :xs="24" :md="12" class="containerRight bg--secondary-grey-alt-1">
                <a-space direction="vertical" :size="56">
                    
                    <a-space direction="vertical" :size="16">
                        <div 
                            v-for="(section, index) in sections.sectionA"
                            :key="`A_${index}`"
                        >
                            <span class="text text--level-3 cl--primary-blue justify">
                                {{ $t(section) }}
                            </span>
                        </div>
                        <div
                            v-for="(section, index) in sections.sectionB"
                            :key="`B_${index}`"
                        >
                            <span class="text text--level-3 cl--primary-blue justify">
                                {{ $t(section) }}
                            </span>
                        </div>
                    </a-space>
                </a-space>
                
            </a-col>
        </a-row>
    </div>
</template>


<style lang="scss">
@import '@/styles/scss/config.scss';

.containerInsight {
    
    .containerSection {
        height: auto;

        @include breakpoint(md) {
            height: 400px;
        }
    }

    .containerLeft, .containerRight {
        padding: 56px 24px !important;
        overflow: scroll;
        height: 300px;
        position: relative !important;

        @include breakpoint(md) {
            height: 100%;
        }
    }
}

.circle {
    position: absolute;
    height: auto;

    &.a {
        top: calc(50% - 24px);
        left: 24px;
    }
    &.b {
        right: 16px;
        height: auto;
        top: 100px;
    }
    &.c {
        right: 120px;
        width: 3.5vw;
        top: 75px;
    }
}
</style>

<script>
import { IMAGES } from "@/constants/images"

export default {
  data: function() {
    return {
        insight: IMAGES.institutional.insight,

        sections: {
            sectionA: [
                "INSTITUTIONAL.INSIGHT.PARAGRAPHS.SECTION_A.1",
                "INSTITUTIONAL.INSIGHT.PARAGRAPHS.SECTION_A.2",
            ],
            sectionB: [
                "INSTITUTIONAL.INSIGHT.PARAGRAPHS.SECTION_B.1",
                "INSTITUTIONAL.INSIGHT.PARAGRAPHS.SECTION_B.2"
            ]
        },

        decoration: {
            circle_a: IMAGES.institutional.decoration.circle_a,
            circle_b: IMAGES.institutional.decoration.circle_b,
            circle_c: IMAGES.institutional.decoration.circle_c,
        }
    };
  },
  methods: {
  }
};
</script>
