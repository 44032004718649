<template>
    <div 
        class="bg--secondary-white navbarMobile"
        :style="styleNavbar()"
    >
        <div
            class="flex-column center-column-center icon-close shadow"
            @click="closeNavbar()"
        >
            <a-icon type="caret-left" :style="{ fontSize: '18px' }" />
        </div>

        <a-space direction="vertical" class="full-width" :size="40">
            <a-menu
                class="full-width"
                style="overflow-y: scroll; overflow-x: clip"
                mode="inline"
            >
                <a-sub-menu
                    key="home-submenu"
                    class="item-menu"
                >
                    <a-space direction="horizontal" :size="8" slot="title">
                        <a-icon :type="menu[0].icon" :style="{ fontSize: '20px', color: '#1b4484' }" />
                        <span class="text text--level-2 cl--primary-blue">
                            {{ $t(menu[0].name) }}
                        </span>
                    </a-space>
                    <a-menu-item 
                        v-for="(itemSubMenu, index) in menu[0].submenu.items"
                        :key="`home_${index}`"
                        class="item-menu"
                        style="height: auto !important"
                        @click="goToPage(itemSubMenu)"
                    >
                        <a-space direction="horizontal" :size="8">
                            <a-icon :type="itemSubMenu.icon" :style="{ fontSize: '20px', color: '#1b4484' }" />
                            <span class="text text--level-2 cl--primary-blue">
                                {{ $t(itemSubMenu.name) }}
                            </span>
                        </a-space>
                    </a-menu-item>
                </a-sub-menu>

                <a-sub-menu
                    key="institutional-submenu"
                    class="item-menu"
                >
                    <a-space direction="horizontal" :size="8" slot="title">
                        <a-icon :type="menu[1].icon" :style="{ fontSize: '20px', color: '#1b4484' }" />
                        <span class="text text--level-2 cl--primary-blue">
                            {{ $t(menu[1].name) }}
                        </span>
                    </a-space>
                    <a-menu-item 
                        v-for="(itemSubMenu, index) in menu[1].submenu.items"
                        :key="`institutional_${index}`"
                        class="item-menu"
                        style="height: auto !important"
                        @click="goToPage(itemSubMenu)"
                    >
                        <a-space direction="horizontal" :size="8">
                            <a-icon :type="itemSubMenu.icon" :style="{ fontSize: '20px', color: '#1b4484' }" />
                            <span class="text text--level-2 cl--primary-blue">
                                {{ $t(itemSubMenu.name) }}
                            </span>
                        </a-space>
                    </a-menu-item>
                </a-sub-menu>

                <a-sub-menu
                    key="briefcase-submenu"
                    class="item-menu"
                >
                    <a-space direction="horizontal" :size="8" slot="title">
                        <a-icon :type="menu[2].icon" :style="{ fontSize: '20px', color: '#1b4484' }" />
                        <span class="text text--level-2 cl--primary-blue">
                            {{ $t(menu[2].name) }}
                        </span>
                    </a-space>
                    <a-menu-item 
                        v-for="(itemSubMenu, index) in menu[2].submenu.items"
                        :key="`briefcase_${index}`"
                        class="item-menu"
                        style="height: auto !important"
                        @click="goToPage(itemSubMenu)"
                    >
                        <a-space direction="horizontal" :size="8">
                            <a-icon :type="itemSubMenu.icon" :style="{ fontSize: '20px', color: '#1b4484' }" />
                            <span class="text text--level-2 cl--primary-blue">
                                {{ $t(itemSubMenu.name) }}
                            </span>
                        </a-space>
                    </a-menu-item>
                </a-sub-menu>

                

                <a-sub-menu
                    key="webinars-submenu"
                    class="item-menu"
                >
                    <a-space direction="horizontal" :size="8" slot="title">
                        <a-icon :type="menu[3].icon" :style="{ fontSize: '20px', color: '#1b4484' }" />
                        <span class="text text--level-2 cl--primary-blue">
                            {{ $t(menu[3].name) }}
                        </span>
                    </a-space>
                    <a-menu-item 
                        v-for="(itemSubMenu, index) in menu[3].submenu.items"
                        :key="`library_${index}`"
                        class="item-menu"
                        style="height: auto !important"
                        @click="goToPage(itemSubMenu)"
                    >
                        <a-space direction="horizontal" :size="8">
                            <a-icon :type="itemSubMenu.icon" :style="{ fontSize: '20px', color: '#1b4484' }" />
                            <span class="text text--level-2 cl--primary-blue">
                                {{ $t(itemSubMenu.name) }}
                            </span>
                        </a-space>
                    </a-menu-item>
                </a-sub-menu>

                <a-menu-item 
                    key="ktdra"
                    class="item-menu"
                    @click="goToPage(menu[4])"
                >
                    <a-space direction="horizontal" :size="8">
                        <a-icon :type="menu[4].icon" :style="{ fontSize: '20px', color: '#1b4484' }" />
                        <span class="text text--level-2 cl--primary-blue">
                            {{ $t(menu[4].name) }}
                        </span>
                    </a-space>
                </a-menu-item>

                <a-sub-menu
                    key="library-submenu"
                    class="item-menu"
                >
                    <a-space direction="horizontal" :size="8" slot="title">
                        <a-icon :type="menu[5].icon" :style="{ fontSize: '20px', color: '#1b4484' }" />
                        <span class="text text--level-2 cl--primary-blue">
                            {{ $t(menu[5].name) }}
                        </span>
                    </a-space>
                    <a-menu-item 
                        v-for="(itemSubMenu, index) in menu[5].submenu.items"
                        :key="`library_${index}`"
                        class="item-menu"
                        style="height: auto !important"
                        @click="goToPage(itemSubMenu)"
                    >
                        <a-space direction="horizontal" :size="8">
                            <a-icon :type="itemSubMenu.icon" :style="{ fontSize: '20px', color: '#1b4484' }" />
                            <span class="text text--level-2 cl--primary-blue">
                                {{ $t(itemSubMenu.name) }}
                            </span>
                        </a-space>
                    </a-menu-item>
                </a-sub-menu>
                <a-menu-item 
                    key="signatories"
                    class="item-menu"
                    @click="goToPage(menu[6])"
                >
                    <a-space direction="horizontal" :size="8">
                        <a-icon :type="menu[6].icon" :style="{ fontSize: '20px', color: '#1b4484' }" />
                        <span class="text text--level-2 cl--primary-blue">
                            {{ $t(menu[6].name) }}
                        </span>
                    </a-space>
                </a-menu-item>
            </a-menu>
            <LocaleSwitcher/>
        </a-space>

        
    </div>
</template>

<script>
export default {
    data: () => {
        return {
            currentRoute: {},
            menu: [
                {
                    name: "NAVBAR.HOME",
                    icon: "home",
                    path: "/",
                    submenu: {
                        index: 'sub-1',
                        items: [
                            {
                                name: "NAVBAR.SUBMENU.HOME.ABOUT_US",
                                path: "/",
                                keyPath: "about-us"
                            },
                            {
                                name: "NAVBAR.SUBMENU.HOME.EXTERNAL_SCHEDULE",
                                path: "/",
                                keyPath: "external-schedule"
                            },
                            {
                                name: "NAVBAR.SUBMENU.HOME.TECHNICAL_CONTRIBUTIONS",
                                path: "/",
                                keyPath: "technical-contributions"
                            },
                            {
                                name: "NAVBAR.SUBMENU.HOME.INSTITUTIONAL_SUPPORTERS",
                                path: "/",
                                keyPath: "institutional-supporters"
                            },
                            {
                                name: "NAVBAR.SUBMENU.HOME.RECTORAL_BOARD_EVENTS",
                                path: "/",
                                keyPath: "rectoral-board-events"
                            },
                            /* {
                                name: "NAVBAR.SUBMENU.HOME.EXTERNAL_EVENTS_PARTICIPATION",
                                path: "/",
                                keyPath: "external-events-participation"
                            }, */
                            {
                                name: "NAVBAR.SUBMENU.HOME.SIGNATORY_MEMBERS",
                                path: "/",
                                keyPath: "signatory-members"
                            },
                            {
                                name: "NAVBAR.SUBMENU.HOME.WEBINARS_RECTORAL",
                                path: "/",
                                keyPath: "webinars-rectoral"
                            },
                            {
                                name: "NAVBAR.SUBMENU.HOME.BRIEFCASE",
                                path: "/",
                                keyPath: "briefcase"
                            },
                            {
                                name: "NAVBAR.SUBMENU.HOME.LIBRARY",
                                path: "/",
                                keyPath: "library"
                            },
                            {
                                name: "NAVBAR.SUBMENU.HOME.CHANNEL_KTDRA",
                                path: "/",
                                keyPath: "ktdra"
                            },
                            {
                                name: "NAVBAR.SUBMENU.HOME.BOARD_NEWS",
                                path: "/",
                                keyPath: "board-news"
                            },
                        ],
                    }
                },
                {
                    name: "NAVBAR.INSTITUTIONAL",
                    icon: "bank",
                    path: "/institutional",
                    submenu: {
                        index: 'sub-2',
                        items: [
                            {
                                name: "NAVBAR.SUBMENU.INSTITUTIONAL.GOVERNANCE",
                                path: "/institutional/",
                                keyPath: "governance"
                            },
                            {
                                name: "NAVBAR.SUBMENU.INSTITUTIONAL.IDENTITY",
                                path: "/institutional/",
                                keyPath: "identity"
                            },
                            {
                                name: "NAVBAR.SUBMENU.INSTITUTIONAL.PRINCIPLES",
                                path: "/institutional/",
                                keyPath: "rectoral-principles"
                            },
                            {
                                name: "NAVBAR.SUBMENU.INSTITUTIONAL.ACTION_SECTORS",
                                path: "/institutional/",
                                keyPath: "action-sectors"
                            },
                            {
                                name: "NAVBAR.SUBMENU.INSTITUTIONAL.TARGET_AUDIENCES",
                                path: "/institutional/",
                                keyPath: "target-audience"
                            },
                            {
                                name: "NAVBAR.SUBMENU.INSTITUTIONAL.INSTITUTIONAL_POLITICS",
                                path: "/institutional/",
                                keyPath: "institutional-politics"
                            },
                            {
                                name: "NAVBAR.SUBMENU.INSTITUTIONAL.INSIGHT",
                                path: "/institutional/",
                                keyPath: "insight"
                            },
                        ],
                    }
                },
                {
                    name: "NAVBAR.BRIEFCASE",
                    icon: "appstore",
                    path: "/briefcase",
                    submenu: {
                        index: 'sub-3',
                        items: [
                            {
                                name: "NAVBAR.SUBMENU.BRIEFCASE.MENTORING_180",
                                path: "/briefcase/",
                                keyPath: "mentoring-180"
                            },
                            {
                                name: "NAVBAR.SUBMENU.BRIEFCASE.LEADERSHIP",
                                path: "/briefcase/",
                                keyPath: "leadership"
                            },
                            {
                                name: "NAVBAR.SUBMENU.BRIEFCASE.TOOL_BOX_RECTORAL",
                                path: "/briefcase/",
                                keyPath: "tool-box-rectoral"
                            },
                            {
                                name: "NAVBAR.SUBMENU.BRIEFCASE.SCHEMA",
                                path: "/briefcase/",
                                keyPath: "schema"
                            },
                        ],
                    }
                },
                {
                    name: "NAVBAR.WEBINARS",
                    icon: "desktop",
                    path: "/webinars",
                    submenu: {
                        index: 'sub-4',
                        items: [
                            {
                                name: "NAVBAR.SUBMENU.WEBINARS.LEGACY",
                                path: "/webinars/legacy",
                            },
                            {
                                name: "NAVBAR.SUBMENU.WEBINARS.THINK_TANK_BOARD",
                                path: "/webinars/think-tank-board",
                            },
                            {
                                name: "NAVBAR.SUBMENU.WEBINARS.BRANDS_RECTORAL",
                                path: "/webinars/rectoral-brands",
                            },
                            {
                                name: "NAVBAR.SUBMENU.WEBINARS.ROAD_MAP_RECTORAL",
                                path: "/webinars/road-map-rectoral",
                            },
                        ],
                    },
                },
                {
                    name: "NAVBAR.KTDRA_CHANNEL",
                    icon: "solution",
                    path: "/ktdra-channel",
                    submenu: null,
                },
                {
                    name: "NAVBAR.LIBRARY",
                    icon: "hdd",
                    path: "/library",
                    submenu: {
                        index: 'sub-7',
                        items: [
                            {
                                name: "NAVBAR.SUBMENU.LIBRARY.REPORTS",
                                path: "/library/reports",
                            },
                            {
                                name: "NAVBAR.SUBMENU.LIBRARY.BOOKS",
                                path: "/library/books",
                            },
                            {
                                name: "NAVBAR.SUBMENU.LIBRARY.BENCHMARKIES",
                                path: "/library/conferences",
                            },
                            {
                                name: "NAVBAR.SUBMENU.LIBRARY.TRENDS",
                                path: "/library/trends",
                            },
                        ],
                    }
                },
                {
                    name: "NAVBAR.REGISTER",
                    icon: "gold",
                    path: "/offer",
                    submenu: null,
                },
            ]
        }
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        styleNavbar () {
            return {
                display: this.active ? 'flex' : 'none',
            }
        },

        goToRegister() {
            const url = "https://dashboard.rectoralboard.com/sign-up";
            window.open(url, "_blank");
        },

        closeNavbar () {
            this.$emit('close')
        },

        goToPage(route) {
            if (route.path === "/offer") {
                this.goToRegister();
                return;
            }
            this.currentRoute = route;
            const path = route.path;
            const keyPath = route.keyPath;
            this.$router.push({ path, query: { section: keyPath } });
            this.$emit("updateSection", keyPath);
        },
        updateSection() {
            const path = this.currentRoute.path;
            const keyPath = this.currentRoute.keyPath;
            this.$router.push({ path, query: { section: keyPath } });
            this.$emit("updateSection", keyPath);
        },
    },
}
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';
@import '@/styles/scss/components/navbar.scss';
</style>
