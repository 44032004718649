import axios from "axios";
import { DASHBOARD_API_URL } from "../../api-routes";
import queryString from 'querystring';

export async function getContributions(params) {
    try {
        let url = `${DASHBOARD_API_URL}/contributions?${queryString.stringify(params)}`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            statusCode: error.response.status,
            message: error.response.data.message
        }
    }
}