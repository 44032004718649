<template>
     <div>
        <Navbar 
            @updateSection="updateSection" 
            @handleNavbarMobile="(value) => handleNavbarMobile(value)"
        />
        <NavbarMobile 
            :active="activeNavbarMobile"
            @updateSection="(value) => updateSection(value)"
            @close="() => activeNavbarMobile = false"
        />
        <div class="mainContainer sections full-width full-height library">
            <div class="image" :style="getBackground()"/>
            <div class="cover">
                <a-space direction="vertical" :size="8" class="full-width">
                    <span class="title full-width center title--level-2 cl--secondary-white phrase">
                        {{ $t('LIBRARY.SLOGAN') }}
                    </span>

                    <span class="title italic full-width center text--level-3 tp-light cl--secondary-white phrase">
                        {{ $t('LIBRARY.AUTHOR') }}
                    </span>
                </a-space>
            </div>

                <div class="containerPresentation flex-column end-column-start">
                    <span
                        class="cl--primary-blue title full-width center"
                        style="margin-bottom: 32px;"
                    >
                        {{ $t('LIBRARY.HEADER') }}
                    </span>
                    <a-space direction="vertical" :size="48" class="full-width">
                        <a-row type="flex" justify="space-around" align="middle" :gutter="[24, 32]" class="full-width">
                            <a-col 
                                class="flex-column center-column-center"
                                @click="selectCategory(action.path)"
                                v-for="action in actions"
                                :key="action.index"
                                :span="4" 
                                :lg="4" 
                                :xs="12"
                            >
                                <a-avatar
                                    :src="library[action.icon]"
                                    class="iconLibrary shadow"
                                    shape="square"
                                    :size="120"
                                />
                                <span class="cl--primary-blue title text--level-2">
                                    {{ $t(action.name) }}
                                </span>
                            </a-col>
                        </a-row>
                    </a-space>
                </div>

        </div>
    </div>
</template>

<style lang="scss">
@import "@/styles/scss/config.scss";
@import "@/styles/scss/views/library.scss";
</style>

<script>
import { IMAGES } from '@/constants/images'
import { scroller } from "vue-scrollto/src/scrollTo";

export default {
    data: () => {
        return {
            backgroundLibrary: IMAGES.backgrounds.library,
            activeNavbarMobile: false,
            actions: [
                {
                    index: 0,
                    icon: "books",
                    name: "LIBRARY.BOOKS",
                    path: "books",
                },
                {
                    index: 1,
                    icon: "reports",
                    name: "LIBRARY.REPORTS",
                    path: "reports",
                },
                {
                    index: 2,
                    icon: "conferences",
                    name: "LIBRARY.CONFERENCES",
                    path: "conferences",
                },
                {
                    index: 3,
                    icon: "trends",
                    name: "LIBRARY.TRENDS",
                    path: "trends",
                },
            ]
        }
    },
    computed: {
        library () {
            return IMAGES.home.library
        }
    },
    methods: {
        getBackground () {
            return {
                "background-image": "url(" + this.backgroundLibrary + ")",
            };
        },
        selectCategory(category) {
            this.$router.push({ path: `/library/${category}` });
        },
        updateSection(keySection) {
            let scroll = scroller();
            scroll(`#${keySection}`);
            this.activeSection = keySection;
        },
        handleNavbarMobile(value) {
            this.activeNavbarMobile = value;
        }
    },
}
</script>