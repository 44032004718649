export const baseURL = "https://firebasestorage.googleapis.com/v0/b/standarrector/o/";
export const baseBucketURL = "https://rectoral-board.s3.eu-west-1.amazonaws.com/";

export const IMAGES = {
    logos: {
        horizontal_png: `${baseURL}logos%2Flogo-rectoral.png?alt=media&token=f0d7d65f-46c9-4d96-a43b-41192513ceaa`,
        horizontal_svg: `${baseURL}logos%2Flogo-rectoral.svg?alt=media&token=d1519aea-3a71-4e32-8678-c379e875f76e`,
        vertical_png: `${baseURL}logos%2Flogo-vertical-rectoral.png?alt=media&token=003a1a66-0a2d-4860-95f2-0f2feadcddb4`,
        vertical_svg: `${baseURL}logos%2Flogo-vertical-rectoral.svg?alt=media&token=04a83238-2605-4fd6-9a7f-a4c31fc44cde`,
    },
    services: {
        portrait: `${baseURL}services%2Fportrait.jpg?alt=media&token=c8f54988-7a10-4a9f-9ec1-bf5fdf59a38a`,
        blog: `${baseURL}services%2Fblog.png?alt=media&token=34c91fd8-a629-4f54-ab8d-513c1bade066`,
        lidership: `${baseURL}services%2Flidership.png?alt=media&token=ab63c613-3c99-4940-9f8e-970809540c68`,
        eagles: `${baseBucketURL}services/eagles.png`,
        mentoring: `${baseURL}services%2Fmentoring.png?alt=media&token=019995f0-de21-4ed6-8a81-a7d2764fb1a0`,
        toolbox: `${baseURL}services%2Ftoolbox.png?alt=media&token=6578f348-be7d-4c4f-a576-31163fbfa2cf`,
        back_mentoring: `${baseURL}services%2Fback_mentoring.jpg?alt=media&token=b8c78fe5-a8ca-4e00-838c-23a590e277a9`,
        back_leadership: `${baseURL}services%2Fback_leadership.png?alt=media&token=5624c824-b36c-4602-9cbd-e159082a2ea8`,
        back_toolbox: `${baseURL}services%2Fback_toolbox.png?alt=media&token=fe0b88d7-a22e-40c5-9ff3-4a6d2090c17d`,
        pyramid: `${baseURL}services%2Fpyramid.png?alt=media&token=450e06f6-af82-47da-bd0e-79b5a9b2737e`,
        groups: `${baseURL}services%2Fgroups.png?alt=media&token=9ed67170-3b88-4446-baab-923bc805a978`,
    },
    memberships: {
        gold: `${baseURL}memberships%2Fgold.png?alt=media&token=e5b0108f-082f-42bc-947d-4e1336eb0c09`,
        platinum: `${baseURL}memberships%2Fplatinum.png?alt=media&token=26b6eee9-1713-4009-afc8-200779c098e3`,
        diamond: `${baseURL}memberships%2Fdiamond.png?alt=media&token=ffbe94e7-b319-4b08-9daa-721894c61869`,
    },
    webinars: {
        legacy: `${baseURL}webinars%2Flegacy.png?alt=media&token=fdef6c02-2756-4642-abb4-e6609b946092`,
        think_tank_board: `${baseURL}webinars%2Fthinktank.png?alt=media&token=baa24c00-efc1-43c2-b2e2-f23cccf59b01`,
        brands_rectoral: `${baseURL}webinars%2Fbrands.png?alt=media&token=4cf003f2-31dd-4819-b6bf-90766d2b582b`,
        road_map_rectoral: `${baseURL}webinars%2Froadmap.png?alt=media&token=21974169-0759-44aa-9194-4c2bcdb07388`,
    },
    languages: {
        spanish: 'https://flagcdn.com/es.svg',
        english: 'https://flagcdn.com/gb.svg',
        portugues: 'https://flagcdn.com/br.svg',
    },
    home: {
        about_us: `${baseURL}complementary%2Fhome%2Fabout_us.jpg?alt=media&token=820cfcc2-5488-42fd-9656-8d707287704f`,
        external_schedule: `${baseURL}complementary%2Fhome%2Fexternal_schedule.jpg?alt=media&token=6eba1e2b-e22f-42fd-b1e0-c228cb856bc9`,
        contributions: `${baseURL}complementary%2Fhome%2Fcontributions.jpg?alt=media&token=609b2494-2570-4a59-a37a-f7f8226bdd3d`,
        apporters: `${baseURL}complementary%2Fhome%2Fapporters.jpg?alt=media&token=2f1d40b6-8071-4f0b-a01d-b4d5a1520b26`,
        webinars: {
            icon: `${baseURL}complementary%2Fhome%2Fwebinars.png?alt=media&token=14420d1a-8a4a-4b17-a667-209b394780fc`,
            section: `${baseURL}complementary%2Fhome%2Fimage-webinars.png?alt=media&token=5835faa1-2b5a-4561-90c2-3088210093d7`,
        },
        portfolio: {
            icon: `${baseURL}complementary%2Fhome%2Fportfolio.png?alt=media&token=066f388d-3cd9-4134-9a57-8d44a640c430`,
        },
        library: {
            icon: `${baseURL}complementary%2Fhome%2Flibrary.png?alt=media&token=01b6ca57-3ecd-41b4-ab53-22c305e59404`,
            reports: `${baseURL}library%2Ficons%2Freports.png?alt=media&token=929752e4-b0a0-4fb7-8441-4edd97b5b723`,
            books: `${baseURL}library%2Ficons%2Fbooks.png?alt=media&token=16c1c1f6-fc67-4b27-a60b-24825db80bd4`,
            conferences: `${baseURL}library%2Ficons%2Fconferences.png?alt=media&token=466defd5-f8e2-40f2-bc8c-319b1929cb52`,
            trends: `${baseURL}library%2Ficons%2Ftrends.png?alt=media&token=f7e3faa5-82e3-4237-9b54-41351ec27e9e`,
            portrait: `${baseURL}library%2Fportrait.svg?alt=media&token=c638da7e-4561-4c83-959f-4ac3a687347a`
        }
    },

    governance: {
        valdecir: `${baseURL}governance%2FValdecir.jpg?alt=media&token=e28d0746-9ea9-4e6f-b198-d9dc4283c99c`,
        grupo_standard: `${baseURL}governance%2Flogo_grupo_standard.jpg?alt=media&token=58e5e23d-943c-4151-9b2e-41c846a1a81d`,
        oderbiz: `${baseURL}governance%2Foderbiz.png?alt=media&token=4c21ef2d-9b62-4c28-8958-00025564a711`,
    },

    kpis: {
        users: `${baseURL}complementary%2Fhome%2Fmembers.png?alt=media&token=a3be7ab1-0cae-4f19-a0a2-3f90a9e07e3b`,
        countries: `${baseURL}complementary%2Fhome%2Fcountries.png?alt=media&token=8e91b7a2-891b-4e3b-b272-c48411ae7c4a`,
        male: `${baseBucketURL}logos/male.png`,
        female: `${baseBucketURL}logos/female.png`,
    },
    backgrounds: {
        home: `${baseURL}complementary%2Fhome%2Fbackground-sections.png?alt=media&token=94b91f6f-ff21-4110-8f56-26bc1123035c`,
        library: `${baseURL}complementary%2Fhome%2Fbackground-library.png?alt=media&token=2e1cf39c-67f3-4c8c-963e-71f5744aca45`,
        principles: `${baseURL}complementary%2Finstitutional%2Fprinciples.jpg?alt=media&token=29a7819c-59ad-4df0-8efe-22b2dbcbbb34`,
        library:`${baseURL}library%2Fbackground.jpg?alt=media&token=b449e76d-a87b-4c87-8705-ffdc2957e025`,
        article: `${baseURL}complementary%2Fktdra%2Fbackground-article.png?alt=media&token=4868e33e-8b4e-4ba4-be0d-b6fd1f0cd2aa`
    },
    institutional: {
        insight: `${baseURL}complementary%2Finstitutional%2Finsight.jpg?alt=media&token=edc2c43b-8a32-4564-a0d6-880cf5db9990`,
        decoration: {
            circle_a: `${baseURL}complementary%2Finstitutional%2Fa.png?alt=media&token=b6809cc6-d17b-46ca-b012-1808fbfa941a`,
            circle_b: `${baseURL}complementary%2Finstitutional%2Fb.png?alt=media&token=512aa894-7336-4727-8b35-8f55ffa07902`,
            circle_c: `${baseURL}complementary%2Finstitutional%2Fc.png?alt=media&token=ce681f47-0894-47ad-91dd-6cec623e86ac`,
        }
    },
    icons: {
        article: `${baseURL}icons%2Farticle.svg?alt=media&token=bd3a2798-3515-489c-86c7-5dddeb954946`,
        book: `${baseURL}icons%2Fbook.svg?alt=media&token=00c68973-64c3-48dc-ae17-e56ca3f97274`,
        feature: `${baseURL}icons%2Fcogs.svg?alt=media&token=b605232c-1c86-42f2-b0cc-067242bc088a`,
        profile: `${baseURL}icons%2Fprofile.svg?alt=media&token=b54cea9a-9e13-417e-9b33-f473c7c8ed68`,
        news: `${baseURL}icons%2Fnews.png?alt=media&token=8933f1dc-e937-4f7f-aea0-c4526f8b496a`,
        zoom: `${baseURL}webinar_transmission%2Fzoom.png?alt=media&token=dba9edcc-3d8b-477d-a136-d00326d24111`,
        webinars: `${baseURL}webinars%2Ficon-webinars.svg?alt=media&token=9481c404-76d2-4989-908f-173fd2973623`,
        success: `${baseURL}extras%2Fregister_success.svg?alt=media&token=04969da0-9f69-4218-b3e3-a57d9a3bf008`
    },
    transmission: {
        facebook: `${baseURL}webinar_transmission%2Ffacebook.png?alt=media&token=6bf65d8b-a674-451a-a476-a8d92e850739`,
        utpl: `${baseURL}webinar_transmission%2Futpl.png?alt=media&token=0284e3f7-923a-472f-bca3-e3b73671a64b`,
        youtube: `${baseURL}webinar_transmission%2Fyoutube.png?alt=media&token=2dfc1ea0-6e3d-4861-b17a-220d566d3717`,
        zoom: `${baseURL}webinar_transmission%2Fzoom.png?alt=media&token=dba9edcc-3d8b-477d-a136-d00326d24111`,
    },
    portrait: {
        guide: `${baseURL}portrait%2F1.svg?alt=media&token=9470b755-22a7-4feb-b3ef-abe14b5b2618`,
        quijote: `${baseURL}portrait%2F2.svg?alt=media&token=bfea773d-8ec3-47e0-9e04-8e4aad6f519b`,
        person: `${baseURL}portrait%2F3.svg?alt=media&token=003c6488-1fca-49b9-810a-fe63e8866c48`,
        graduate: `${baseURL}portrait%2F4.svg?alt=media&token=b4de36a1-8f01-4f97-97a4-d3eb8dbd7270`,
        cotopaxi: `${baseURL}portrait%2F6.svg?alt=media&token=f301782c-1d90-4576-9ecf-ac96f4f60de6`,
        palace: `${baseURL}portrait%2F7.svg?alt=media&token=88a7e513-2029-4dc6-95b4-a2e87be2c833`,
        horizont: `${baseURL}portrait%2F8.svg?alt=media&token=ff0b9fb8-3319-4bfb-9422-1d29d9b7e0b5`,
        books: `${baseURL}portrait%2F9.svg?alt=media&token=eea8a6a3-5878-4349-a838-57debe590d57`,
        city: `${baseURL}portrait%2F5.svg?alt=media&token=4f796b21-0e37-4c31-a893-d3c78f3bfd84`,
        celebrate: `${baseURL}portrait%2F10.svg?alt=media&token=03a403de-a7d9-49ec-8005-b18c92b8c59d`,
    },

    dashboard: {
        default_avatar: `${baseBucketURL}dashboard/default-avatar.svg`,
        clap: `${baseBucketURL}dashboard/clap.svg`,
        opinion: `${baseBucketURL}dashboard/opinion.svg`,
    },
}

export const VIDEOS = {
    presentation: `${baseBucketURL}videos/Rectoral+Board-Presentacion.mp4`,
}
