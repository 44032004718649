<template>
  <div class="reports row">
    <download-csv :data="new_data">
      Descargar Reporte
    </download-csv>
  </div>
</template>

<script>
import { db } from "../config/firebase/db";

export default {
  name: "reports",
  data: function() {
    return {
      data: {
        users: [],
        gold: 0,
        diamond: 0,
        platinum: 0
      },
      new_data: []
    };
  },
  beforeMount: function() {
    this.getUsers();
  },
  methods: {
    getUsers: async function() {
      let data = await db.collection("users").get();
      data.forEach(bl => {
        this.data.users.push({
          id: bl.id,
          data: bl.data()
        });
      });
      this.ordenar();
    },

    ordenar: function() {
      let prueba = [];
      this.data.users.forEach(bl => {
        prueba.push({
          id: bl.id,
          data: bl.data
        });
      });
      prueba.sort(function(a, b) {
        if (a.data.personal.names > b.data.personal.names) {
          return 1;
        }
        if (a.data.personal.names < b.data.personal.names) {
          return -1;
        }
        return 0;
      });
      this.data.users = prueba;
      this.loadUsers();
    },
    loadUsers: function() {
      this.data.users.forEach(user => {
        this.new_data.push({
          membresia: user.data.type.name,
          nombres: user.data.personal.names,
          apellidos: user.data.personal.surnames,
          pais: user.data.contact.country,
          cargo: user.data.academic.cargo,
          titulo: user.data.academic.title,
          institucion: user.data.academic.institucion,
          caracterizacion: user.data.academic.caracterizacion,
          correo: user.data.account.email,
          telefono: user.data.contact.phone,
          facebook: user.data.contact.social.fb,
          linkedin: user.data.contact.social.li,
          twitter: user.data.contact.social.tw,
          slogan: user.data.slogan.content,

          mentoring: user.data.services[0].status ? "Sí" : "No",
          "Realizado algún servicio de Mentoring para otros dirigentes fuera de la institución":
            user.data.services[0].items[0].asnwer,
          "¿Tienes alguna formación específica en Mentoring?":
            user.data.services[0].items[1].asnwer,
          "¿En caso de darse la oportunidad, tendrías interés de participar de un curso de formación de Mentoring para Rectores?": user
            .data.services[0].items[2].asnwer
            ? "Sí"
            : "No",

          contenido_blog: user.data.services[1].status ? "Sí" : "No",
          "¿Cuáles son los temas de interés para las publicaciones?":
            user.data.services[1].items[0].asnwer,

          liderazgos_sembradores: user.data.services[2].status ? "Sí" : "No",
          "¿Qué contenido le gustaría ofrecer de manera individual o como parte de un programa favorito?":
            user.data.services[2].items[0].asnwer,
          "¿Has realizado esta actividad en alguna IES?":
            user.data.services[2].items[1].asnwer,

          toolbox: user.data.services[3].status ? "Sí" : "No",
          "¿Qué tipo de actividad de consultoría/asesoría técnica para IES usted se considera apto para ofrecer?":
            user.data.services[3].items[0].asnwer,
          "¿En qué IES realizaste este tipo de actividad con éxito?":
            user.data.services[3].items[1].asnwer,

          otros: user.data.services[4].status ? "Sí" : "No",
          "¿Tienes interés en ofrecer junto con Rectoral Board otros servicios específicos?":
            user.data.services[4].items[0].asnwer,
          "¿Dónde y cuándo implementaste el servicio con éxito?":
            user.data.services[4].items[1].asnwer
        });
      });
    }
  }
};
</script>

<style>
.reports {
  position: relative;
}
</style>
