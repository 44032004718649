
import axios from "axios";
import { DASHBOARD_API_URL, BASE_URL, routes, paths } from '../../api-routes';
import queryString from 'querystring';

export async function getPartners(params) {
    try {
        let url = `${DASHBOARD_API_URL}/users/partners?${queryString.stringify(params)}`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}

export async function signIn(payload) { 
    try {

        let url = `${BASE_URL}${paths.USER}${routes.SIGNIN}`;

        let response = await axios.post(url, payload);
        return response.data;
        
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}


export async function getDataUser(userID, token) {
    try {
        
        let url = `${BASE_URL}${paths.USER}${routes.GET_DATA_USER}`;

        const payload = {userID};

        let response = await axios.post(url, payload);
        return response.data;

    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}

export async function registerUser(payload) {
    try {
        let url = `${BASE_URL}${paths.USER}${routes.REGISTER_USER}`;
        let response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        return {
            code: 403,
            message: `Error: ${error}`
        }
    }
}