import Vue from 'vue'

import {
    Avatar,
    Button,
    Card,
    Checkbox,
    Col,
    ConfigProvider,
    Divider,
    Form,
    FormModel,
    Icon,
    Input,
    Layout,
    List,
    Menu,
    Modal,
    message,
    Row,
    Select,
    Skeleton,
    notification,
    Space,
    Spin,
    Switch,
    Table,
    Tabs,
    Tooltip
} from "ant-design-vue";
import "@/styles/ant.less";

Vue.use(Avatar);
Vue.use(Button);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(ConfigProvider);
Vue.use(Divider);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Layout);
Vue.use(List);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(message);
Vue.use(Row);
Vue.use(Select);
Vue.use(Skeleton);
Vue.use(notification);
Vue.use(Space);
Vue.use(Spin);
Vue.use(Switch);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Tooltip);