<template>
    <div>
        <div v-if="position === 0" class="responsability">
            <a-space direction="vertical">
                <a-text class="title text--level-2">
                    {{  $t('INSTITUTIONAL.GOVERNANCE.EXECUTIVE_MANAGEMENT.DESCRIPTION') }}
                </a-text>
                <a-text 
                    v-for="(enumerate, index) in 21"
                    :key="`${section}_${index}`"
                    class="text text--level-2 cl--primary-blue item"
                >
                    {{ $t(`INSTITUTIONAL.GOVERNANCE.${section}.SUBTITLE.${enumerate}`) }}
                </a-text>
            </a-space>
        </div>

        <div v-if="position === 1" class="responsability">
            <a-space direction="vertical">
                <a-text class="title text--level-2">
                    {{  $t('INSTITUTIONAL.GOVERNANCE.REGIONAL_VICE_PRESIDENCY.DESCRIPTION') }}
                </a-text>
                <a-text 
                    v-for="(enumerate, index) in 16"
                    :key="`${section}_${index}`"
                    class="text text--level-2 cl--primary-blue item"
                >
                    {{ $t(`INSTITUTIONAL.GOVERNANCE.${section}.SUBTITLE.${enumerate}`) }}
                </a-text>
            </a-space>
        </div>

        <div v-if="position === 2" class="responsability">
            <a-space direction="vertical" :size="24">
                <a-space direction="vertical">
                    <a-text class="title text--level-2">
                        {{  $t('INSTITUTIONAL.GOVERNANCE.GABINETE.DESCRIPTION') }}
                    </a-text>
                    <a-text 
                        v-for="(enumerate, index) in 5"
                        :key="`${section}_${index}`"
                        class="text text--level-2 cl--primary-blue item"
                    >
                        {{ $t(`INSTITUTIONAL.GOVERNANCE.${section}.${enumerate}`) }}
                    </a-text>
                </a-space>

                <a-space direction="vertical">
                    <a-text class="title text--level-2">
                        {{  $t('INSTITUTIONAL.GOVERNANCE.GABINETE.VICE') }}
                    </a-text>
                    <a-text 
                        v-for="(enumerate, index) in 5"
                        :key="`${section}_${index}`"
                        class="text text--level-2 cl--primary-blue item"
                    >
                        {{ $t(`INSTITUTIONAL.GOVERNANCE.${section}.VICE_ITEMS.${enumerate}`) }}
                    </a-text>
                </a-space>
                
                <a-text class="title text--level-1">
                    {{  $t('INSTITUTIONAL.GOVERNANCE.GABINETE.HELP') }}
                </a-text>

                <a-space direction="vertical">
                    <a-text class="title text--level-2">
                        {{  $t('INSTITUTIONAL.GOVERNANCE.GABINETE.VICE_ATTACHMENTS') }}
                    </a-text>
                    <a-text 
                        v-for="(enumerate, index) in 4"
                        :key="`${section}_${index}`"
                        class="text text--level-2 cl--primary-blue item"
                    >
                        {{ $t(`INSTITUTIONAL.GOVERNANCE.${section}.VICE_ATTACHMENTS_ITEMS.${enumerate}`) }}
                    </a-text>
                </a-space>

                <a-space direction="vertical">
                    <a-text class="title text--level-2">
                        {{  $t('INSTITUTIONAL.GOVERNANCE.GABINETE.EXECUTIVE_SECRETARY') }}
                    </a-text>
                    <a-text 
                        v-for="(enumerate, index) in 3"
                        :key="`${section}_${index}`"
                        class="text text--level-2 cl--primary-blue item"
                    >
                        {{ $t(`INSTITUTIONAL.GOVERNANCE.${section}.EXECUTIVE_SECRETARY_ITEMS.${enumerate}`) }}
                    </a-text>
                </a-space>

                <a-space direction="vertical">
                    <a-text class="title text--level-2">
                        {{  $t('INSTITUTIONAL.GOVERNANCE.GABINETE.ACADEMIC_SECRETARY') }}
                    </a-text>
                    <a-text 
                        v-for="(enumerate, index) in 4"
                        :key="`${section}_${index}`"
                        class="text text--level-2 cl--primary-blue item"
                    >
                        {{ $t(`INSTITUTIONAL.GOVERNANCE.${section}.ACADEMIC_SECRETARY_ITEMS.${enumerate}`) }}
                    </a-text>
                </a-space>
            </a-space>
        </div>

        <div v-if="position === 3" class="responsability">
            <a-space direction="vertical">
                <a-text class="title text--level-2">
                    {{  $t('INSTITUTIONAL.GOVERNANCE.ADMINISTRATIVE.DESCRIPTION') }}
                </a-text>
                <a-text 
                    v-for="(enumerate, index) in 5"
                    :key="`${section}_${index}`"
                    class="text text--level-2 cl--primary-blue item"
                >
                    {{ $t(`INSTITUTIONAL.GOVERNANCE.${section}.SUBTITLE.${enumerate}`) }}
                </a-text>
            </a-space>
        </div>

        <!-- <div v-if="position === 2" class="responsability">
            <a-row justify="space-between" align="middle" :gutter="[16, 32]">
                <a-col :span="8" :lg="8" :xs="24">
                    <a-space direction="horizontal" :size="16" >
                        <a-avatar
                            :size="124"
                            :src="gabinete.oderbiz"
                        />

                        <a-space direction="vertical" :size="4">
                            <span class="title text--level-2 cl--primary-blue">
                                {{ gabinete.name }}
                            </span>
                            <span class="text text--level-2 cl--primary-blue">
                                {{ gabinete.slogan }}
                            </span>
                        </a-space>

                    </a-space>
                </a-col>
                <a-col :span="16" :lg="16" :xs="24">
                    <span 
                        v-for="(enumerate, index) in 3"
                        :key="`${section}_${index}`"
                        class="text text--level-2 cl--primary-blue item"
                    >
                        {{ (index + 1) + ". " + $t(`INSTITUTIONAL.GOVERNANCE.${section}.${enumerate}`) }}

                        <div class="responsability" v-if="index === 0" style="padding-top: 8px !important">
                            <span 
                                v-for="(itemize, subIndex) in 6"
                                :key="`${section}_${itemize}_${subIndex}`"
                                class="text text--level-2 cl--primary-blue item"
                            >
                                {{ $t(`INSTITUTIONAL.GOVERNANCE.${section}.${index + 1}${itemize}`) }}
                            </span>
                        </div>
                    </span>
                </a-col>
            </a-row>
        </div>

        <div v-if="position === 3" class="responsability">
            <a-row justify="space-between" align="middle" :gutter="[40, 32]">
                <a-col :span="4" :lg="4" :xs="24">
                    <a-avatar
                        :size="124"
                        :src="administrative.valdecir"
                    />
                </a-col>
                <a-col :span="10" :lg="10" :xs="24">
                    <a-space direction="vertical" :size="8">
                        <span class="title title--level-3 cl--primary-blue">
                            {{ administrative.name }}
                        </span>

                        <span class="title text--level-3 cl--primary-blue">
                            {{ $t(`INSTITUTIONAL.GOVERNANCE.${section}.STANDARD_INTERNATIONAL_CONSULTANCY`) }}
                        </span>
                        <span class=" text text--level-2 cl--primary-blue">
                            {{ $t(`INSTITUTIONAL.GOVERNANCE.${section}.SUMMARY`) }}
                        </span>

                        <a-avatar 
                            class="logoGrupoStandard" 
                            shape="square"
                            :src="administrative.grupo_standard"
                        />
                    </a-space>
                </a-col>
                <a-col :span="10" :lg="10" :xs="24">
                    <span 
                        v-for="(enumerate, index) in 3"
                        :key="`${section}_${index}`"
                        class="text text--level-2 cl--primary-blue item"
                    >
                        {{ (index + 1) + ". " + $t(`INSTITUTIONAL.GOVERNANCE.${section}.${enumerate}`) }}
                    </span>
                </a-col>
            </a-row>
        </div> -->
    </div>
</template>

<script>
import { IMAGES } from "@/constants/images"

export default {
    
    data: () => {
        return {
            gabinete: {
                name: "Oderbiz",
                slogan: "Marketing & Estrategia",
                oderbiz: IMAGES.governance.oderbiz,
                country: "Ecuador"
            },
            administrative: {
                name: "Valdecir José Gonçalves",
                grupo_standard: IMAGES.governance.grupo_standard,
                valdecir: IMAGES.governance.valdecir,
                country: "Brazil"
            }
        };
    },

    props: {
        position: {
            type: Number,
            default: 0
        },
        section: {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';

.responsability {
    width: 100%;
    position: relative;
    padding: 16px 24px 0 24px !important;

    span { text-align: justify; }

    .title {
        font-weight: 600 !important;
        font-size: 20px;
        margin-bottom: 12px;
    }

    .item {
        display: flex;
        width: 100%;
        margin-bottom: 8px;
    }

    .logoGrupoStandard {
        margin-top: 24px;
        width: auto;
        height: 150px;
    }
}
</style>