export const institutesType = [
    {
        key: 0,
        name: "IES Pública",
        keyName: "IES_PUBLIC",
    },
    {
        key: 1,
        name: "IES Privada",
        keyName: "IES_PRIVATE",
    },
    {
        key: 2,
        name: "IES Comunitaria",
        keyName: "IES_COMMUNITY",
    },
    {
        key: 3,
        name: "IES Tecnológica",
        keyName: "IES_TECH",
    },
    {
        key: 4,
        name: "Red Educacional",
        keyName: "EDUCATIONAL_NETWORK",
    },
    {
        key: 5,
        name: "Institución Gubernamental",
        keyName: "GOVERNMENT_INSTITUTION",
    },
    {
        key: 6,
        name: "Consultoría o Asesoría",
        keyName: "CONSULTING_ASSISTANCE",
    },
    {
        key: 7,
        name: "Proveedor Tecnológico",
        keyName: "TECHNOLOGY_PROVIDER",
    }
]
