<template>
    <div class="mainContainer sections full-width full-height bg--secondary-white" style="position: relative">

        <Headers
            :showLine=headers.line
            :title=headers.title
            align="left"
        />

        <div class="flex-column start-column-center full-width">
            <div
                class="firstNew full-width"
                v-if="news.data.length > 0"
                @click="goToNew(news.data[0].externalLink)"
            >
                <div class="cont-img" :style="getBackground()">
                    <div class="mask" />
                    <a-space direction="vertical" :size="4" class="full-width">
                        <div class="info-new flex-column-center center-column-start">
                            <span class="title text--level-2 cl--secondary-white">
                                {{ news.data[0].title[locales[locale]] }}
                            </span>
                            <span class="text text--level-3 cl--secondary-white">
                                {{ news.data[0].summary[locales[locale]] }}
                            </span>
                            <span class="text text--level-3 cl--secondary-white date">
                                {{ `${news.data[0].source} - ${formatDate(news.data[0].date)}` }}
                            </span>
                        </div>
                    </a-space>
                </div>
            </div>

            <div class="slideNews full-width">
                <slider
                    animation="fade"
                    :indicators=false
                    :interval="6000"
                    :touch="true"
                    :control-btn=true
                    height="100%"
                    class="full-width"
                    style="overflow: initial;"
                >
                    <slider-item
                        v-for="(newData) in news.data"
                        :key="newData._id"
                        class="flex-column center-column-center boardNew"
                        style="right: 0;"
                    >
                        <a-space direction="vertical" :size="4" class="full-width">
                            <span class="title text--level-2 cl--secondary-white">
                                {{ newData.title[locales[locale]] }}
                            </span>
                            <span class="text text--level-3 cl--secondary-white">
                                {{ newData.summary[locales[locale]] }}
                            </span>
                            <span class="text text--level-3 cl--secondary-white date">
                                {{ `${newData.source} - ${formatDate(newData.date)}` }}
                            </span>
                            <a-space direction="horizontal" :size="16">
                                <a-avatar :size="32" :src="iconNew" shape="square" />
                                <span
                                    class="title text--level-3 cl--primary-blue link"
                                    @click="goToNew(newData.externalLink)" 
                                >
                                    {{ $t('HOME.BOARD_NEWS.SEE_NOTICE')}}
                                </span>
                            </a-space>
                        </a-space>
                    </slider-item>
                </slider>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { IMAGES } from '@/constants/images';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/pt-br';

export default {
    data() {
        return {
            headers: {
                title: "HOME.BOARD_NEWS.TITLE",
                line: true,
            },
            loading: true,
            iconNew: IMAGES.icons.news,
            locales: {
                es: 0,
                en: 1,
                pt: 2
            },
            params: {
                limit: 10,
            }
        }
    },
    
    async created() {
        await this.initialData();
    },

    computed: {
        ...mapGetters("landing", {
			news: "getNews",
		}),

        locale() {
            return this.$i18n.locale;
        },
    },

    methods: {
        ...mapActions("landing", {
			getNewsStore: "getNews",
		}),

        async initialData() {
            console.log(this.news);
            if (this.news.data.length === 0) {
                await this.getNewsStore(this.params);
                this.loading = false;
            };
        },

        getBackground () {
            return {
                "background-image": "url(" + this.news.data[0].image.original + ")",
            };
        },

        goToNew(link) {
            window.open(link, '_blank');
        },

        formatDate (date) {
            moment.locale(this.$i18n.locale);
            return moment(date).format('LLLL');
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
@import '@/styles/scss/components/boardNews.scss';
</style>