import Vue from "vue";
import VueRouter from "vue-router";
import Meta from 'vue-meta';

import Home from "@/views/Home.vue";
import Institutional from "@/views/Institutional.vue";
import Briefcase from "@/views/Briefcase.vue";
import Offer from "@/views/Offer.vue";
import Register from "@/views/Register.vue";

import KtdraChannel from "../views/KtdraChannel.vue";
import Reports from "../views/Reports.vue";
import BloguerProfile from "../components/blog/subcomponents/BloguerProfile.vue";
import SingleArticle from "../components/blog/subcomponents/SingleArticle.vue";
import ArticleDetail from "../components/ktdra/ArticleDetail.vue";

import CategorySelection from "@/components/webinars/CategorySelection.vue";
import Library from "../views/Library.vue";
import Webinars from "../views/Webinars.vue";
import LibraryCategory from "@/components/library/LibraryCategory.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    props(route) {
      if (route.query) {
        route.query.section = route.query.section;
        return route.query;
      } else {
        return {};
      }
    }
  },
  {
    path: "/institutional",
    name: "institutional",
    component: Institutional,
    props(route) {
      if (route.query) {
        route.query.section = parseInt(route.query.section);
        return route.query;
      } else {
        return {};
      }
    }
  },
  {
    path: "/briefcase",
    name: "briefcase",
    component: Briefcase,
  },
  {
    path: "/offer",
    name: "offer",
    component: Offer,
    props(route) {
      if (route.query) {
        route.query.section = parseInt(route.query.section);
        return route.query;
      } else {
        return {};
      }
    }
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/ktdra-channel",
    name: "ktdra",
    component: KtdraChannel
    /* ,
    props(route) {
      if (route.query) {
        route.query.id = parseInt(route.query.id);
        return route.query;
      } else {
        return {};
      }
    } */
  },
  {
    path: "/ktdra-channel/:articleID",
    name: "ArticleDetail",
    component: ArticleDetail
  },
  {
    path: "/blog/:name",
    name: "BloguerProfile",
    component: BloguerProfile
  },
  {
    path: "/blog/:name/articles/:id",
    name: "SingleArticle",
    component: SingleArticle,
    props: true
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports
  },
  {
    path: "/webinars",
    name: "Webinars",
    component: Webinars,
    props: true
  },
  {
    path: "/webinars/:category",
    name: "CategorySelection",
    component: CategorySelection,
    props: true
  },
  {
    path: "/library",
    name: "Library",
    component: Library,
    props: true
  },
  {
    path: "/library/:category",
    name: "LibraryCategory",
    component: LibraryCategory,
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  }
});

/* router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // console.log(record.meta.requiresAuth);
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // console.log(auth.currentUser);
    if (!auth.currentUser) {
      next({ path: '/login' })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
}) */

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(route => route.meta.requiresAuth)) {
//     console.log(route.meta.requiresAuth);
//     if (auth.currentUser) {
//       next();
//     } else {
//       next({ path: "/" });
//     }
//   }
//   next();
// });

export default router;
