<template>
    <div class="mainContainer sections full-width full-height bg--secondary-grey-alt">

        <!-- MODAL EVENT -->
        <a-modal
            class="modalEvent"
            v-model="showEvent"
            :width="'800px'"
            :footer="null"
        >
            <Event
                @changeStateModal="(eventData, state) => changeStateModal(eventData, state)"
                :event="eventSelected"
            />
        </a-modal>
        <!-- END MODAL EVENT -->

        <a-space direction="vertical" :size="40" class="full-width">
            <Headers
                :showLine="headers.line"
                :title="headers.title"
                :description="headers.description"
                align="left"
            />

            <Loader :type="type" v-if="loaded"/>

            <div class="flex-row center-row-center full-width containerCalendar" v-else>
                <a-row :gutter="0" justify="space-between" align="middle" class="full-width full-height">
                    <a-col :span="24" :md="24" :sm="24" :xs="24" style="overflow: hidden">
                        <div class="flex-row start-column-start containerEvents">
                            <div 
                                class="cl--primary-blue flex-column center-column-center events"
                                v-for="(event, position) in webinarsCalendar" 
                                :key="position"
                                :class="{
                                    'first': position === 0,
                                    'last': position === event.length - 1
                                }"
                                v-show="event.month !== 0"
                            >
                                <div class="month flex-column start-column-center">    
                                    <span class="title text--level-1 cl--primary-blue monthText">
                                        {{ $t(`MONTHS.${event.name}`) }}
                                    </span>
                                    <div class="containerDays flex-row start-row-center">
                                        <span 
                                            class="dayMonth title text--level-2 cl--primary-blue active"
                                            v-for="(day, INDEX) in event.days" 
                                            :key="`month_${position}_day_${day}`" 
                                            :class="{
                                                'first': INDEX === 0,
                                                'last': INDEX === event.days.length - 1,
                                            }"
                                        >
                                            {{ day }}
                                        </span>
                                    </div>
                                </div>

                                <div
                                    class="flex-column center-column-center listWebinars"
                                    v-for="(webinar) in event.list" 
                                    :key="webinar._id" 
                                >
                                    <div class="full-width full-height">
                                        <div
                                            class="webinar flex-column start-column-start full-height"
                                            :class="webinar.category.class"
                                            @click="changeStateModal(webinar, true)"
                                        >
                                            <a-space class="full-width" direction="horizontal" :size="8">
                                                <a-avatar
                                                    shape="square"
                                                    :src="webinar.category.logo"
                                                    class="logoWebinar"
                                                />
                                                <div>
                                                    <span class="title text--level-2">
                                                        {{ `${webinar.tag} ${$t('WEBINARS.INTERVIEW')}` }}
                                                    </span>
                                                    <span class="title text--level-3">
                                                        {{ webinar.category.name }}
                                                    </span>
                                                </div>
                                            </a-space>

                                            <span class="text text--level-3 date">
                                                {{ formatDate(webinar.date) }}
                                            </span>

                                            <span 
                                                class="isNext title text--level-3 right full-width"
                                                v-if="webinar.isNext"
                                            >
                                                {{ $t('WEBINARS.IS_NEXT') }}
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>
            <OutsideHoldings />
        </a-space>

    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { WEBINARS_NAME } from '@/constants/webinars';
import { getDayFromDate } from "@/utils/util-dates.js";
import Event from "@/components/home/Event";
import OutsideHoldings from "./OutsideHoldings"
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb'
import 'moment/locale/pt-br'

export default {
    data: () => {
        return {
            headers: {
                title: "HOME.EVENTS.RECTORAL_BOARD_EVENTS",
                description: "HOME.EVENTS.DESCRIPTION",
                line: true,
            },
            eventSelected: null,
            showEvent: false,
            loaded: true,
            type: 1,
        };
    },

    async created() {
        await this.initialData();
    },

    computed: {
        ...mapGetters("landing", {
			webinarsCalendar: "getWebinarsCalendar",
		}),

        categories() {
            return WEBINARS_NAME
        }
    },

    components: {
        Event,
        OutsideHoldings,
    },

    beforeDestroy() {
        this.clearWebinarsCalendar();
    },

    methods: {
        ...mapActions("landing", {
			getWebinarsCalendarStore: "getWebinarsCalendar",
            clearWebinarsCalendar: "clearWebinarsCalendar",
		}),

        async initialData() {
            if(this.webinarsCalendar.length === 0) {
                await this.getWebinarsCalendarStore();
                this.loaded = false;
            };
        },
        
        addActiveEvent(position, day) {
            let actives = this.webinarsCalendar[position].actives;
            if ( actives.includes(day) ) {
                this.webinarsCalendar[position].actives = actives.filter(active => active !== day);
            } else {
                actives.push(day)
            }
        },
        async getDay(date) {
            return await getDayFromDate(date);
        },
        changeStateModal(eventData, state) {
            this.eventSelected = null;
            this.eventSelected = eventData;
            this.showEvent = state;
        },
        formatDate (date) {
            moment.locale(this.$i18n.locale);
            return moment(date).format('LLLL');
        }
    }

}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
@import '@/styles/scss/components/boardEvents.scss';
</style>