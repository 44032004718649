<template>
  <div class="portada section nopadding bloguers">
    <svg
      class="background all_size"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <radialGradient
          id="myGradient"
          r="1"
          cx="1.15"
          cy="0.5"
          fx="0.5"
          fy="0.3"
        >
          <stop offset="50%" style="stop-color:#434343;" />
          <stop offset="99%" style="stop-color:transparent;" />
        </radialGradient>
      </defs>
      <mask id="mask1">
        <rect class="all_size" fill="url('#myGradient')" />
      </mask>
      <image
        id="image_a"
        preserveAspectRatio="none"
        style="mask: url(#mask1);"
        width="100%"
        viewbox="0 0 0 0"
        x="220"
        y="-160"
        xlink:href="assets/blog.jpg"
        height="200%"
        :class="getClassImageSlider()"
      ></image>
    </svg>
    <div class="color all_size"></div>
    <div class="welcome row">
      <div class="a">
        <span class="title">
          {{ data.title[language.active].split(" ")[0] }}
          <br />
          {{ data.title[language.active].split(" ")[1] }}
          <br />
          {{ data.title[language.active].split(" ")[2] }}
        </span>
      </div>
      <div class="b">
        <span class="context">{{ data.content[language.active] }}</span>
      </div>
    </div>
  </div>
</template>

<style>
.portada.bloguers {
  mix-blend-mode: color-burn;
  margin-top: 5vw;
  margin-bottom: 0;
  height: 26.46vw !important;
}
.portada .background {
  position: absolute;
  z-index: 2;
  top: 0;
}
.portada .color {
  position: absolute;
  z-index: 1;
  top: 0;
  background: #005ebd;
}
.portada .imageslide {
  opacity: 0;
  transition: all 0.2s 0.2s ease-in;
}
.portada .imageslide.active {
  opacity: 1;
  transition: all 0.2s 0.2s ease-in;
}
.portada .welcome {
  height: 100%;
  position: absolute;
  background: transparent;
  z-index: 100;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}
.portada .welcome .a {
  width: 27vw;
}
.portada .welcome .a span.title {
  font-family: gothan_bold;
  color: #f2f2f2;
  font-size: 2.9vw;
}
.portada .welcome .b {
  width: calc(100% - 27vw);
}
.portada .welcome .b span.context {
  font-family: gothan_light;
  font-weight: 800;
  color: #f2f2f2;
  display: block;
  width: 70%;
  font-size: 1.3vw;
  line-height: 1.7vw;
}
</style>

<script>
export default {
  data: function() {
    return {
      language: {
        active: 0
      },
      data: {
        title: ["CANAL KTDRA", "CANAL KTDRA", "CANAL KTDRA"],
        content: [
          "Espacio académico privilegiado para compartir conocimientos de reconocidos expertos internacionales sobre el complejo universo de la educación superior.",
          "Privileged academic space to share knowledge of recognized international experts on the complex universe of higher education.",
          "Privilegiado espaço acadêmico para compartilhar conhecimentos de reconhecidos especialistas internacionais sobre o complexo universo da educação superior."
        ]
      }
    };
  },
  props: {
    language_active: {
      type: Number,
      default: 0
    }
  },
  beforeMount: function() {
    this.language.active = this.language_active;
  },
  watch: {
    language_active: function(index) {
      this.language.active = index;
    }
  },
  created() {},
  methods: {
    getClassImageSlider() {
      return {
        imageslide: true,
        active: true
      };
    }
  }
};
</script>
