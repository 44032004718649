<template>
    <div>
        <Navbar 
            @updateSection="updateSection" 
            @handleNavbarMobile="(value) => handleNavbarMobile(value)"
        />
        <NavbarMobile 
            :active="activeNavbarMobile"
            @updateSection="(value) => updateSection(value)"
            @close="() => activeNavbarMobile = false"
        />
        <div class="first sections full-width full-height offer">

            <div class="header flex-column start-column-start">
                <a-space direction="vertical" class="full-width" :size="24">
                    <span class="title title--level-2 cl--primary-blue left">
                        {{ $t(header) }}
                    </span>

                    <span class="title title--level-2 cl--primary-blue left">
                        {{ $t(subHeader) }}
                    </span>
                </a-space>
            </div>
                
            <a-row justify="space-between" align="middle" class="noPadding">
                <a-col 
                    :span="8" :md="8" :sm="24" :xs="24" 
                    :class="`cardCategory ${section.category}`"
                    v-for="section in sections"
                    :key="`${section.data.keyName}`"
                    @click="goToRegister(section.category)"
                >   
                    <a-space direction="vertical" :size="24">
                        <a-list-item-meta 
                            :description="$t(`${section.data.target}`)"
                        >
                            <span
                                :class="section.category"
                                class="title title--level-3 left head" 
                                slot="title"
                            >
                                {{ $t(`MEMBERSHIPS.${section.category}`) }}
                            </span>
                            <a-avatar 
                                :class="`iconMembership ${section.category}`"
                                :src="section.logo"
                                slot="avatar"
                                shape="square"
                                :size="64"
                            />
                        </a-list-item-meta>

                        <a-button
                            type="primary"
                            :class="`full-width button ${section.category}`"
                        >
                            {{ $t('ACTIONS.JOIN_ME') }}
                        </a-button>

                        <div class="benefits">
                            <div
                                :class="{
                                    'benefit': true,
                                    'flex-column': true,
                                    'center-column-center': true
                                }"
                                v-for="(benefit, index) in benefits"
                                :key="`${section.category}_${index}`"
                            >
                                <a-icon 
                                    type="check-circle" 
                                    theme="filled"
                                    :style="`color: ${section.data.color}`"
                                    :class="{
                                        'icon': true,
                                        'no-active': !section.benefits.includes(benefit),
                                    }"
                                />
                                <span class="text text--level-3">
                                    {{ $t(`OFFER.${benefit}`) }}
                                </span>
                            </div>
                        </div>
                    </a-space>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
import { MEMBERSHIPS } from "@/constants/memberships";
import { IMAGES } from "@/constants/images";
import { scroller } from "vue-scrollto/src/scrollTo";

export default {
    data: () => {
        return {
            activeNavbarMobile: false,
            header: "WELCOME_TO_RECTORAL_BOARD",
            subHeader: "HOME.SECTIONS.ABOUT_US.JOIN_TO_RECTORAL_BOARD",
            logo_svg: IMAGES.logos.horizontal_svg,
            benefits: [
                "SERVICES",
                "INFORMATION",
                "SCHEDULE",
                "MEMBERS",
                "LIBRARY",
                "KTDRA",
                "CARDS",
                "EMAIL",
                "TEMPLATES"
            ],
            sections: [
                {
                    data: MEMBERSHIPS.GOLD,
                    category: "GOLD",
                    logo: IMAGES.memberships.gold,
                    benefits: [
                        "SERVICES",
                        "INFORMATION",
                        "SCHEDULE",
                        "MEMBERS",
                        "LIBRARY",
                        "KTDRA",
                    ]
                },
                {
                    data: MEMBERSHIPS.PLATINUM,
                    category: "PLATINUM",
                    logo: IMAGES.memberships.platinum,
                    benefits: [
                        "SERVICES",
                        "INFORMATION",
                        "SCHEDULE",
                        "MEMBERS",
                        "LIBRARY",
                        "CARDS",
                        "KTDRA",
                    ]
                },
                {
                    data: MEMBERSHIPS.DIAMOND,
                    category: "DIAMOND",
                    logo: IMAGES.memberships.diamond,
                    benefits: [
                        "SERVICES",
                        "INFORMATION",
                        "SCHEDULE",
                        "MEMBERS",
                        "LIBRARY",
                        "CARDS",
                        "KTDRA",
                        "EMAIL",
                        "TEMPLATES"
                    ]
                }
            ]
        }
    },
    methods: {
        updateSection(keySection) {
            let scroll = scroller();
            scroll(`#${keySection}`);
            this.activeSection = keySection;
        },
        handleNavbarMobile(value) {
            this.activeNavbarMobile = value;
        },

        goToRegister(membership) {
            const url = "https://dashboard.rectoralboard.com/sign-up";
            window.open(url, "_blank");
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';

.offer {
    min-height: 100vh;
    padding: 16px 24px;
    background: $color-grey-alt;
    position: relative;

    @include breakpoint(md) {
        padding: 32px 70px;
    }

    @include breakpoint(lg) {
        padding: 40px 70px;
    }

    @include breakpoint(xl) {
        padding: 40px 140px;
    }

    .header {
        width: 100%;
        margin-bottom: 48px;

        @include breakpoint(md) {
            width: 70%;
        }

        @include breakpoint(lg) {
            width: 50%;
        }
    }
    
    .cardCategory {
        border-radius: 6px;
        background: $color-white;
        padding: 32px 32px !important;
        margin: 0 12px;
        width: calc(100% - 24px);

        @include breakpoint(md) {
            width: calc((100% / 3) - 24px);
        }

        .ant-list-item-meta-description {
            color: $color-black !important;
            line-height: 18px;
        }

        .head {
            &.GOLD {
                color: $color-category-gold;
            }

            &.PLATINUM {
                color: $color-category-platinum;
            }

            &.DIAMOND {
                color: $color-category-diamond;
            }    
        }

        &.GOLD {
            border-top: solid 8px $color-category-gold;
        }

        &.PLATINUM {
            border-top: solid 8px $color-category-platinum;
        }

        &.DIAMOND {
            border-top: solid 8px $color-category-diamond;
        }

        .iconMembership {
            padding: 8px;

            &.GOLD {
                background: linear-gradient(340.18deg, #E2C247 5.65%, rgba(255, 255, 255, 0) 86.74%);
            }

            &.PLATINUM {
                background: linear-gradient(360deg, #3E3E3E -18.61%, rgba(255, 255, 255, 0) 100%);
            }

            &.DIAMOND {
                background: linear-gradient(359.97deg, #4F88EC -14.72%, rgba(255, 255, 255, 0) 99.97%);
            }   
        }

        .button {
            &.GOLD {
                background: $color-category-gold;
                border-color: $color-category-gold;
            }

            &.PLATINUM {
                background: $color-category-platinum;
                border-color: $color-category-platinum;
            }

            &.DIAMOND {
                background: $color-category-diamond;
                border-color: $color-category-diamond;
            }
        }
    }

    .benefits {
        padding: 16px 0;
    }

    .benefit {

        .icon {
            margin: 12px 0;
            font-size: 16px;
            &.no-active {
                color: $color-grey-sub !important;
            }
        }

        .text {
            color: $color-black !important;
            text-align: center;
        }
    }
}
</style>