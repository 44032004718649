import axios from "axios";
import { BASE_URL, routes, paths } from '../../api-routes';

export async function sendEmailContact(body) {
    try {
        let url = `${BASE_URL}${paths.SENDS}${routes.SEND_EMAIL_CONTACT}`;
        let response = await axios.post(url, body);
        console.log(response);
        return response.data;
    } catch (error) {
        return {
            message: `${error}`
        }
    }
}