<template>
    <a-space direction="vertical" :size="16" class="full-width">
        <div class="liveHeader">
            <a-space direction="horizontal" :size="8">
                <a-icon slot="icon" type="appstore" :style="{ fontSize: '24px', color: '#1b4484' }" />
                <span class="title title--level-2 cl--primary-blue left full-width">
                    {{ $t('WEBINARS.CATEGORIES') }}
                </span>
            </a-space>
        </div>
        <a-row class="containerCategories flex-row center-row-center">
            <a-col
                v-for="category in categories"
                :key="category.code"
                :xs="12"
                :class="`category flex-column center-column-center ${category.class}`"
                @click="selectCategory(category)"
            >
                <a-avatar
                    :src="category.logo"
                    :size="64"
                    shape="square"
                    style="margin-bottom: 8px"
                />
                <span class="title text--level-2 full-width center">
                    {{ $t(`WEBINARS.${category.name}.NAME`) }}
                </span>
            </a-col>
        </a-row>
    </a-space>
</template>

<style lang="scss">
@import "@/styles/scss/config.scss";
@import "@/styles/scss/views/webinars.scss";
</style>

<script>
import { WEBINARS_NAME } from '@/constants/webinars';

export default {
    data: () => {
        return {
            categories: WEBINARS_NAME
        }
    },
    methods: {
        selectCategory(category) {
            this.$router.push({ path: `/webinars/${category.path}` });
        }
    },
}
</script>