<template>
    <div class="containerSection full-width">
        
        <!-- MODAL PLAN -->
        <a-modal
            class="containerPlan"
            v-model="showPlan"
            :dialog-style="{ top: '10px' }"
            :footer="null"
            :bodyStyle="stylesForm"
        >
            <Plan @changeStateModal="(value) => changeStateModal(value)" />
        </a-modal>


        <a-row class="full-width full-height">
            <a-col :span="12" :md="12" :sm="24" :xs="24" class="containerLeft full-height">
                <div class="maskImage" />
                <img :src="apportersImage" alt="icon-apporters" class="imageSection" />
                <div class="textSection full-width">
                    <span class="title  cl--secondary-white full-width">
                        {{ $t('HOME.SECTIONS.INSTITUTIONAL_SUPPORTERS.DESCRIPTION') }} 
                    </span>
                </div>
                <span class="title title--level-3 cl--secondary-white full-width textSection">
                    {{ $t('HOME.SECTIONS.INSTITUTIONAL_SUPPORTERS.SUB_DESCRIPTION') }} 
                </span>
                <span 
                    class="title title--level-3 cl--primary-blue link"
                    @click="changeStateModal(true)"
                >
                    {{ $t('HOME.SECTIONS.INSTITUTIONAL_SUPPORTERS.LINK') }} 
                </span>
            </a-col>
            <a-col :span="12" :md="12" :sm="24" :xs="24" class="bg--secondary-white containerRight">
                <a-tabs :default-active-key="defaultTab" class="full-width" style="height: 100%">
                    <a-tab-pane 
                        :key="tab.key"
                        v-for="tab in tabs"
                        class="containerTab"
                    >
                        <span slot="tab" class="title text--level-3">
                            {{ $t(`${tab.name}`) }}
                        </span>
                        <div class="containerLogos">
                            <a-row :gutter="[16,16]">
                                <a-col
                                    v-for="item in lineBackers[tab.keyName].documents"
                                    :key="item._id"
                                    :span="8"
                                    class="flex-column center-column-center lineBeaker"
                                    @click="goToLink(item.externalLink)"
                                >
                                    <img
                                        :src="item.image.original"
                                        :alt="item.name"
                                        style="width: 80px; height: 80px; object-fit: contain"
                                    >
                                </a-col>
                            </a-row>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { IMAGES } from '@/constants/images';
import Plan from "@/components/home/Plan";
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => {
        return {
            apportersImage: IMAGES.home.apporters,
            defaultTab: 0,
            tabs: [
                {
                    key: 0,
                    name: "HOME.SECTIONS.INSTITUTIONAL_SUPPORTERS.SUPPORTERS.IES",
                    keyName: "IES"
                },
                {
                    key: 1,
                    name: "HOME.SECTIONS.INSTITUTIONAL_SUPPORTERS.SUPPORTERS.NETWORKS",
                    keyName: "NETWORK"
                },
                {
                    key: 2,
                    name: "HOME.SECTIONS.INSTITUTIONAL_SUPPORTERS.SUPPORTERS.PRIVATE_INITIATIVES",
                    keyName: "PRIVATE_INITIATIVE"
                }
                
            ],
            showPlan: false,
            stylesForm: {
                padding: '0',
                borderRadius: '8px !important'
            },
        };
    },
    
    async created() {
        await this.initialData();
    },

    computed: {
        ...mapGetters("landing", {
			lineBackers: "getLineBackers",
		}),
    },

    components: {
        Plan
    },

    methods: {
        ...mapActions("landing", {
			getLineBackersStore: "getLineBackers",
		}),

        async initialData() {
            await this.getLineBackersStore();
        },

        changeStateModal(value) {
            this.showPlan = value
        },

        goToLink(url) {
            window.open(url, '_blank');
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';

.containerRight {
    padding: 0 !important;
    height: 400px !important;

    @include breakpoint(md) {
        height: 100%;
    }

    :global(.ant-tabs-content) {
        height: calc(100% - 60px) !important;
    }

    .containerTab {
        height: 100%;
    }

    .containerLogos {
        height: 290px;
        overflow-y: scroll;

        @include breakpoint(md) {
            height: 340px;
        }
    }

    .lineBeaker {
        padding: .5px;
        cursor: pointer;
        transition: all 0.15 ease-in;

        &:active {
            transform: scale(0.96);
            transition: all 0.15 ease-in-out;
        }

        &:hover {
            background-color: $color-grey-alt-1;
            border-radius: 2px;
        }
    }
}

</style>