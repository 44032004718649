<template>
    <div class="register flex-column start-column-center bg--primary-white">
        
        <a-space :size="48" class="full-width" direction="vertical">
            <a-space :size="16" class="full-width" direction="vertical">
                <a-avatar
                    :src="logo"
                    class="rLogo"
                    shape="square"
                    alt="logo-rectoral-board"
                />

                <span class="title title--level-2 cl--primary-blue full-width center" v-if="!userRegistered">
                    {{ $t('REGISTER.HEADER') }}
                </span>
            </a-space>

            <div class="containerSteps flex-column start-column-center">
                <div v-if="userRegistered">
                    <a-space direction="vertical" :size="16" class="full-width">
                        <a-avatar
                            :src="iconSuccess"
                            class="iconSuccess"
                            style="margin: 0 auto; display: block"
                            shape="square"
                            alt="icon-success"
                            :size="220"
                        />

                        <span class="cl--primary-blue title title--level-2 center full-width">
                            {{ $t('WELCOME_TO')}}
                        </span>
                        <span class="cl--primary-blue title title--level-3 center full-width">
                            {{ $t('NO_SERVICE')}}
                        </span>
                        <span class="cl--primary-blue text text--level-2 center full-width">
                            {{ $t('THANK_YOU')}}
                        </span>
                        
                        <a-button
                            type="primary"
                            class="btn btn--primary-blue"
                            style="margin: 0 auto; display: block"
                            @click="() => this.$router.push({ path: '/' })"
                        >
                            {{ $t('GO_HOME')}}
                        </a-button>
                    </a-space>
                </div>
                <div class="contentStep flex-column start-column-center" v-else>
                    <a-alert :message="error" type="error" v-if="error" closable style="margin-bottom: 32px"/>
                    <a-form
                        class="full-width flex-column start-column-center"
                        :form="form"
                        @submit="saveUser"
                    >
                        <a-row justify="space-between" align="top" :gutter="[24, 48]" class="full-width">
                            <a-col :xs="24" :md="12">

                                <span class="title title--level-3 cl--primary-blue center">
                                    {{ $t(personalInformation) }}
                                </span>

                                <div class="picture" :style="getStylePreviewPicture()">

                                    <input
                                        type="file"
                                        ref="profile_picture"
                                        accept="image/png, image/jpg, image/jpeg, image/webp"
                                        @change="changePictureInput"
                                    />

                                    <font-awesome-icon
                                        :icon="['fas', 'camera-retro']"
                                        class="icon"
                                        @click="makeClickInputFile('profile_picture')"
                                    />

                                    <div v-if="picture.crop" class="crop">
                                        <cropper
                                            ref="cropper"
                                            class="cropper"
                                            :src="picture.img"
                                            :stencilComponent="getStencil()"
                                            :stencilProps="{
                                                minAspectRatio: 10 / 10,
                                                maxAspectRatio: 10 / 10,
                                            }"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'save']"
                                            class="button_crop shadow"
                                            @click="crop"
                                        />
                                    </div>
                                </div>

                                

                                <a-form-item :label="$t('FORMS.NAMES')">
                                    <a-input
                                        v-decorator="[
                                            'names',
                                            {
                                                rules: [{
                                                    required: true,
                                                    message: $t('FORMS.REQUIRED'),
                                                }],
                                            },
                                        ]"
                                        :placeholder="$t('FORMS.NAMES')"
                                    />
                                </a-form-item>

                                <a-form-item :label="$t('FORMS.SURNAMES')">
                                    <a-input
                                        v-decorator="[
                                            'surnames',
                                            {
                                                rules: [{
                                                    required: true,
                                                    message: $t('FORMS.REQUIRED'),
                                                }],
                                            },
                                        ]"
                                        :placeholder="$t('FORMS.SURNAMES')"
                                    />
                                </a-form-item>

                                <CountrySelector
                                    @changeCountry="(value) => changeCountry(value)"
                                />
                                <a-form-item :label="$t('FORMS.EMAIL')">
                                    <a-input
                                        v-decorator="[
                                            'email',
                                            {
                                                rules: [{
                                                    required: true,
                                                    message: $t('FORMS.REQUIRED'),
                                                }],
                                            },
                                        ]"
                                        placeholder="email@gmail.com"
                                    />
                                </a-form-item>
                                <a-form-item :label="$t('FORMS.PASSWORD')">
                                    <a-input-password
                                        v-decorator="[
                                            'password',
                                            {
                                                rules: [{
                                                    required: true,
                                                    message: $t('FORMS.REQUIRED'),
                                                }],
                                            },
                                        ]"
                                    >
                                        <template #prefix>
                                            <LockOutlined class="site-form-item-icon" />
                                        </template>
                                    </a-input-password>
                                </a-form-item>

                            </a-col>
                            <a-col :xs="24" :md="12">

                                <span class="title title--level-3 cl--primary-blue center">
                                    {{ $t(institutionalInformation) }}
                                </span>
                                
                                <a-form-item :label="$t('FORMS.TITLE_ACADEMIC')">
                                    <a-input
                                        v-decorator="[
                                            'title',
                                            {
                                                rules: [{
                                                    required: true,
                                                    message: $t('FORMS.REQUIRED'),
                                                }],
                                            },
                                        ]"
                                        :placeholder="$t('FORMS.TITLE_ACADEMIC')"
                                    />
                                </a-form-item>
    
    
                                <a-form-item :label="$t('FORMS.CURRICULUM_FILE')">
                                    <a-textarea
                                        :rows="5"
                                        :maxLength="250"
                                        v-decorator="[
                                            'synthesis',
                                            {
                                                rules: [{
                                                    required: true,
                                                    message: $t('FORMS.REQUIRED'),
                                                }],
                                            },
                                        ]"
                                        :placeholder="$t('FORMS.CURRICULUM_FILE')"
                                    />
                                </a-form-item>
                                <a-form-item :label="$t('FORMS.CURRENT_JOB_POSITION')">
                                    <a-input
                                        v-decorator="[
                                            'position',
                                            {
                                                rules: [{
                                                    required: true,
                                                    message: $t('FORMS.REQUIRED'),
                                                }],
                                            },
                                        ]"
                                        :placeholder="$t('FORMS.CURRENT_JOB_POSITION')"
                                    />
                                </a-form-item>

                                <a-form-item :label="$t('FORMS.INSTITUTION_THAT_REPRESENT')">
                                    <a-input
                                        v-decorator="[
                                            'institution',
                                            {
                                                rules: [{
                                                    required: true,
                                                    message: $t('FORMS.REQUIRED'),
                                                }],
                                            },
                                        ]"
                                        :placeholder="$t('FORMS.INSTITUTION_THAT_REPRESENT')"
                                    />
                                </a-form-item>

                                <InstitutionSelector
                                    @changeInstituteType="(value) => changeInstituteType(value)"
                                />
                            </a-col>
                        </a-row>
                        <div class="containerButtons flex-row center-row-center">
                            <a-button
                                class="button"
                                type="primary"
                                html-type="submit"
                                :loading="loading"
                            >
                                {{ $t('ACTIONS.CONTINUE') }}
                            </a-button>
                        </div>
                    </a-form>
                </div>
            </div>
        </a-space>
    </div>
</template>

<script>
import { IMAGES } from "@/constants/images.js";
import { Cropper, CircleStencil } from "vue-advanced-cropper";
import { v4 as uuidv4 } from "uuid";
import { storage } from "@/config/firebase/storage";
import { registerUser } from "@/api/users"
import { Alert } from "ant-design-vue";

export default {
    name: "register",
    data: () => {
        return {
            logo: IMAGES.logos.horizontal_svg,
            personalInformation: "REGISTER.STEPS.PERSONAL_INFORMATION",
            institutionalInformation: "REGISTER.STEPS.INSTITUTIONAL_INFORMATION",
            countryCode: null,
            country: 'Ecuador',
            mobile: null,
            institution: 'IES_PUBLIC',
            pictureProfile: null,
            picture: {
              url: null,
              coordinates: null,
              canvas: null,
              value: null,
              crop: false,
              img: null,
              name: null,
            },
            loading: false,
            error: null,
            userRegistered: false,
            iconSuccess: IMAGES.icons.success,
        };
    },
    
    beforeCreate() {
		this.form = this.$form.createForm(this, { name: "personal" });
	},

    components: {
        Cropper,
        // eslint-disable-next-line vue/no-unused-components
        CircleStencil,
        "a-alert": Alert, 
    },

    methods: {
        saveUser(e) {
            e.preventDefault();
			this.form.validateFields(async (err, values) => {
				if (!err) {
                    const { membership } = this.$route.query;
                    const payload = {
                        ...values, 
                        picture: this.pictureProfile,
                        country: this.country,
                        institutionType: this.institution,
                        category: membership,
                    }

                    const dataToEmail = {
                        membresia: membership,
                        name: payload.names,
                        surname: payload.surnames,
                        title: payload.title,
                        sintesis: payload.synthesis,
                        cargo: payload.position,
                        institucion: payload.institution,
                        caracterizacion: payload.institutionType,
                        pais: payload.country,
                        email: payload.email,
                        password: payload.password,
                    };

                    this.loading = true;

                    const response = await registerUser(payload);

                    if (response.code === 403) {
                        this.error = "¡Lo sentimos! Ya existe un usuario con el mismo email";
                        this.userRegistered = false;
                    } else {
                        const sendedMailUser = await this.sendMailUser(dataToEmail);
                        delete dataToEmail.password;
                        const sendedMail = await this.sendMail(dataToEmail);
                        this.userRegistered = true;
                        this.form.resetFields();
                    }
                    
                    this.loading = false;
				}
			});
        },

         sendMail(data) {
            return this.axios.post(
                "https://us-central1-standarrector.cloudfunctions.net/sendUsuarioToEmail",
                data
            );
        },

        sendMailUser(data) {
            return this.axios.post(
                "https://us-central1-standarrector.cloudfunctions.net/sendUsuarioEmail",
                data
            );
        },

        changeCountry(newCountry) {
            this.country = newCountry;
        },

        changeInstituteType(institutionType) {
            this.institution = institutionType;
        },

        makeClickInputFile: function (ref) {
            this.$refs[ref].click();
        },
        changePictureInput: function (e) {
            let file = e.target.files[0];
            this.picture.img = URL.createObjectURL(file);
            this.picture.crop = true;
            this.picture.name = `${uuidv4()}`;
        },
        getStencil: function () {
            return this.$options.components.CircleStencil;
        },
        crop: function () {
            const { coordinates, canvas } = this.$refs.cropper.getResult();
            this.picture.canvas = canvas;
            this.picture.coordinates = coordinates;
            this.picture.value = canvas.toDataURL();
            this.picture.crop = false;
            this.picture.img = null;
            this.uploadFileToStorage(async (url) => this.pictureProfile = url);
        },
        getStylePreviewPicture: function () {
            return this.picture.value !== null
                ? {
                    "background-color": "none",
                    "background-image":
                    "url(" + this.picture.value + ")",
                    "background-size": "100% 100%",
                }
                : {
                    "background-color": "#1976d2",
                };
        },
        uploadFileToStorage: function (cb) {
            let ref = storage.ref();
            const pictureData = this.picture;
            pictureData.canvas.toBlob(async function(blob) {
                let snapshot = await ref
                .child("profile/pictures/" + pictureData.name)
                .put(blob);
                let url = await snapshot.ref.getDownloadURL();
                cb(url);
            });
        },
    },
};
</script>

<style lang="scss">
@import "@/styles/scss/config.scss";

.register {
    width: 100%;
	height: 100vh;
	min-height: 100vh;
    padding: 48px 0;
    overflow-y: scroll;

    .rLogo {
        width: 150px;
        height: auto;
        margin: 0 auto;
        display: block;
    }

    .picture {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px auto;
        margin-bottom: 24px;

        input { display: none; }

        .icon {
            color: $color-white;
            font-size: 16px;
            cursor: pointer;

            &:active { transform: scale(0.98); }
        }


        .crop {
            position: absolute;
            width: 100%;
            top: 0;
            right: 0;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 30;

            .cropper {
                width: auto;
                max-width: 90%;
                max-height: 90%;
            }

            .button_crop {
                position: absolute;
                top: 1vw;
                right: 1vw;
                background: $color-primary;
                color: $color-white;
                width: 40px;
                height: fit-content;
                border-radius: 50%;
                font-size: 32px;
                padding: 12px;
                cursor: pointer;
            }

            .button_crop:active {
                transform: scale(0.98);
            }
        }
    }
}
</style>