<template>
    <div class="progressCard">
        <a-list-item-meta>
            <a-avatar
                slot="avatar"
                :src="iconCard"
                shape="square"
                :size="64"
            />

            <div slot="title">
                <a-space direction="vertical" :size="6" class="full-width">
                    <span class="title title--level-3 cl--secondary-white center full-width">
                        {{ $t(`PROGRESS.${card.type}`) }}
                    </span>
                    <span class="title title--level-1 cl--secondary-white center full-width">
                        {{ card.value }}
                    </span>
                </a-space>
            </div>
        </a-list-item-meta>
    </div>
</template>

<script>
import { IMAGES } from '@/constants/images'

export default {
	name: "ProgressCard",
	data: () => {
		return {}
    },
    props: {
        card: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        iconCard() {
            return IMAGES.icons[this.card.type.toLowerCase()]
        },
    }
}
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';
</style>