<template>
    <a-space direction="horizontal" :size="8" @click="goBack()">
        <a-icon type="arrow-left" :style="{ fontSize: '20px', color: color === 'dark' ?  '#1b4484' : '#FFF' }" />
        <span 
            class="title title--level-3 left"
            :style="{ color: color === 'dark' ?  '#1b4484' : '#FFF' }"
        >
            {{ $t('ACTIONS.BACK') }}
        </span>
    </a-space>
</template>

<style lang="scss">
@import "@/styles/scss/config.scss";
</style>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: ''
            },
            path: {
                type: String,
                default: ''
            }
        },
        methods: {
            goBack() {
                this.$router.push({ path: this.path });
            }
        }
    }
</script>