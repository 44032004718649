<template>
    <div class="mainContainer sections full-width full-height bg--secondary-white">
        <a-space direction="vertical" :size="40" class="full-width">
            <div class="flex-column start-column-start full-width header">
                <Back :color="'dark'" path="/ktdra-channel" />
            </div>

            <Loader :type="type" v-if="loaded"/>

            <div v-else>
                <a-space direction="vertical" :size="40" class="full-width">
                    <a-space direction="vertical" :size="8">
                        <span class="cl--primary-blue title title--level-1">
                            {{ article.title }}
                        </span>
    
                        <span class="cl--primary-blue title text--level-2">
                            {{ `${$t('KTDRA_CHANNEL.CATEGORY')} ${article.categoryID[language]}` }}
                        </span>
                    </a-space>


                    <a-row :justify="'space-between'" :align="'top'" class="adHeader" :gutter="[32, 24]">
                        <a-col :xs="24" :lg="12">
                            <a-avatar 
                                :src="article.image.original || portrait"
                                shape="square"
                                class="adAvatar"
                            />
                        </a-col>
                        <a-col :xs="24" :lg="12" class="adSummary">
                            <a-space direction="vertical" :size="8">
                                <span class="cl--primary-blue title text--level-2">
                                    {{ $t('KTDRA_CHANNEL.SUMMARY') }}
                                </span>
                                <span class="cl--primary-blue text text--level-3 textSummary" v-html="article.summary" />
                            </a-space>
                        </a-col>
                    </a-row>

                    <a-space direction="vertical" :size="8">
                        <span class="cl--primary-blue title text--level-2">
                            {{ $t('KTDRA_CHANNEL.CONTENT') }}
                        </span>
    
                        <span class="cl--primary-blue text text--level-3" v-html="article.content" />
                    </a-space>
                    
                    <a-row justify="space-between" align="middle" class="adAuthor full-width" :gutter="[16, 24]">

                        <a-col :span="12" class="full-height flex-column center-column-start">
                            <a-list-item-meta>
                                <a-space slot="title" :size="2" direction="vertical">
                                    <span
                                        class="title text--level-2 left cl--primary-blue"
                                    >
                                        {{ 
                                            $t(`${article.authorID.name} ${article.authorID.lastName}`) 
                                        }}
                                    </span>
                                    <span
                                        class="text text--level-3 left cl--primary-blue"
                                    >
                                        {{ `${article.authorID.academic.jobPosition || ''}` }}
                                    </span>
                                    <span
                                        class="text text--level-3 left cl--primary-blue"
                                    >
                                        {{ `${article.authorID.academic.institute || ''}` }}
                                    </span>
                                </a-space>
                                <a-avatar
                                    :src="article.authorID.image.original"
                                    slot="avatar"
                                    :size="56"
                                />
                            </a-list-item-meta>
                        </a-col>

                        <a-col :span="12" class="full-height flex-row end-row-center">
                            <a-space direction="horizontal" :size="12">
                                <a
                                    v-for="social in socialMedia"
                                    :key="social.name"
                                    :href="social.url"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <a-tooltip placement="bottom" >
                                        <span slot="title" v-if="!social.url || social.url === ''">
                                            {{ $t('KTDRA_CHANNEL.NO_LINK') }}
                                        </span>
                                        <a-icon
                                            :type="social.name" 
                                            :style="{ fontSize: '32px', color: '#1b4484' }"
                                        />
                                    </a-tooltip>
                                </a>
                            </a-space>
                        </a-col>
                    
                    </a-row>

                    <a-space direction="vertical" :size="8" v-if="article.externalLink || article.externalLink !== ''" class="full-width">
                        <span class="cl--primary-blue title text--level-2">
                            {{ $t('KTDRA_CHANNEL.COMPLETE_ARTICLE') }}
                        </span>

                        <a-row justify="space-between" align="middle" class="adAuthor full-width" :gutter="[16, 24]">
                            <a-col :span="12" class="full-height flex-column center-column-start">
                                <a-list-item-meta>
                                    <a-space slot="title" :size="2" direction="vertical">
                                        <span
                                            class="text text--level-3 left cl--primary-blue"
                                        >
                                            {{ `${$t('KTDRA_CHANNEL.PUBLISHED_IN')} ${article.dataSheet.source || ''}` }}
                                        </span>
                                        <span
                                            class="text text--level-3 left cl--primary-blue"
                                        >
                                            {{ `${$t('KTDRA_CHANNEL.PUBLISHED_YEAR')} ${article.dataSheet.publisher_year || ''}` }}
                                        </span>
                                    </a-space>
                                    <a-avatar
                                        :src="portrait"
                                        slot="avatar"
                                        class="adLink"
                                        :size="56"
                                    />
                                </a-list-item-meta>
                            </a-col>

                            <a-col :span="12" class="full-height flex-row end-row-center">
                                <span
                                    :class="getStyleTag(2)"
                                    class="adTag"
                                    @click="goToExternalLink(article.externalLink)"
                                    style="cursor: pointer;"
                                >
                                    {{ $t('KTDRA_CHANNEL.COMPLETE_ARTICLE') }}
                                </span>
                            </a-col>
                        </a-row>
                    </a-space>
                    

                    <a-space direction="vertical" :size="8">
                        <span class="cl--primary-blue title text--level-2">
                            {{ $t('KTDRA_CHANNEL.COLLABORATORS') }}
                        </span>

                        <a-row justify="start" align="top" v-if="article.collaborators.length > 0">
                            <span
                                v-for="(collaborator, index) in article.collaborators"
                                :class="getStyleTag(index)"
                                :key="'collaborator_' + index"
                                class="adTag"
                            >
                                {{ `${collaborator.name} ${collaborator.lastName}` }}
                            </span>
                        </a-row>

                        <span
                            class="cl--primary-blue text text--level-3"
                            v-else
                        >
                            {{ $t('KTDRA_CHANNEL.NO_COLLABORATORS') }}
                        </span>
                    </a-space>

                    <a-space direction="vertical" :size="8">
                        <span class="cl--primary-blue title text--level-2">
                            Keywords
                        </span>

                        <a-row :justify="'start'" :align="'top'">
                            <span
                                v-for="(tag, index) in keyWords"
                                :class="getStyleTag(index)"
                                :key="'tag_' + index"
                                class="adTag"
                            >
                                {{ tag }}
                            </span>
                        </a-row>
                    </a-space>

                    <a-space direction="vertical" :size="8" style="width: 100%;">
                        <span class="cl--primary-blue title text--level-2">
                            {{ $t('KTDRA_CHANNEL.OPINIONS') }}
                        </span>

                        <a-space :size="4">
                            <span class="text cl--primary-blue title text--level-2">
                                {{ $t('KTDRA_CHANNEL.SET_YOUR_OPINION') }}
                            </span>
                            <span
                                class="cl--primary-blue title text--level-2"
                                style="cursor: pointer;"
                                @click="goToRegister()"
                            >
                                {{ $t('KTDRA_CHANNEL.CLICK_HERE') }}
                            </span>
                        </a-space>


                        <a-row :justify="'start'" :align="'top'" v-if="article.comments.length > 0">
                            <Comments />
                        </a-row>

                        <span class="cl--primary-blue title text--level-2" v-else>
                            {{ $t('KTDRA_CHANNEL.NO_OPINIONS') }}
                        </span>
                    </a-space>

                </a-space>
            </div>
        </a-space>
    </div>
</template>

<style lang="scss">
@import '@/styles/scss/config.scss';

.adAvatar {
    margin: 0 auto;
    display: block;
    width: 100%;
    height: 100%;
    
    @include breakpoint(md) {
        width: fit-content;
        height: 250px;
    }
}

.adHeader {
    min-height: 100%;
    max-height: 100%;
    overflow: hidden;

    @include breakpoint(md) {
        min-height: 250px;
        max-height: 250px;
    }

    .adSummary {
        height: 250px;
        overflow-y: scroll;
    }
}

.adTag {
    background: $color-primary;
    color: $color-white;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 4px 8px;
    padding-bottom: 2px;
    display: inline-flex;

    &.a { background: #4596d6; }
    &.b { background: rgb(52, 129, 189); }
    &.c { background: rgb(45, 113, 164); }
    &.d { background: rgb(68, 145, 207); }
    &.e { background: rgb(68, 145, 207); }
    &.f { background: #1976d2; }
    &.g {
        background: #fff;
        color: #1976d2;
    }
}

.adLink {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        object-fit: cover;
    }
}

</style>

<script>
import { mapActions, mapGetters } from "vuex";
import Comments from "./Comments";
import { IMAGES } from '@/constants/images'

export default {
    data: () => {
        return {
            portrait: IMAGES.backgrounds.article,
            loaded: true,
            type: 1,
            languageKeys: [
                'es',
                'en',
                'pt',
            ]
        }
    },
    async created() {
        await this.initialData();
    },

    computed: {
        ...mapGetters("landing", {
            article: "getArticleDetail",
        }),

        language () {
            const lngKey = this.$i18n.locale;
            return lngKey.toString().toUpperCase();
        },

        socialMedia () {
            const social = [
                {
                    name: "linkedin",
                    url: this.article.authorID.contact.socialMedia.LN
                },
                {
                    name: "twitter",
                    url: this.article.authorID.contact.socialMedia.TW
                },
                {
                    name: "facebook",
                    url: this.article.authorID.contact.socialMedia.FB
                },
                {
                    name: "instagram",
                    url: this.article.authorID.contact.socialMedia.IG
                },
            ];
            return social;
        },

        keyWords () {
            const keyWords = this.article.keyWords.split(",");
            return keyWords;
        }
    },

    components: {
        Comments,
    },

    methods: {
        ...mapActions("landing", {
            getArticleDetailStore: "getArticleDetail",
        }),

        async initialData() {
            const { articleID } = this.$route.params;
            if (articleID) {
                await this.getArticleDetailStore(articleID);
                this.loaded = false;
            }
        },

        getStyleTag(index) {
			return {
				a: index === 0,
				b: index === 1,
				c: index === 2,
				d: index === 3,
				e: index === 4,
				f: index === 5,
				g: index === 6
			}
		},

        goToExternalLink(link) {
            window.open(link, '_blank');
        },

        goToRegister() {
            const url = "https://dashboard.rectoralboard.com/sign-up";
            window.open(url, "_blank");
        },
    }
}
</script>