<template>
  <div>
    <Navbar
      :language_active="language_active"
      @updateLanguage="updateLanguage"
    ></Navbar>
    <Portada :language_active="language_active"></Portada>
    <div id="div_to_scrool" class="div_to_scrool"></div>

    <div
      id="bloguerProfile"
      class="bloguer-profile flex-column-center start-column-center"
    >
      <div
        class="full-size flex-column-center start-column-center"
        v-if="bloguer.exist"
      >
        <div class="header-profile flex-row-center start-row-start">
          <div class="middle-left flex-column-center start-column-start info">
            <div class="full-width flex-row-center start-row-center">
              <div class="middle-left flex-row-center start-row-center picture">
                <div
                  class="cont-picture-bloguer flex-row-center center-row-center"
                >
                  <img :src="bloguer.data.personal.picture" />
                  <img
                    :src="getFlag(bloguer.data.contact.country)"
                    class="flag"
                  />
                </div>
              </div>
              <div
                class="
                  middle-left
                  flex-column-center
                  start-column-center
                  personal
                "
              >
                <span class="names">
                  {{ bloguer.data.personal.names }}
                  {{ bloguer.data.personal.surnames }}
                </span>
                <span class="cargo wt">{{ bloguer.data.academic.cargo }}</span>
                <span class="category">
                  {{
                    labels.member[language_active] +
                    " " +
                    bloguer.data.type.name.toUpperCase()
                  }}
                </span>
              </div>
            </div>

            <span class="sintesis">{{ bloguer.data.academic.sintesis }}</span>
            <div class="flex-row-center start-row-center">
              <span class="theme"
                >{{ labels.theme[language_active] + ":" }}

                <span
                  class="wt"
                  v-if="bloguer.data.services[1].items[0].asnwer !== ''"
                >
                  {{ " " + bloguer.data.services[1].items[0].asnwer }}
                </span>
                <span class="wt" v-else>{{
                  " " + labels.no_theme[language_active]
                }}</span>
              </span>
            </div>
          </div>

          <div class="middle-right flex-column-center start-column-start vcard">
            <span class="share-title" style="display: none">
              {{ labels.share_title[language_active] }}
            </span>
            <div
              class="cont-qr flex-column-center center-column-center"
              style="display: none"
            >
              <qrcode-vue
                class="qr full-size"
                :value="getProfile()"
                :size="qr.size"
                :foreground="qr.fore"
                :background="qr.back"
                level="Q"
              ></qrcode-vue>
            </div>
            <div
              class="flex-row-center start-row-center social"
              style="display: none"
            >
              <a
                target="_blank"
                :href="bloguer.data.contact.social.fb"
                v-show="bloguer.data.contact.social.fb !== ''"
              >
                <font-awesome-icon :icon="['fab', 'facebook']" class="icon" />
              </a>

              <a
                target="_blank"
                :href="bloguer.data.contact.social.tw"
                v-show="bloguer.data.contact.social.tw !== ''"
              >
                <font-awesome-icon :icon="['fab', 'twitter']" class="icon" />
              </a>

              <a
                target="_blank"
                :href="bloguer.data.contact.social.li"
                v-show="bloguer.data.contact.social.li !== ''"
              >
                <font-awesome-icon :icon="['fab', 'linkedin']" class="icon" />
              </a>

              <a
                :href="'mailto:' + bloguer.data.account.email"
                v-show="bloguer.data.account.email !== ''"
              >
                <font-awesome-icon :icon="['fas', 'envelope']" class="icon" />
              </a>

              <span class="share" @click="shareProfile">{{
                labels.share[language_active]
              }}</span>
            </div>

            <span class="copied-profile" v-if="qr.copied">
              {{ labels.profile_copy[language_active] }}
            </span>
          </div>
        </div>

        <div class="flex-row-center center-row-start full-width">
          <div class="middle-left">
            <div v-if="bloguer.articles.length <= 0">
              <Important
                :message="labels.no_articles[language_active]"
              ></Important>
            </div>

            <div class="flex-column-center start-column-center" v-else>
              <div class="slider flex-column-center start-column-center">
                <div
                  :key="s.key"
                  v-for="s in slider.slides.items"
                  :class="getClassSlideItem(s.index)"
                >
                  <div
                    :key="'article_' + pos"
                    v-for="(i, pos) in s.item"
                    :class="getClassItem(pos)"
                    v-show="i !== null"
                    @click="viewArticle(i.id)"
                  >
                    <div
                      class="flex-column-center start-column-center full-size"
                      v-if="i !== null"
                    >
                      <div class="cont-img">
                        <div class="mask"></div>
                        <img
                          :src="i.data.url_image"
                          v-if="i.data.url_image !== ''"
                        />
                        <img src="@/assets/image/blog/backarticle.png" v-else />
                        <span class="category">{{ i.data.category }}</span>
                      </div>
                      <div
                        class="cont-text flex-column-center start-column-start"
                      >
                        <span class="title">{{ i.data.title }}</span>
                        <span class="date">{{
                          getFormatDate(i.data.date)
                        }}</span>
                        <span class="line"></span>
                        <div class="flex-row-center start-row-center author">
                          <div
                            class="
                              middle-left
                              flex-column-center
                              center-column-center
                            "
                          >
                            <img :src="getFlag(i.data.country)" class="flag" />
                          </div>
                          <div
                            class="
                              middle-right
                              flex-column-center
                              start-column-center
                            "
                          >
                            <span class="title name"
                              >{{ i.data.name }} {{ i.data.surname }}</span
                            >
                            <span class="date email">{{ i.data.email }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="buttons flex-row-center center-row-center">
                <div
                  :key="btn.key"
                  v-for="btn in slider.slides.buttons"
                  :class="getClassButton(btn.index)"
                  @click="changeActiveSection(btn.index)"
                ></div>
              </div>
            </div>
          </div>
          <div class="middle-right">
            <div class="rightbar" id="rightbar">
              <!-- Show all -->
              <span
                class="show-all flex-row-center center-row-center"
                @click="resetSlider()"
              >
                {{ labels.all[language_active] }}
              </span>

              <!-- Search by article -->
              <div class="search-by-article flex-row-center">
                <div class="input_buscar flex-row-center" @keyup="checkSize()">
                  <font-awesome-icon :icon="['fas', 'search']" class="icon" />
                  <input
                    type="text"
                    v-model="search.query"
                    :placeholder="search.placeholder[language_active]"
                  />
                </div>
                <div
                  class="button_buscar flex-row-center center-row-center"
                  @click="searchArticleByTitle()"
                >
                  <span>{{ search.button[language_active] }}</span>
                </div>
              </div>

              <!-- Search by Category -->
              <div class="search-by-category">
                <div class="title-section flex-row-center start-row-center">
                  <span>{{ categories.title[language_active] }}</span>
                  <span class="line"></span>
                </div>
                <div class="list-categories">
                  <div
                    :class="
                      categories.selected === category.id
                        ? 'active category flex-row-center start-row-center'
                        : 'category flex-row-center start-row-center'
                    "
                    v-for="category in categories.list"
                    :key="category.id"
                    @click="searchByCategory(category.id, category.data.name)"
                  >
                    <span class="name">{{ category.data.name }}</span>
                    <font-awesome-icon :icon="['fas', 'fire']" class="icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex-row-center center-row-center full-width">
        <Important :message="labels.exist_bloguer[language_active]"></Important>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/app.scss";
@import "@/assets/css/components/ktdra/bloguer_profile.scss";
@import "@/assets/css/components/ktdra/rightbar.scss";
@import "@/assets/css/components/ktdra/articles.scss";
</style>

<script>
import { db } from "../../../config/firebase/db";
import json from "../../../assets/docs/countries.json";
import moment from "moment";
import QrcodeVue from "qrcode.vue";

import Important from "@/components/Important";
import Navbar from "../../Navbar";
import Portada from "./Portada";
import Footer from "../../Footer";

export default {
  name: "BloguerProfile",
  data: function () {
    return {
      language_active: 0,
      section_active: 0,

      bloguer: {
        exist: false,
        id: null,
        data: null,
        articles: [],
        backup_articles: [],
      },

      categories: {
        list: [],
        selected: null,
        title: ["Categorías", "Categories", "Categorias"],
      },

      slider: {
        size: 0,
        slides: {
          active: 0,
          buttons: [],
          items: [],
        },
      },

      search: {
        query: null,
        button: ["Buscar", "Search", "Procurar"],
        placeholder: [
          "Buscar artículo",
          "Search article",
          "Artigo de pesquisa",
        ],
      },

      labels: {
        all: ["Ver todos", "See all", "Mostre tudo"],
        exist_bloguer: [
          "Obteniendo información del bloguer",
          "Getting information from the blogger",
          "Obtendo informações do blogger",
        ],

        no_articles: [
          "No se han publicado artículos",
          "No articles have been published",
          "Nenhum artigo foi publicado",
        ],

        member: [
          "Miembro signatario de categoría",
          "Category Signatory Member",
          "Membro Signatário da Categoria",
        ],
        theme: ["Tema para el blog", "Blog theme", "Tema de blog"],
        no_theme: [
          "No se ha difinido tema para el blog",
          "No topics have been defined for the blog",
          "Nenhum tópico foi definido para o blog",
        ],

        share_title: [
          "Compartir Perfil",
          "Share profile",
          "Compartilhar perfil",
        ],
        share: ["Compartir", "Share", "Compartilhar"],

        profile_copy: ["Enlace copiado", "Copied link", "Link copiado"],
      },

      qr: {
        size: 120,
        fore: "#1976d2",
        back: "#ECF2FB",
        copied: false,
        profile: null,
      },
    };
  },

  components: {
    Navbar,
    Portada,
    Important,
    QrcodeVue,
    Footer,
  },
  beforeMount() {
    this.getCategories();
    this.getBloguer();
  },
  methods: {
    updateLanguage: function (index) {
      this.language_active = index;
    },

    formatNameToParams: function (names, surnames) {
      let newName = names + " " + surnames;
      let formatedName = newName
        .split(" ")
        .join("-")
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return formatedName;
    },

    getBloguer: async function () {
      let name_bloguer = this.$route.params.name;
      let bloguers = await db.collection("users").get();
      bloguers.forEach(async (bloguer) => {
        let name_formated = await this.formatNameToParams(
          bloguer.data().personal.names,
          bloguer.data().personal.surnames
        );
        if (name_formated === name_bloguer) {
          this.bloguer.id = bloguer.id;
          this.bloguer.data = bloguer.data();
          this.bloguer.exist = true;
          this.getArticles();
        }
      });
    },

    getArticles: async function () {
      let articles = await db
        .collection("articles")
        .where("id", "==", this.bloguer.id)
        .where("active", "==", true)
        .get();
      if (!articles.empty) {
        articles.forEach((article) => {
          this.bloguer.articles.push({
            id: article.id,
            data: article.data(),
          });
        });
      }
      this.bloguer.backup_articles = this.bloguer.articles;
      this.orderArticles();
    },

    getCategories: async function () {
      let data = await db.collection("category").get();
      data.forEach((ct) => {
        this.categories.list.push({
          id: ct.id,
          data: ct.data(),
          name: ct.data().name,
        });
      });

      let aux = this.removeDuplicates(this.categories.list, "name");
      this.categories.list = aux;
    },

    removeDuplicates(originalArray, prop) {
      var newArray = [];
      var lookupObject = {};

      for (var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
      }

      for (i in lookupObject) {
        newArray.push(lookupObject[i]);
      }
      return newArray;
    },

    orderArticles: function () {
      let prueba = this.bloguer.articles;
      prueba.sort(function (a, b) {
        if (a.data.date < b.data.date) {
          return 1;
        }
        if (a.data.date > b.data.date) {
          return -1;
        }
        return 0;
      });
      this.bloguer.articles = [];
      this.bloguer.articles = prueba;
      this.loadInSlider(this.bloguer.articles, 6);
    },

    loadInSlider: function (list, total) {
      this.slider.size = list.length;
      let indices = 0;
      for (let i = 0; i < this.slider.size; i += total) {
        let aux = {
          key: "slide_" + i,
          index: indices,
          item: [],
        };
        for (let j = 0; j < total; j++) {
          aux.item.push(i + j < this.slider.size ? list[i + j] : null);
        }
        this.slider.slides.items.push(aux);
        indices += 1;
      }
      for (let j = 0; j < this.slider.slides.items.length; j++) {
        this.slider.slides.buttons.push({
          key: "button_" + j,
          index: j,
        });
      }
    },

    getClassSlideItem: function (index) {
      return {
        slide: true,
        active: this.slider.slides.active === index,
        "flex-row-center": true,
        "start-row-start": true,
      };
    },

    getClassItem: function (index) {
      return {
        shadow: true,
        item: true,
        "flex-row-center": true,
        "start-row-center": true,
        a: index === 0,
        b: index === 1,
        c: index === 2,
        d: index === 3,
        e: index === 4,
        f: index === 5,
      };
    },

    changeActiveSection: function (index) {
      this.slider.slides.active = index;
    },

    getClassButton: function (index) {
      return {
        shadow: true,
        button: true,
        active: this.slider.slides.active === index,
      };
    },

    /* PROFILE */

    getProfile: function () {
      let domain = "https://rectoralboard.com";
      let profile = domain + this.$route.fullPath;
      this.qr.profile = profile;
      return profile;
    },

    shareProfile: function () {
      if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
      ) {
        var textarea = document.createElement("textarea");
        textarea.textContent = this.qr.profile;
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.select();
        try {
          this.qr.copied = true;
          setTimeout(() => {
            this.qr.copied = false;
          }, 2000);
          return document.execCommand("copy");
        } catch (ex) {
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },

    getFormatDate: function (date) {
      return moment(date.toDate()).format("MMM DD, YYYY");
    },

    getFlag: function (country) {
      country = country.toLowerCase();
      let countries = json;
      let flag;
      let nativo;
      let nombre;
      countries.forEach((c) => {
        nativo = c.nativeName.toLowerCase();
        nombre = c.name.toLowerCase();
        if (country === nativo || country === nombre) {
          // flag = `https://www.countryflags.io/${c.alpha2Code}/flat/64.png`;
          flag = `/assets/flagsx60-webp/${c.alpha2Code.toLowerCase()}.webp`;
          // flag = c.flag;
        }
      });
      return flag;
    },

    /* Search */

    resetSlider: function () {
      this.categories.selected = null;
      this.cleanSlider();
      this.loadInSlider(this.bloguer.backup_articles, 6);
    },

    cleanSlider: function () {
      this.slider = {
        size: 0,
        slides: {
          active: 0,
          buttons: [],
          items: [],
        },
      };
    },

    checkSize: function () {
      if (this.search.query === null || this.search.query.length === 0) {
        this.resetSlider();
      } else {
        this.searchArticleByTitle();
      }
    },

    searchArticleByTitle: function () {
      let aux = [];
      this.bloguer.articles.forEach((article) => {
        if (
          this.search.query === null ||
          this.search.query.length === 0 ||
          this.verifyTitle(article)
        ) {
          aux.push(article);
        }
      });

      let indices = 0;
      this.slider.slides.items = [];

      for (let i = 0; i < aux.length; i += 6) {
        let item = {
          key: "slide_" + i,
          index: indices,
          item: [],
        };
        for (let j = 0; j < 6; j++) {
          item.item.push(i + j < aux.length ? aux[i + j] : null);
        }
        this.slider.slides.items.push(item);
        indices += 1;
      }

      this.slider.slides.buttons = [];

      for (let j = 0; j < this.slider.slides.items.length; j++) {
        this.slider.slides.buttons.push({
          key: "button_" + j,
          index: j,
        });
      }
    },

    verifyTitle: function (article) {
      return article.data.title
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          this.search.query
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
    },

    searchByCategory: function (id, category) {
      this.bloguer.articles = this.bloguer.backup_articles;
      if (this.categories.selected === id) {
        this.categories.selected = null;
        this.resetSlider();
      } else {
        this.categories.selected = id;
        let aux = [];
        this.bloguer.articles.forEach((article) => {
          if (article.data.category === category) {
            aux.push(article);
          }
        });
        this.cleanSlider();
        this.bloguer.articles = aux;
        this.orderArticles();
      }
    },

    getUrlParams: function (names, surnames) {
      let newName = names + " " + surnames;
      let formatedName = newName
        .split(" ")
        .join("-")
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return formatedName;
    },

    viewArticle: function (id) {
      let path = this.$route.fullPath;
      this.$router.push({ path: path + "/articles/" + id });
    },
  },
};
</script>
