<template>
    <div>
        <span slot="title" class="title title--level-3 cl--secondary-white bg--primary-blue full-width flex-row start-row-center headerContact">
            {{ $t('CONTACT.TITLE') }}
        </span>

        <div class="containerForm full-width full-height" style="padding: 24px">

            <a-form layout="vertical" @submit.prevent="sendContactEmail">

                <a-row :gutter="16" class="center-row-center">
                    <a-col :span="12" :md="12" :sm="24" :xs="24">
                        <a-form-item :label="`${$t('CONTACT.NAMES')}`">
                            <a-input
                                v-model="form.names"
                                :placeholder="`${$t('CONTACT.NAMES')}`"
                            >
                                <a-icon slot="prefix" type="user" />
                            </a-input>
                        </a-form-item>

                        <a-form-item :label="`${$t('CONTACT.PHONE')}`">
                            <a-input
                                v-model="form.phone"
                                :placeholder="`${$t('CONTACT.PHONE')}`"
                            >
                                <a-icon slot="prefix" type="phone" />
                            </a-input>
                        </a-form-item>

                    </a-col>
                    <a-col :span="12" :md="12" :sm="24" :xs="24">
                        <a-form-item :label="`${$t('CONTACT.EMAIL')}`">
                            <a-input
                                v-model="form.email"
                                :placeholder="`${$t('CONTACT.EMAIL')}`"
                            >
                                <a-icon slot="prefix" type="mail" />
                            </a-input>
                        </a-form-item>

                        <a-form-item :label="`${$t('CONTACT.TOPIC')}`">
                            <a-input
                                v-model="form.topic"
                                :placeholder="`${$t('CONTACT.TOPIC')}`"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-form-item :label="`${$t('CONTACT.MESSAGE')}`">
                    <a-textarea
                        v-model="form.message"
                        :placeholder="`${$t('CONTACT.MESSAGE')}`"
                        :auto-size="{ minRows: 3, maxRows: 6 }"
                    />
                </a-form-item>
            </a-form>
            
        </div>
        
        <div class="flex-row end-row-end full-width" style="padding: 0 24px 24px">
            <a-space direction="horizontal" :size="24">

                <a-button size="large" type="primary" ghost @click="changeStateModal(false)">
                    {{ $t('CONTACT.CANCEL') }}
                </a-button>

                <a-button :loading="loading" size="large" type="primary" html-type="submit" @click="sendContactEmail()">
                    {{ $t('CONTACT.SEND') }}
                </a-button>

            </a-space>
        </div>

    </div>
    

</template>

<script>
import * as api from "@/api/sends.js";

export default {
    data: () => {
        return {
            loading: false,
            form: {
                names: null,
                email: null,
                phone: null,
                topic: null,
                message: null,
            }
        };
    },

    methods: {
        changeStateModal(value) {
            this.$emit("changeStateModal", value)
        },

        async sendContactEmail() {
            this.loading = true
            const response = await api.sendEmailContact(this.form);
            console.log(response);
            this.resetForm()
            this.changeStateModal(false)
            this.loading = false
        },

        resetForm() {
            this.form = {
                names: null,
                email: null,
                phone: null,
                topic: null,
                message: null,
            }
        }
    }

};
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';

.headerContact {
    padding: 16px 24px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
</style>