<template>
	<div class="full-width full-height bg--secondary-white" style="position: relative">
        <!-- MODAL CONTACT -->
        <a-modal 
            class="containerContact"
            v-model="showModal"
            :closable="false"
            :footer="null"
            :bodyStyle="stylesForm"
        >
            <Contact @changeStateModal="(value) => changeStateModal(value)"/>
        </a-modal>

        <div class="mainContainer containerFooter">
            <a-row :gutter="[8,40]" class="center-row-center">
                <a-col :span="8" :xl="8" :lg="8" :md="24" :sm="24" :xs="24">    
                    <a-space direction="vertical" :size="16">
                        <span class="uppercase title tp-bold text--level-2 cl--primary-blue">
                            {{ $t('FOOTER.INFORMATION') }}
                        </span>
                        <a-space direction="vertical" :size="8">
                            <span class="text text--level-2 cl--primary-blue tp-regular">
                                {{ information.email }}
                            </span>
                            <span class="text text--level-2 cl--primary-blue tp-regular">
                                {{ information.phone }}
                            </span>
                            <span class="text text--level-2 cl--primary-blue tp-regular">
                                {{ information.direction }}
                            </span>
                        </a-space>
                        <a-space direction="horizontal" :size="12">
                            <a
                                v-for="social in information.social"
                                :key="social.name"
                                :href="social.url" 
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <a-icon
                                    :type="social.name" 
                                    :style="{ fontSize: '32px', color: '#1b4484' }"
                                />
                            </a>
                        </a-space>

                        <a-button
                            type="primary"
                            size="large"
                            icon="mail"
                            style="padding: 6px 16px"
                            @click="changeStateModal(true)"
                        >
                            {{ $t('FOOTER.WRITE_US') }}
                        </a-button>
                    </a-space>         

                </a-col>
                <a-col :span="16" :xl="16" :lg="16" :md="24" :sm="24" :xs="24">         
                    <a-row :gutter="[8,40]" class="center-row-center">
                        <a-col :span="12" :md="12" :sm="24" :xs="24">    

                            <div class="containerFrame fb">
                                <iframe
                                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Frectoralboardinstitute&tabs=timeline&height=400&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=252604422894146"
                                    width="100%"
                                    height="400"
                                    style="border:none; overflow: scroll; margin: 0 auto"
                                    scrolling="no"
                                    frameborder="0"
                                    allowfullscreen="true"
                                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                ></iframe>
                            </div>         
                        </a-col>
                        <a-col :span="12" :md="12" :sm="24" :xs="24">   
                            <div class="containerFrame tw" id="twitter">      
                                <!-- <Timeline id="RectoralBoard" sourceType="profile" :options="{ tweetLimit: '10' }"/> -->
                                <a
                                    class="twitter-timeline"
                                    href="https://twitter.com/RectoralBoard?ref_src=twsrc%5Etfw"
                                    data-tweet-limit="10"
                                    data-width="100%"
                                    data-height="400"
                                    data-chrome="nofooter noborders"
                                    data-dnt="true" 
                                >Tweets by RectoralBoard</a>
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
        </div>
        <CopyRight/>
	</div>
</template>

<script>
import { Timeline } from 'vue-tweet-embed';
import Contact from "@/components/home/Contact";
  
export default {
	name: "Footer",
	data: function() {
		return {
            stylesForm: {
                padding: '0',
                borderRadius: '8px !important'
            },
            showModal: false,
            information: {
                email: "gabinete.rectoral@rectoralboard.com",
                phone: "Whats: +55 48 9 9127 1973",
                direction: "Sede: Centro de Inovação ACATE Primavera\nIlha de Santa Catarina\nImpact Hub Florianópolis SC - Brasil",
                social: [
                    {
                        name: "linkedin",
                        url: "https://www.linkedin.com/company/rectoralboardinstitute/"
                    },
                    {
                        name: "twitter",
                        url: "https://twitter.com/RectoralBoard?s=20"
                    },
                    {
                        name: "facebook",
                        url: "https://www.facebook.com/rectoralboardinstitute"
                    },
                    {
                        name: "instagram",
                        url: "https://www.instagram.com/rectoralboardinstitute/"
                    },
                    {
                        name: "youtube",
                        url: "https://www.youtube.com/channel/UCpMRSFSFp5eWEuxHEYuRzeA"
                    }
                ]
            },
		};
	},
	components: {
		Timeline,
        Contact
	},
	created() {
		document.body.style.overflow = "auto";
	},
    mounted() {
        const options = {
            sourceType: "profile",
            screenName: "RectoralBoard"

        };

        twttr.widgets.createTimeline(options, this.$refs.timelineContainer);
    },
    methods: {
        changeStateModal(value) {
            this.showModal = value
        }
    },
};
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';
@import '@/styles/scss/components/footer.scss';
</style>
