<template>
    <div class="flex-row center-row-start full-width containerFormPlan">
        <a-form 
            layout="vertical"
            @submit.prevent="sendLinebackerRequest"
            class="full-width full-height"
            :form="form"
        >

            <div class="sectionForm">
                <span class="title text--level-3 cl--secondary-white">
                    {{ $t('PLAN_LINEBACKER.IES_INFORMATION') }}
                </span>
            </div>

            <a-form-item :label="$t('FORMS.INSTITUTIONAL_NAME')">
                <a-input
                    v-decorator="[
                        'institutionalName',
                        {
                            rules: [{
                                required: true,
                                message: $t('FORMS.REQUIRED'),
                            }],
                        },
                    ]"
                    :placeholder="$t('FORMS.INSTITUTIONAL_NAME')"
                />
            </a-form-item>

            <a-form-item :label="$t('FORMS.COUNTRY')">
                <a-select default-value="Ecuador" class="full-width" @change="changeCountry">
                    <a-select-option 
                        v-for="(country, index) in countries"
                        :key="`country_${index}`"
                        :value="country.name"
                    >
                        {{ country.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item :label="$t('FORMS.EMAIL_INSTITUTIONAL')">
                <a-input
                    v-decorator="[
                        'institutionalEmail',
                        {
                            rules: [{
                                required: true,
                                message: $t('FORMS.REQUIRED'),
                            }],
                        },
                    ]"
                    :placeholder="$t('FORMS.EMAIL_INSTITUTIONAL')"
                />
            </a-form-item>

            <a-form-item :label="$t('FORMS.INSTITUTIONAL_TYPE')">
                <a-select default-value="IES_PUBLIC" class="full-width" @change="changeInstituteType">
                    <a-select-option 
                        v-for="institute in institutes"
                        :key="institute.key"
                        :value="institute.keyName"
                    >
                        {{ institute.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>

            <div class="sectionForm">
                <span class="title text--level-3 cl--secondary-white">
                    {{ $t('PLAN_LINEBACKER.MANAGER_INFORMATION') }}
                </span>
            </div>

            <a-form-item :label="$t('FORMS.NAMES')">
                <a-input
                    v-decorator="[
                        'names',
                        {
                            rules: [{
                                required: true,
                                message: $t('FORMS.REQUIRED'),
                            }],
                        },
                    ]"
                    :placeholder="$t('FORMS.NAMES')"
                />
            </a-form-item>

            <a-form-item 
                class="containerPhone flex-row center-row-center"
                :label="$t('FORMS.PHONE')"
            >
                <a-select 
                    default-value="+593" 
                    class="full-height code"
                    showSearch
                    optionFilterProp="children"
                    :filter-option="filterCountryCode"
                    @change="changeCountryCode"
                >
                    <a-select-option
                        v-for="(codeNumber, index) in codesNumber"
                        :key="`code_${index}_${codeNumber}`"
                        :value="codeNumber.code"
                        class="flex-row center-row-center full-height"
                    >
                        <a-avatar 
                            :src="codeNumber.flag" class="flagCode"
                            shape="square"
                        />
                        <span class="text text--level-3">{{ codeNumber.code }}</span>
                    </a-select-option>
                    
                </a-select>

                <a-input 
                    class="number"
                    placeholder="Ej. 987654321"
                    v-model="mobile"
                />
            </a-form-item>

            <a-form-item :label="$t('FORMS.EMAIL')">
                <a-input
                    v-decorator="[
                        'email',
                        {
                            rules: [{
                                required: true,
                                message: $t('FORMS.REQUIRED'),
                            }],
                        },
                    ]"
                    :placeholder="$t('FORMS.EMAIL')"
                />
            </a-form-item>

            <a-form-item :label="$t('FORMS.WORK_AREA')">
                <a-input
                    v-decorator="[
                        'workArea',
                        {
                            rules: [{
                                required: true,
                                message: $t('FORMS.REQUIRED'),
                            }],
                        },
                    ]"
                    :placeholder="$t('FORMS.WORK_AREA')"
                />
            </a-form-item>
            
            <a-button 
                :loading="loading" 
                size="large" 
                type="primary" 
                class="full-width" 
                htmlType="submit"
            >
                {{ $t('ACTIONS.SEND') }}
            </a-button>
        </a-form>
    </div>
</template>

<script>
import countries from "@/constants/countries.json";
import * as utils from '@/utils/';
import { institutesType } from '@/constants/institutesType.js';

    export default {
        data: () => {
            return {
                codesNumber: [],
                countries: [],
                countryCode: null,
                mobile: null,
                names: null,
                email: null,
                instituteType: null,
                institutionalName: null,
                institutionalEmail: null,
                country: null,
                institutes: institutesType,
            };
        
        },
        beforeCreate() {
		    this.form = this.$form.createForm(this, { name: "planRequest" });
	    },
        beforeMount() {
            this.loadCodesNumber();
            this.loadCountries();
        },
        methods: {
            loadCountries() {
                countries.map(country => {
                    this.countries.push(country)
                });
            },
            loadCodesNumber() {
                let aux = [];
                countries.map((country) => {
                    if (country.callingCodes[0] !== "") {
                        aux.push({
                            flag: country.flag,
                            code: `+${country.callingCodes[0]}`,
                            country: country.name
                        });
                    }
                });
                this.codesNumber = utils.removeDuplicates(aux, "code");
            },
            filterCountryCode(input, option) {
                return ( 
                    option.componentOptions.propsData.value.indexOf(input) >=0
                );
            },
            changeCountryCode(newCountryCode) {
                this.countryCode = newCountryCode;
            },
            changeInstituteType(newInstituteType) {
              this.instituteType = newInstituteType;
            },
            changeCountry(newCountry) {
                this.country = newCountry;
            },
            sendLinebackerRequest(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log("Received values of form: ", values);
                        this.$emit('nextStep');
                    }
                });
            } 
        },
    };
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';

.containerFormPlan {
    max-height: 600px;
    overflow-y: scroll;
    padding-bottom: 32px;
}

.sectionForm {
    background: $color-primary;
    padding: 8px 16px;
    border-radius: 8px;
    margin-bottom: 16px;
    width: 100%;
    color: $color-white;
}
</style>