<template>
    <div class="containerPortrait">
        <slider 
            animation="fade"
            :indicators=false
            :interval="6500"
            :touch="true"
            :control-btn=false
            height="100%"
            :style="getBackground()"
        >
            <slider-item
                v-for="(phrase, index) in welcomePhrases"
                :key="index"
                class="flex-column center-column-center containerSlide"
                style="right: 0;"
            >   
                <a-space :size="8" direction="vertical" class="full-width containerText">
                    <span class="title title--level-2 cl--secondary-white"> {{ `"${phrase.slogan.content}"` }} </span>
                    <img :src="phrase.flag" :alt="phrase.country" class="flag"/>
                    <span class="text text--level-3 cl--secondary-white"> {{ `${phrase.name} ${phrase.lastName}` }} </span>
                </a-space>

            </slider-item>
        </slider>

    </div>
</template>

<style lang="scss">
@import '@/styles/scss/config.scss';

.containerPortrait {
    height: 100vh;
    background: $color-primary-alt;
    transition: all .1s .1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    @include breakpoint(md) {
        height: 100vh;
    }
}

.containerSlide {
    max-width: 1059px;
    margin: 0 auto;
    
    span {
        max-width: 1059px;
        width: 85%;
        margin: 0 auto;
        text-align: center;
        display: block;
    }

    .containerText {
        align-items: center;  
        margin-bottom: -20px;

        @include breakpoint(md) {
            margin-bottom: 60px;
        }
    }

}

</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { IMAGES } from "@/constants/images"

export default {
    
    data: function () {
        return {
            params: {
                limit: 10,
                page: 1
            },
            vertical: 'vertical',
            horizontal: 'horizontal',
            activeImage: 0,
            images: [
                IMAGES.portrait.guide,
                IMAGES.portrait.quijote,
                IMAGES.portrait.person,
                IMAGES.portrait.graduate,
                IMAGES.portrait.cotopaxi,
                IMAGES.portrait.palace,
                IMAGES.portrait.horizont,
                IMAGES.portrait.books,
                IMAGES.portrait.city,
                IMAGES.portrait.celebrate,
            ]
        };
    },

    created() {
		  this.initialData();
    },
    
    computed: {
        ...mapGetters("landing", {
			welcomePhrases: "getDataWelcomePhrases",
		}),
    },

    methods: {
        ...mapActions("landing", {
			getWelcomePhrasesStore: "getWelcomePhrases",
		}),

    async initialData() {
			if (this.welcomePhrases.length === 0) await this.getWelcomePhrasesStore(this.params);
      window.setInterval(() => {
        if (this.activeImage < this.images.length - 1) {
            this.activeImage += 1;
            if (this.activeImage >= 10) {
                this.activeImage = 0;
            }
        } else {
            this.activeImage = 0;
        }
      }, 6500);
		},

        getBackground () {
            return {
                "background-image": "url(" + this.images[this.activeImage] + ")",
                "background-size": "cover",
                "background-repeat": "no-repeat",
                "background-position": "center center",
            };
        },
    }

};
</script>
