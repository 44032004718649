<template>
  <div class="shadow important" id="important">
    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="icon" />
    <span>{{ message }}</span>
  </div>
</template>

<style lang="scss" scoped>
#important {
  width: auto;
  padding: 0.8vw 2vw;
  background: #005ebd;
  color: #fff;
  font-family: gothan_medium;
  border-radius: 0.4vw;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  .icon {
    margin-right: 1vw;
  }
}
</style>

<script>
export default {
  props: {
    message: {
      type: String,
      default: ""
    }
  }
};
</script>