import axios from "axios";
import { DASHBOARD_API_URL } from "../../api-routes";

export async function createMentoringQuote(payload) {
    try {
        let url = `${DASHBOARD_API_URL}/quotes/mentoring`;
        let response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        return {
            statusCode: error.response.status,
            message: error.response.data.message
        }
    }
}

export async function createToolBoxQuote(payload) {
    try {
        let url = `${DASHBOARD_API_URL}/quotes/tool-box`;
        let response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        return {
            statusCode: error.response.status,
            message: error.response.data.message
        }
    }
}

export async function createLeadershipQuote(payload) {
    try {
        let url = `${DASHBOARD_API_URL}/quotes/leadership`;
        let response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        return {
            statusCode: error.response.status,
            message: error.response.data.message
        }
    }
}