<template>
    <div class="interviewersWebinar full-width">
        <div :class="`cover ${category.class}`"/>

        <a-space direction="vertical" :size="32" class="full-width containerInterviewers" v-if="interviewerID">
            <a-space direction="vertical" :size="16" class="full-width">
                <span :class="`title title--level-2 moderator ${category.class}`">
                    {{ $t('WEBINARS.INTERVIEWED') }}
                </span>

                <a-list :data-source="interviewerID">
                    <a-list-item slot="renderItem" slot-scope="item">
                        <a-list-item-meta>
                            <div class="containerImage" slot="avatar">
                                <a-avatar 
                                    :src="item.image.original" 
                                    :size="56"
                                    class="avatar" 
                                />
                            </div>
                            <span :class="`title title--level-3 tp-light moderator ${category.class}`" slot="title">
                                {{ `${item.name} ${item.lastName} - ${item.academic.jobPosition}` }}
                            </span>
                            <span :class="`title title--level-3 moderator ${category.class}`" slot="description">
                                {{ item.academic.jobPosition }}
                            </span>
                        </a-list-item-meta>

                        <a-space direction="vertical" :size="8" class="flex-column center-column-end">
                            <a-avatar 
                                :src="getFlag(item.country)"
                                class="avatar flag" 
                                shape="square"
                            />
                            <span class="title text--level-3 center">
                                {{ getNameCountry(item.country) }}
                            </span>
                        </a-space>
                    </a-list-item>
                </a-list>
            </a-space>

            <a-space direction="vertical" :size="16" class="full-width" v-if="moderatorIds">
                <span class="title title--level-2">
                    {{ $t('WEBINARS.MODERATOR') }}
                </span>

                <a-list :data-source="moderatorIds">
                    <a-list-item slot="renderItem" slot-scope="item">
                        <a-list-item-meta>
                            <div class="containerImage" slot="avatar">
                                <a-avatar 
                                    :src="item.image.original" 
                                    :size="56"
                                    class="avatar" 
                                />
                            </div>
                            <span class="title title--level-3 tp-light" slot="title">
                                {{ `${item.name} ${item.lastName}` }}
                            </span>
                            <span class="title title--level-3" slot="description">
                                {{ item.academic.jobPosition }}
                            </span>
                        </a-list-item-meta>

                        <a-space direction="vertical" :size="8" class="flex-column center-column-end">
                            <a-avatar 
                                :src="getFlag(item.country)"
                                class="avatar flag" 
                                shape="square"
                            />
                            <span class="title text--level-3 center">
                                {{ getNameCountry(item.country) }}
                            </span>
                        </a-space>
                    </a-list-item>
                </a-list>
                    
            </a-space>

            <a-space direction="vertical" :size="16" class="full-width" v-if="interviewers">
                <span class="title title--level-2">
                    {{ $t('WEBINARS.INTERVIEWERS') }}
                </span>

                <a-list :data-source="interviewers">
                    <a-list-item slot="renderItem" slot-scope="item">
                        <a-list-item-meta>
                            <div class="containerImage" slot="avatar">
                                <a-avatar 
                                    :src="item.image.original" 
                                    :size="56"
                                    class="avatar" 
                                />
                            </div>
                            <span class="title title--level-3 tp-light" slot="title">
                                {{ `${item.name} ${item.lastName}` }}
                            </span>
                            <span class="title title--level-3" slot="description">
                                {{ item.academic.jobPosition }}
                            </span>
                        </a-list-item-meta>

                        <a-space direction="vertical" :size="8" class="flex-column center-column-end">
                            <a-avatar 
                                :src="getFlag(item.country)"
                                class="avatar flag" 
                                shape="square"
                            />
                            <span class="title text--level-3 center">
                                {{ getNameCountry(item.country) }}
                            </span>
                        </a-space>
                    </a-list-item>
                </a-list>
            </a-space>
        </a-space>
    </div>
</template>

<style lang="scss">
@import "@/styles/scss/config.scss";
@import "@/styles/scss/views/categorySelection.scss";
</style>

<script>
import * as utils from '@/utils';
export default {
    props: {
        interviewerID: {
            type: Array,
            default: () => {}
        },
        interviewers: {
            type: Array,
            default: () => {}
        },
        moderatorIds: {
            type: Array,
            default: () => {}
        },
        category: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        getFlag (country) {
            return utils.getFlag(country);
        },
        getNameCountry (country) {
            return utils.getNameCountry(country);
        }
    }
}
</script>