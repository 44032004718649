export const TOOL_BOX_QUOTES = [
    {
        key: "PLANNING",
        name: "PLANNING",
        tools: [
            {
                item: "STRATEGIC_DIAGNOSTIC",
                value: false
            },
            {
                item: "COMPETITIVE_INTELLIGENCE",
                value: false
            },
            {
                item: "STRATEGIC_IDENTITY",
                value: false
            },
            {
                item: "STRATEGIC_FORMULATION",
                value: false
            },
                {
                item: "STRATEGIC_TRACKING",
                value: false
            },
            {
                item: "STRATEGIC_POSITIONING",
                value: false
            },
            {
                item: "STRATEGIC_IMPLEMENTATION",
                value: false
            },
            {
                item: "OTHERS",
                value: ""
            }
        ]
    },
    {
        key: "PROCESS",
        name: "PROCESS",
        tools: [
            {
                item: "PROCESS_MAPPING",
                value: false
            },
            {
                item: "PROCESS_ANALYSIS",
                value: false
            },
            {
                item: "PROCESS_MANAGEMENT",
                value: false
            },
            {
                item: "PROCESS_CHARACTERIZATION",
                value: false
            },
                {
                item: "PROCESS_VISION",
                value: false
            },
            {
                item: "PROCESS_REDESIGN",
                value: false
                },
            {
                item: "REGULATION_MANAGEMENT",
                value: false
            },
            {
                item: "OTHERS",
                value: ""
            }
        ]
    },
    {
        key: "PLATFORM",
        name: "PLATFORM",
        tools: [
            {
                item: "ERP_IMPLEMENTATION",
                value: false
            },
            {
                item: "BUSINESS_BLUE_PRINT",
                value: false
            },
            {
                item: "SPECIALIST_SYSTEMS",
                value: false
            },
            {
                item: "SHARED_SERVICES_CENTER",
                value: false
            },
                {
                item: "IT_MANAGEMENT_MODEL",
                value: false
            },
            {
                item: "ERP_SELECTION",
                value: false
                },
            {
                item: "PETI_CONSTRUCTION",
                value: false
            },
            {
                item: "OTHERS",
                value: ""
            }
        ]
    },
    {
        key: "PEOPLE",
        name: "PEOPLE",
        tools: [
            {
                item: "EXECUTIVE_COACHING",
                value: false
            },
            {
                item: "CLIMATE_ORGANIZATIONAL_CULTURE",
                value: false
            },
            {
                item: "CHANGE_MANAGEMENT_PLAN",
                value: false
            },
            {
                item: "STRUCTURES_REVIEW",
                value: false
            },
                {
                item: "SKILLS_DEVELOPMENT_PLAN",
                value: false
            },
            {
                item: "PERFORMANCE_EVALUATION",
                value: false
            },
            {
                item: "MERITOCRACY_PLAN",
                value: false
            },
            {
                item: "OTHERS",
                value: ""
            }
        ]
    },
    {
        key: "PROJECTS",
        name: "PROJECTS",
        tools: [
            {
                item: "STRATEGIC_ALLIANCES",
                value: false
            },
            {
                item: "UNESCO_CREATIVE_CITIES_DEVELOPMENT",
                value: false
            },
            {
                item: "SUSTAINABLE_DEVELOPMENT_GOALS",
                value: false
            },
            {
                item: "UNIVERSITY_INTERNATIONALIZATION",
                value: false
            },
                {
                item: "INNOVATION_ECOSYSTEMS",
                value: false
            },
            {
                item: "ENSINO_EAD_MODEL",
                value: false
                },
            {
                item: "QUALITY_MANAGEMENT_SYSTEMS",
                value: false
            },
            {
                item: "OTHERS",
                value: ""
            }
        ]
    },
]
