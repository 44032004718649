<template>
    <div class="mainContainer sections full-width full-height bg--secondary-white" style="position: relative">
        <a-space direction="vertical" :size="40" class="full-width">
            <Headers
                :showLine=headers.line
                :title=headers.title
                :description=headers.description
                align="left"
            />
            <Articles />
        </a-space>
    </div>
</template>

<script>
import Articles from "@/components/ktdra/Articles.vue";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            headers: {
                title: "HOME.CHANNEL_KTDRA.TITLE",
                description: "HOME.CHANNEL_KTDRA.DESCRIPTION",
                line: true,
            }
        }
    },
    components: { Articles },

    computed: {
        ...mapGetters("landing", {
            articles: "getArticles",
        }),
    }


}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
</style>