import axios from "axios";
import { API_URL } from "../../api-routes";
import queryString from 'querystring';

export async function getWebinarsCalendar() {
    try {
        let url = `${API_URL}/webinars/calendar`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}


export async function getWebinarsByCategory(params) {
    try {
        let url = `${API_URL}/webinars?${queryString.stringify(params)}`;
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        return {
            code: 404,
            message: `Error: ${error}`
        }
    }
}