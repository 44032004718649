<template>
    <div>
        <div :class="`mainContainer sections full-width full-height bg--secondary-white ${category.class} categorySelection`">
            <a-space direction="vertical" :size="40" class="full-width">
            
                <div class="flex-column start-column-start full-width header">
                    <a-space direction="vertical" :size="24">
                        <Back :color="category.name === 'LEGACY' ? 'light' : 'dark'" path="/webinars" />
                        <a-row justify="space-between" align="middle" :gutter="[24, 32]" class="noMargin full-width">
                            <a-col :xs="24" :lg="5" :xl="4" :xxl="3" class="flex-row start-row-center">
                                <a-avatar
                                    :src="category.logo"
                                    shape="square"
                                    :size="150"
                                />
                            </a-col>
                            <a-col :xs="24" :lg="19" :xl="20" :xxl="21" class="flex-row start-row-center">
                                <a-space direction="vertical" :size="16">
                                    <span class="cl--primary-blue title ">
                                        {{ $t(`WEBINARS.${category.name}.NAME`) }}
                                    </span>
                                    <span class="cl--primary-blue text text--level-2 justify">        
                                        {{ $t(`WEBINARS.${category.name}.DESCRIPTION`) }}
                                    </span>
                                </a-space>
                            </a-col>
                        </a-row>
                    </a-space>
                </div>

                <Loader :type="type" v-if="loaded"/>

                <a-space direction="vertical" :size="24" v-else class="full-width">

                    <span class="title title--level-1 cl--primary-blue">
                        {{ `${$t('WEBINARS.ALL')} (${webinarsByCategory.total})` }}
                    </span>

                    <a-collapse 
                        accordion
                        :bordered="false"
                        :class="category.class"
                        v-model="activeKey"
                    >
                        <a-collapse-panel 
                            v-for="item in webinarsByCategory.data"
                            :key="item.id"
                        >
                            <a-space slot="header" direction="horizontal" :size="24">
                                <span class="title title--level-3 cl--primary-blue">
                                    {{ `${$t('WEBINARS.CYCLE')} - ${item.tag} ${$t('WEBINARS.INTERVIEW')}`  }}
                                </span>
                                <span 
                                    v-if="isNext"
                                    class="title text--level-3 isNext"
                                >
                                    {{ $t('WEBINARS.IS_NEXT') }}
                                </span>
                            </a-space>
                            <a-icon
                                slot="extra"
                                type="arrows-alt"
                                :style="{ fontSize: '18px' }"
                                @click="goToWebinar(item.id)"
                            />
                            <Interviewers
                                :interviewerID="item.interviewerID"
                                :interviewers="item.interviewersIds"
                                :moderatorIds="item.moderatorIds"
                                :category="category"
                            />
                            <div class="full-width flex-column start-column-center">    
                                <Schedule
                                    :schedules="item.schedules"
                                    :date="item.date"
                                    :video="item.video"
                                    :category="category"
                                    :link="item.registerLink"
                                    :transmissions="item.transmissionChannels"
                                    :isNext="item.isNext"
                                />
                            </div>
                        </a-collapse-panel>
                    </a-collapse>
                </a-space>
            </a-space>
        </div>
    </div>
</template>

<style lang="scss">
@import "@/styles/scss/config.scss";
@import "@/styles/scss/views/categorySelection.scss";
</style>

<script>
import { WEBINARS_PATH } from '@/constants/webinars';
import { mapGetters, mapActions } from "vuex";
import { Collapse } from 'ant-design-vue';

import Presentation from '@/components/webinars/Presentation';
import Interviewers from '@/components/webinars/Interviewers';
import Schedule from '@/components/webinars/Schedule';

export default {
    data: () => {
        return {
            categories: WEBINARS_PATH,
            loaded: true,
            activeKey: ['0'],
            type: 1,
            params: {
                limit: 100,
                page: 1,
            }
        };
    },

    computed: {
        category() {
            const path = this.$route.params.category.toUpperCase();
            return this.categories[path];
        },

        typeCategory() {
            const path = this.$route.params.category.toUpperCase();
            return this.categories[path].id;
        },

        ...mapGetters("landing", {
			webinarsByCategory: "getWebinarsByCategory",
		}),
    },
    
    async created() {
        await this.initialData();
    },

    beforeDestroy() {
        this.clearWebinars();
    },
    
    components: {
        'a-collapse': Collapse,
        'a-collapse-panel': Collapse.Panel,
        Presentation,
        Interviewers,
        Schedule,
    },

    methods: {

        ...mapActions("landing", {
			getWebinarsByCategoryStore: "getWebinarsByCategory",
            clearWebinars: "clearWebinarsByCategory",
		}),

        async initialData() {
            const { data } = this.webinarsByCategory;
            if (!data.length) {
                const params = { ...this.params, category: this.typeCategory };
                await this.getWebinarsByCategoryStore(params);
                this.loaded = false;
            };
        },

        goToWebinar(index) {
            this.$router.push(`/webinars/${this.category.path}/${index}`);
        },
    },
}
</script>