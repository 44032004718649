<template>
    <div class="containerSection full-width">
        <a-row class="full-width full-height">
            <a-col :span="12" :md="12" :sm="24" :xs="24" class="containerLeft full-height">
                <div class="maskImage" />
                <img :src="external_schedule" alt="external_schedule" class="imageSection" />
                <div class="textSection full-width">
                    <span class="title  cl--secondary-white full-width">
                        {{ $t('HOME.SECTIONS.EXTERNAL_SCHEDULE.DESCRIPTION') }} 
                    </span>
                </div>
                <span class="title title--level-3 cl--secondary-white full-width textSection">
                    {{ $t('HOME.SECTIONS.EXTERNAL_SCHEDULE.SUB_DESCRIPTION') }} 
                </span>
            </a-col>
            <a-col :span="12" :md="12" :sm="24" :xs="24" class="containerRight">
                <Calendar/>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { IMAGES } from '@/constants/images';
export default {
    data: () => {
        return {
            external_schedule: IMAGES.home.external_schedule,
        };
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';

.containerRight {
    height: 400px !important;

    @include breakpoint(md) {
        height: 100%;
    }
}
</style>