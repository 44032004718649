<template>
    <div>
        <Navbar 
            @updateSection="updateSection" 
            @handleNavbarMobile="(value) => handleNavbarMobile(value)"
        />
        <NavbarMobile 
            :active="activeNavbarMobile"
            @updateSection="(value) => updateSection(value)"
            @close="() => activeNavbarMobile = false"
        />
        <div class="mainContainer sections full-width full-height bg--secondary-white">
            <a-row justify="space-around" align="middle" :gutter="[24, 32]" class="noMargin">
                <a-col :xs="24" :md="24" :lg="16">
                    <a-space direction="vertical" :size="24" class="full-width">
                        <Header/>
                    </a-space>
                </a-col>
                <a-col :xs="24" :md="24" :lg="8">
                    <Live />
                </a-col>
            </a-row>
            <div class="webinarsCategory flex-column start-column-start full-width">
                <a-row justify="space-around" align="middle" :gutter="[24, 32]" class="noMargin full-width">
                    <a-col :xs="24">
                        <Categories/>
                    </a-col>
                    <a-col :xs="24">
                        <Certificates />
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import "@/styles/scss/config.scss";
@import "@/styles/scss/views/webinars.scss";
</style>


<script>
import { mapGetters, mapActions } from "vuex";
import Header from "@/components/webinars/Header.vue";
import Categories from "@/components/webinars/Categories.vue";
import Live from '@/components/webinars/Live';
import Certificates from '@/components/webinars/Certificates';

import { scroller } from "vue-scrollto/src/scrollTo";

export default {
    components: {
        Header,
        Categories,
        Live,
        Certificates,
    },

    data: () => {
        return {
            loaded: true,
            activeNavbarMobile: false,
        };
    },
    computed: {
        ...mapGetters("landing", {
			webinars: "getWebinarsByCategory",
		}),
    },
    
    async created() {
        //await this.initialData();
    },

    methods: {
        ...mapActions("landing", {
			getWebinarsByCategoryStore: "getWebinarsByCategory",
		}),

        async initialData() {
            if (this.webinars.length === 0) {
                await this.getWebinarsByCategoryStore();
                if (this.webinars.length > 0) this.loaded = false;
            };
        },

        updateSection(keySection) {
            let scroll = scroller();
            scroll(`#${keySection}`);
            this.activeSection = keySection;
        },
        
        handleNavbarMobile(value) {
            this.activeNavbarMobile = value;
        }
    },
};
</script>
