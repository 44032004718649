import moment from "moment";

export const getFormatDate = (date) => {
    moment.locale("es");
    return moment(date.toDate()).format("MMM DD, YYYY");
}

export const getYearFromDate = (date) => { 
    moment.locale("es");
    return moment(date.toDate()).format("YYYY");
}

export const getMonthFromDate = (date) => {
    moment.locale("es");
    return moment(date).format("M");
}

export const getDayFromDate = (date) => { 
    moment.locale("es");
    return moment(date.toDate()).format("D");
}



