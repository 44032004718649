<template>
    <div>
        <span slot="title" class="title title--level-2 cl--primary-blue full-width flex-row center-row-center headerPlan">
            {{ $t('PLAN_LINEBACKER.TITLE') }}
        </span>
        <span slot="title" class="title title--level-3 cl--primary-blue full-width flex-row center-row-center">
            {{ $t('PLAN_LINEBACKER.SUBTITLE') }}
        </span>

        <div class="containerForm full-width full-height" style="padding: 24px">
            <a-form layout="vertical">
                 <a-row :gutter="24" class="center-row-center">
                    <a-col :span="12" :md="12" :sm="24" :xs="24">

                        <div class="bg--secondary-grey containerBenefits">
                            <a-space :size="24" direction="vertical">
                                <a-tabs default-active-key="1" class="full-width tabsPlan">
                                    <a-tab-pane key="1">
                                        <span
                                            slot="tab" 
                                            class="title text--level-3 planType"
                                        >
                                            {{ $t(plan.type[0]) }}
                                        </span>

                                        <span class="title text--level-2 cl--primary-blue tp-bold price shadow">
                                            {{ `${$t('PLAN_LINEBACKER.PRICE')}: ${$t(plan.price.ies)}` }}
                                        </span>
                                        
                                        <a-list item-layout="horizontal" :data-source="plan.benefits.ies" class="benefits">
                                            <a-list-item slot="renderItem" slot-scope="benefit">
                                                <a-list-item-meta>
                                                    <span slot="title" class="title text--level-2 cl--primary-blue">
                                                        {{ $t(`PLAN_LINEBACKER.BENEFITS.${benefit}.NAME`) }}
                                                    </span>
                                                    <span slot="description" class="text text--level-3">
                                                        {{ $t(`PLAN_LINEBACKER.BENEFITS.${benefit}.DESCRIPTION`) }}
                                                    </span>
                                                </a-list-item-meta>
                                            </a-list-item>
                                        </a-list>

                                    </a-tab-pane>
                                    <a-tab-pane key="2">
                                        <span 
                                            slot="tab" 
                                            class="title text--level-3 planType"
                                        >
                                            {{ $t(plan.type[1]) }}
                                        </span>

                                        <span class="title text--level-2 cl--primary-blue tp-bold price shadow">
                                            {{ `${$t('PLAN_LINEBACKER.BENEFIT')}` }}
                                        </span>

                                        <a-list item-layout="horizontal" :data-source="plan.benefits.networks" class="benefits">
                                            <a-list-item slot="renderItem" slot-scope="benefit">
                                                <a-list-item-meta>
                                                    <span slot="title" class="title text--level-2 cl--primary-blue">
                                                        {{ $t(`PLAN_LINEBACKER.BENEFITS.${benefit}.NAME`) }}
                                                    </span>
                                                    <span slot="description" class="text text--level-3">
                                                        {{ $t(`PLAN_LINEBACKER.BENEFITS.${benefit}.DESCRIPTION`) }}
                                                    </span>
                                                </a-list-item-meta>
                                            </a-list-item>
                                        </a-list>
                                    </a-tab-pane>
                                </a-tabs>
                            </a-space>
                        </div>

                        <a-list item-layout="horizontal" :data-source="plan.conditions" >
                            <a-list-item slot="renderItem" slot-scope="condition">
                                <a-list-item-meta>
                                    <span slot="title" class="text text--level-3 cl--primary-blue" style="font-size: 12px; font-style: italic">
                                        {{ "* " + $t(`PLAN_LINEBACKER.CONDITIONS.${condition}`) }}
                                    </span>
                                </a-list-item-meta>
                            </a-list-item>
                        </a-list>

                    </a-col>
                    <a-col :span="12" :md="12" :sm="24" :xs="24">
                        <FormPlan />
                    </a-col>
                </a-row>
            </a-form>
        </div>
    </div>
</template>

<script>
import FormPlan from "@/components/home/FormPlan";
export default {
    data: () => {
        return {
            plan: {
                type: [
                    "PLAN_LINEBACKER.TYPE.IES",
                    "PLAN_LINEBACKER.TYPE.NETWORKS"
                ],
                price: {
                    ies: "PLAN_LINEBACKER.PRICE_IES",
                    networks: "PLAN_LINEBACKER.PRICE_NETWORKS"
                },
                benefits: {
                    ies: [
                        "EXTERNAL_SCHEDULE",
                        "EVENTS_RECTORAL",
                        "CANAL_KTDRA",
                        "MENTORING_RECTORAL",
                        "TOOL_BOX_RECTORAL",
                        "MATCH_RECTORAL",
                        "LIBRARY_RECTORAL",
                        "TRAINING_PROGRAMS",
                        "BOARD_NEWS",
                        "DATA_BASE"
                    ],
                    networks: [
                        "EXTERNAL_SCHEDULE",
                        "EVENTS_RECTORAL_PRIVATES",
                        "CANAL_KTDRA",
                        "MATCH_RECTORAL",
                        "LIBRARY_RECTORAL",
                        "TRAINING_PROGRAMS_PRIVATES",
                        "BOARD_NEWS",
                        "DATA_BASE"
                    ]
                },
                conditions: [
                    "DIFFUSION"
                ]
            },
            loading: false
        }        
    },
    methods: {
        changeStateModal(value) {
            this.$emit("changeStateModal", value)
        },

        async sendLinebackerRequest() {
            this.loading = true;
            this.loading = false;
        }
    },
    components: {
        FormPlan,
    },
}
</script>

<style lang="scss">
@import '@/styles/scss/config.scss';

.containerPlan {

    .headerPlan {
        padding: 40px 24px 0px 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-modal {
        width: 1000px !important;
        padding: 0 !important;
    }

    .tabsPlan {
        .ant-tabs-nav {
            .ant-tabs-tab {
                margin: 0 !important;
                color: $color-primary !important;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
            }   
            .ant-tabs-tab-active {
                font-weight: 400;
                background: $color-primary;
                border-radius: 4px 4px 0 0;
            }
        
            .ant-tabs-ink-bar {
                background: $color-primary;
            }
        }
    }

    .planType {
        padding: 4px 24px;
    }

    .containerBenefits {
        padding: 24px !important;
        border-radius: 8px;
        
        .benefits {
            max-height: 400px;
            overflow: scroll;
        }

    }

    .price {
        background: $color-yellow;
        padding: 8px 16px;
        border-radius: 8px;
        margin: 16px 0;
        width: fit-content !important;
    }

}
</style>