<template>
    <a-layout-header class="full-width shadow containerNav">

        <a-row justify="space-around" align="middle" class="navbar">
            <a-col :span="12" class="brand">
                <a-space class=full-width direction=horizontal :size="32">
                    <a-avatar
                        shape="square"
                        :src="logo_svg"
                        class="logo"
                        @click="goToHome()"
                    />

                    <div class="menu flex-row start-row-center">
                        <a-dropdown>
                            <a-button type="link" size="small" icon="caret-down">
                                <span class="text text--level-3 tp-medium cl--primary-blue">
                                    {{ $t('NAVBAR.MENU') }}
                                </span>
                            </a-button>
                            <a-menu slot="overlay" class="bg--secondary-white container-overlay-menu">
                                <a-row align="middle">
                                    <a-col :span="4" style="border-right: 'solid 1px @color-grey-alt-1'">
                                        <a-menu-item
                                            v-for="(item, index) in menu"
                                            :key="index"
                                            @mouseover="hoverMenu(index)"
                                            @click="item.path ? goToPage(item) : null"
                                            class="item-menu"
                                        >
                                            <a-space direction="horizontal" :size="8">
                                                <a-icon :type="item.icon" :style="{ fontSize: '20px' }" />
                                                <span class="text text--level-2 cl--primary-blue">
                                                    {{ $t(item.name) }}
                                                </span>
                                            </a-space>
                                        </a-menu-item>
                                    </a-col>
                                    <a-col :span="6" class="container-items">
                                        <a-menu-item
                                            v-for="(item, index) in subMenuActive"
                                            :key="index"
                                            class="item-menu"
                                            @click="item.path ? goToPage(item) : null"
                                        >
                                            <span class="text text--level-2 cl--primary-blue">
                                                {{ $t(item.name) }}
                                            </span>
                                        </a-menu-item>
                                    </a-col>
                                    <a-col :span="14" class="flex-column center-column-center full-height" style="padding: 64px">
                                        <a-space direction="vertical" :size="8">
                                            <span class="title title--level-2 cl--primary-blue full-width center">
                                                {{ $t('WELCOME_TO_RECTORAL_BOARD') }}
                                            </span>
                                            <span class="text text--level-2 cl--primary-blue full-width center">
                                                {{ $t('WELCOME_MESSAGE') }}
                                            </span>
                                        </a-space>
                                    </a-col>
                                </a-row>
                            </a-menu>
                        </a-dropdown>
                        <a-divider type="vertical" />
                        <a-button type="link" size="small" @click="goToRegister()">
                            <span class="text text--level-3 tp-medium cl--primary-blue">
                                {{ $t('NAVBAR.REGISTER') }}
                            </span>
                        </a-button>
                        <a-divider type="vertical" />
                        <a-button type="link" size="small" @click="goToLogin()">
                            <span class="text text--level-3 tp-medium cl--primary-blue">
                                {{ $t('NAVBAR.LOGIN') }}
                            </span>
                        </a-button>
                    </div>
                </a-space>
            </a-col>
            <a-col :span="12" class=language>
                <LocaleSwitcher/>
            </a-col>
            <a-col :span="12" class="menuMobile flex-row end-row-center">
                <a-button type="link" icon="menu" @click="changeStatusNavbarMobile()"/>
            </a-col>
        </a-row>
    </a-layout-header>
</template>

<style lang="scss">
@import '@/styles/scss/config.scss';
@import '@/styles/scss/components/navbar.scss';
</style>

<script>
import { IMAGES } from '@/constants/images'
import { Dropdown } from 'ant-design-vue';
import { DASHBOARD_URL } from '@/config/environments';

export default {
	data: function() {
		return {
            logo_svg: IMAGES.logos.horizontal_svg,
            statusNavbarMobile: false,
            currentRoute: {},
            menu: [
                {
                    name: "NAVBAR.HOME",
                    icon: "home",
                    path: "/"
                },
                {
                    name: "NAVBAR.INSTITUTIONAL",
                    icon: "bank",
                    path: "/institutional"
                },
                {
                    name: "NAVBAR.BRIEFCASE",
                    icon: "appstore",
                    path: "/briefcase"
                },
                {
                    name: "NAVBAR.SIGNATORIES",
                    icon: "gold",
                    path: "/offer"
                },
                {
                    name: "NAVBAR.WEBINARS",
                    icon: "desktop",
                    path: "/webinars"
                },
                {
                    name: "NAVBAR.KTDRA_CHANNEL",
                    icon: "solution",
                    path: "/ktdra-channel"
                },
                {
                    name: "NAVBAR.LIBRARY",
                    icon: "hdd",
                    path: "/library"
                }
                
            ],
            subMenuActive: [],
            submenu: [
                {
                    indexMenu: 0,
                    active: false,
                    items: [
                        {
                            name: "NAVBAR.SUBMENU.HOME.ABOUT_US",
                            path: "/",
                            keyPath: "about-us"
                        },
                        {
                            name: "NAVBAR.SUBMENU.HOME.EXTERNAL_SCHEDULE",
                            path: "/",
                            keyPath: "external-schedule"
                        },
                        {
                            name: "NAVBAR.SUBMENU.HOME.TECHNICAL_CONTRIBUTIONS",
                            path: "/",
                            keyPath: "technical-contributions"
                        },
                        {
                            name: "NAVBAR.SUBMENU.HOME.INSTITUTIONAL_SUPPORTERS",
                            path: "/",
                            keyPath: "institutional-supporters"
                        },
                        {
                            name: "NAVBAR.SUBMENU.HOME.RECTORAL_BOARD_EVENTS",
                            path: "/",
                            keyPath: "rectoral-board-events"
                        },
                        /* {
                            name: "NAVBAR.SUBMENU.HOME.EXTERNAL_EVENTS_PARTICIPATION",
                            path: "/",
                            keyPath: "external-events-participation"
                        }, */
                        {
                            name: "NAVBAR.SUBMENU.HOME.SIGNATORY_MEMBERS",
                            path: "/",
                            keyPath: "signatory-members"
                        },
                        {
                            name: "NAVBAR.SUBMENU.HOME.WEBINARS_RECTORAL",
                            path: "/",
                            keyPath: "webinars-rectoral"
                        },
                        {
                            name: "NAVBAR.SUBMENU.HOME.BRIEFCASE",
                            path: "/",
                            keyPath: "briefcase"
                        },
                        {
                            name: "NAVBAR.SUBMENU.HOME.LIBRARY",
                            path: "/",
                            keyPath: "library"
                        },
                        {
                            name: "NAVBAR.SUBMENU.HOME.CHANNEL_KTDRA",
                            path: "/",
                            keyPath: "ktdra"
                        },
                        {
                            name: "NAVBAR.SUBMENU.HOME.BOARD_NEWS",
                            path: "/",
                            keyPath: "board-news"
                        },
                    ],
                },
                {
                    indexMenu: 1,
                    active: false,
                    items: [
                        {
                            name: "NAVBAR.SUBMENU.INSTITUTIONAL.GOVERNANCE",
                            path: "/institutional/",
                            keyPath: "governance"
                        },
                        {
                            name: "NAVBAR.SUBMENU.INSTITUTIONAL.IDENTITY",
                            path: "/institutional/",
                            keyPath: "identity"
                        },
                        {
                            name: "NAVBAR.SUBMENU.INSTITUTIONAL.PRINCIPLES",
                            path: "/institutional/",
                            keyPath: "rectoral-principles"
                        },
                        {
                            name: "NAVBAR.SUBMENU.INSTITUTIONAL.ACTION_SECTORS",
                            path: "/institutional/",
                            keyPath: "action-sectors"
                        },
                        {
                            name: "NAVBAR.SUBMENU.INSTITUTIONAL.TARGET_AUDIENCES",
                            path: "/institutional/",
                            keyPath: "target-audience"
                        },
                        {
                            name: "NAVBAR.SUBMENU.INSTITUTIONAL.INSTITUTIONAL_POLITICS",
                            path: "/institutional/",
                            keyPath: "institutional-politics"
                        },
                        {
                            name: "NAVBAR.SUBMENU.INSTITUTIONAL.INSIGHT",
                            path: "/institutional/",
                            keyPath: "insight"
                        },
                    ],
                },
                {
                    indexMenu: 2,
                    active: false,
                    items: [
                        {
                            name: "NAVBAR.SUBMENU.BRIEFCASE.MENTORING_180",
                            path: "/briefcase/",
                            keyPath: "mentoring-180"
                        },
                        {
                            name: "NAVBAR.SUBMENU.BRIEFCASE.LEADERSHIP",
                            path: "/briefcase/",
                            keyPath: "leadership"
                        },
                        {
                            name: "NAVBAR.SUBMENU.BRIEFCASE.TOOL_BOX_RECTORAL",
                            path: "/briefcase/",
                            keyPath: "tool-box-rectoral"
                        },
                        {
                            name: "NAVBAR.SUBMENU.BRIEFCASE.SCHEMA",
                            path: "/briefcase/",
                            keyPath: "schema"
                        },
                    ],
                },
                {
                    indexMenu: 4,
                    active: false,
                    items: [
                        {
                            name: "NAVBAR.SUBMENU.WEBINARS.LEGACY",
                            path: "/webinars/legacy",
                        },
                        {
                            name: "NAVBAR.SUBMENU.WEBINARS.THINK_TANK_BOARD",
                            path: "/webinars/think-tank-board",
                        },
                        {
                            name: "NAVBAR.SUBMENU.WEBINARS.BRANDS_RECTORAL",
                            path: "/webinars/rectoral-brands",
                        },
                        {
                            name: "NAVBAR.SUBMENU.WEBINARS.ROAD_MAP_RECTORAL",
                            path: "/webinars/road-map-rectoral",
                        },
                    ],
                },
                {
                    indexMenu: 6,
                    active: false,
                    items: [
                        {
                            name: "NAVBAR.SUBMENU.LIBRARY.REPORTS",
                            path: "/library/reports",
                        },
                        {
                            name: "NAVBAR.SUBMENU.LIBRARY.BOOKS",
                            path: "/library/books",
                        },
                        {
                            name: "NAVBAR.SUBMENU.LIBRARY.BENCHMARKIES",
                            path: "/library/conferences",
                        },
                        {
                            name: "NAVBAR.SUBMENU.LIBRARY.TRENDS",
                            path: "/library/trends",
                        },
                    ],
                },
            ],
		};
	},
	components: { 'a-dropdown': Dropdown },
    created() {
        this.subMenuActive = this.submenu[0].items;
    },
	methods: {
        goToHome() {
            this.$router.push({ path: "/" });
        },
        goToRegister() {
            const url = `${DASHBOARD_URL}/sign-up`;
            window.open(url, "_blank");
        },
        goToLogin() {
            const url = `${DASHBOARD_URL}/login`;
            window.open(url, "_blank");
        },
        hoverMenu(index) {
            const subMenu = this.submenu.filter((sb) => sb.indexMenu === index);
            if (subMenu.length ){
                this.subMenuActive = subMenu[0].items;
            } else {
                this.subMenuActive = [];
            }
        },
        updateSection() {
            const path = this.currentRoute.path;
            const keyPath = this.currentRoute.keyPath;
            this.$router.push({ path, query: { section: keyPath } });
            this.$emit("updateSection", keyPath);
        },
        goToPage(route) {
            this.currentRoute = route;
            const path = route.path;
            const keyPath = route.keyPath;
            this.$router.push({ path, query: { section: keyPath } });
            this.$emit("updateSection", keyPath);
        },
        changeStatusNavbarMobile() {
            this.statusNavbarMobile = !this.statusNavbarMobile;
            this.$emit("handleNavbarMobile", this.statusNavbarMobile);
        }
	},
};
</script>
