import Vue from 'vue';
import Vuex from 'vuex';

import landing from "./modules/landing/index";
import users from "./modules/users/index";
import register from "./modules/register/index";
import device from './modules/device';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        landing: landing,
        users: users,
        register: register,
        device: device,
    },
    strict: false
});