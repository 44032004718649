<template>
    <div class="mainContainer" style="height: auto">
        <div class="containerSections" style="position: relative">
            <a-space 
                :size="0" 
                direction="vertical" 
                class="full-width containerMenu"
            >
                <a-row 
                    class="full-width containerCards" 
                    justify="space-between" 
                    align="middle"
                >
                    <a-col
                        class="full-height"
                        span="6"
                        :key="index"
                        v-for="(section, index) in sections.list"
                        @click="changeSectionActive(index)"
                    >
                        <a-card :class="getSectionClass(index)">
                            <span slot="title" class="title text--level-3">
                                {{ $t(`${section}`) }}
                            </span>
                        </a-card>    
                    </a-col>
                </a-row>
            </a-space>
        </div>
        <div class="containerContent">
            <Mentoring          v-if="sections.active === 0" />
            <Leadership         v-if="sections.active === 1" />
            <ToolBoxRectoral    v-if="sections.active === 2" />
            <Schema             v-if="sections.active === 3" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/scss/config.scss';
</style>

<script>
import Mentoring from "./subcomponents/Mentoring";
import Leadership from "./subcomponents/Leadership";
import ToolBoxRectoral from "./subcomponents/ToolBoxRectoral";
import Schema from "./subcomponents/Schema";

export default {
    name: "briefcaseSections",
    data: () => {
        return {
            keySections: [
                "mentoring-180",
                "leadership",
                "tool-box-rectoral",
                "schema"
            ],
            sections: {
				active: 0,
				size: 4,
				list: [
                    'BRIEFCASE.SECTIONS.MENTORING_180.TITLE',
                    'BRIEFCASE.SECTIONS.LEADERSHIP.TITLE',
                    'BRIEFCASE.SECTIONS.TOOL_BOX_RECTORAL.TITLE',
                    'BRIEFCASE.SECTIONS.SCHEMA.TITLE',
				]
			}
        }
    },

    components: {
        Mentoring,
        Leadership,
        ToolBoxRectoral,
        Schema
    },

    methods: {
        getSectionClass(position) {
            return {
                cardSections: true,
				active: this.sections.active == position ? true : false
			};
        },
        changeSectionActive(key) {
			this.sections.active = key;
		}
    },

    props: {
        activeSection: {
            type: String,
            default: "mentoring-180"
        }
    },
    watch: {
        activeSection: function(index) {
			this.sections.active = this.keySections.indexOf(index);
		}
    }
}
</script>