<template>
    <div 
        class="containerPortrait flex-column center-column-center"
        :style="getBackground()"
    >
        <div class="__image"/>
        <div class="infoPortrait flex-column center-column-center">
            <span class="left title cl--secondary-white uppercase">
                {{ $t(header) }}
            </span>
            <span class="title title--level-3 cl--secondary-white tp-light justify">
                {{ $t(description) }}
            </span>
        </div>
    </div>
</template>

<style lang="scss">
@import '@/styles/scss/config.scss';
</style>

<script>
export default {
    props: {
        imageUrl: {
            type: String,
            default: ""
        },
        header: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        }
    },
    methods: {
        getBackground () {
            return {
                "background-image": "url(" + this.imageUrl + ")",
            };
        },
    },
};
</script>
