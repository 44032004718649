<template>
	<div>
		<Navbar 
            @updateSection="updateSection" 
            @handleNavbarMobile="(value) => handleNavbarMobile(value)"
        />
        <NavbarMobile 
            :active="activeNavbarMobile"
            @updateSection="(value) => updateSection(value)"
            @close="() => activeNavbarMobile = false"
        />
		<Governance id="governance" />
		<Identity id="identity" />
		<Principles id="rectoral-principles" />
		<Sectors id="action-sectors"/>
		<TargetAudiences id="target-audience"/>
		<Politics id="institutional-politics"/>
		<Insight id="insight"/>
		<Footer />
	</div>
</template>

<script>
import Governance from "@/components/institucional/Governance";
import Identity from "@/components/institucional/Identity";
import Principles from "@/components/institucional/Principles";
import Sectors from "@/components/institucional/Sectors";
import TargetAudiences from "@/components/institucional/TargetAudiences";
import Politics from "@/components/institucional/Politics";
import Insight from "@/components/institucional/Insight";

import { scroller } from "vue-scrollto/src/scrollTo";

export default {
	name: "Institutional",
	components: {
        Governance,
        Identity,
        Principles,
		Sectors,
        TargetAudiences,
		Politics,
		Insight,
	},
    data: () => {
        return {
            activeSection: "governance",
            activeNavbarMobile: false,
        };
    },
    mounted() {
        const fullPath = this.$route.fullPath;
        const section = fullPath.split("=")[1];
        this.updateSection(section);
    },
    methods: {
        updateSection(keySection) {
            let scroll = scroller();
            scroll(`#${keySection}`);
            this.activeSection = keySection;
        },
        handleNavbarMobile(value) {
            this.activeNavbarMobile = value;
        }
    },
};
</script>
