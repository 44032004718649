<template>
    <div>
        <a-form-item 
            class="containerPhone flex-row center-row-center full-width"
            :label="$t('FORMS.COUNTRY')"
        >
            <a-select 
                default-value="Ecuador" 
                class="full-height code full-width"
                showSearch
                optionFilterProp="children"
                :filter-option="filterCountry"
                @change="changeCountry"
            >
                <a-select-option
                    v-for="(value, index) in countries"
                    :key="`country_${index}_${value.name}`"
                    :value="value.name"
                    class="flex-row center-row-center full-height"
                >
                    <a-avatar 
                        :src="value.flag" class="flagCode"
                        shape="square"
                    />
                    <span class="text text--level-3">{{ value.nativeName }}</span>
                </a-select-option>
            </a-select>
        </a-form-item>
    </div>
</template>

<script>
import countries from "@/constants/countries.json";
import * as utils from "@/utils/";
export default {
    data: () => {
        return {
            country: null,
            countries: [],
        };
    },

    beforeMount() {
        this.loadCountries();
    },

    methods: {
        loadCountries() {
            let aux = [];
            countries.map((country) => {
                if (country.name !== '' && country.nativeName !== '') {
                    aux.push({
                        flag: country.flag,
                        name: country.name,
                        nativeName: country.nativeName,
                    });
                }
            });
            this.countries = utils.removeDuplicates(aux, "name");
        },

        filterCountry(input, option) {
            return ( 
                option.componentOptions.propsData.value.indexOf(input) >=0
            );
        },

        changeCountry(newCountry) {
          this.$emit('changeCountry', newCountry);  
        },
    },
}
</script>