export default {
    
    SET_WELCOME_PHRASES(state, data_welcome_phrases) {
        state.welcomePhrases = data_welcome_phrases;
    },

    SET_LINE_BACKERS(state, data_line_backers) {
        state.lineBackers = data_line_backers;
    },

    SET_CONTRIBUTIONS(state, contributions, meta) {
        state.contributions.data = contributions;
        state.contributions.meta = meta;
    },

    SET_WEBINARS_CALENDAR(state, data_webinars_calendar) {
        state.webinarsCalendar = data_webinars_calendar;
    },

    SET_OUTSIDE_HOLDINGS(state, data_outside_holdings, meta) {
        state.outsideHoldings.data = data_outside_holdings;
        state.outsideHoldings.meta = meta;
    },

    SET_METRICS(state, data_metrics) {
        state.metrics = data_metrics;
    },

    SET_NEWS(state, data_news, meta) {
        state.news.data = data_news;
        state.news.meta = meta;
    },

    SET_ARTICLES(state, data_articles, data_meta) {
        state.articles.data = data_articles;
        state.articles.meta = data_meta;
        let categories = data_articles.map(article => article.categoryID);
        categories = categories.filter((category, index) => index === categories.findIndex((obj) => obj._id === category._id));
        state.articles.categories = categories;
    },

    SET_QUERY_ARTICLES(state, value) {
        state.articles.search = value;
    },

    SET_DEFAULT_ARTICLES(state) {
        state.articles.data = [];
        state.articles.categories = [];
        state.articles.search = "";
        state.articles.category = null;
        state.articles.meta = {
            page: 1,
            limit: 10,
            itemCount: 0,
            pageCount: 0,
            hasPreviousPage: false,
            hasNextPage: false,
        };
    },

    SET_ARTICLE_DETAIL(state, data_article) {
        state.article = data_article;
    },

    SET_ARTICLE_COMMENTS(state, data_comments) {
        state.comments = data_comments;
    },

    SET_PARTNERS(state, data_partners, data_meta) {
        state.partners.data = data_partners;
        state.partners.meta = data_meta;
        state.partners.totalCountries = data_partners.length;
    },

    SET_WEBINARS_BY_CATEGORY(state, data_webinars_by_category, data_meta) {
        state.webinarsByCategory.data = data_webinars_by_category;
        state.webinarsByCategory.total = data_webinars_by_category.length;
        state.webinarsByCategory.meta = data_meta;
    },

    SET_DEFAULT_WEBINARS_BY_CATEGORY(state) {
        state.webinarsByCategory.data = [];
        state.webinarsByCategory.total = 0;
        state.webinarsByCategory.meta = {
            page: 1,
            limit: 10,
            itemCount: 0,
            pageCount: 0,
            hasPreviousPage: false,
            hasNextPage: false,
        };
    },

    SET_QUERY_PARTNERS(state, value) {
        state.partners.search = value;
    },

    SET_DEFAULT_PARTNERS(state) {
        state.partners.data = [];
        state.partners.search = "";
        state.partners.country = null;
        state.partners.totalCountries = 0;
        state.partners.meta = {
            page: 1,
            limit: 10,
            itemCount: 0,
            pageCount: 0,
            hasPreviousPage: false,
            hasNextPage: false,
        };
    },

    SET_KPIS(state, data_kpis) {
        state.KPIS = data_kpis;
    },

    SET_BOARD_NEWS(state, data_board_news) {
        state.boardNews = data_board_news;
    },

    SET_DEFAULT_WEBINARS_CALENDAR(state) {
        state.webinarsCalendar = [];
    },

    SET_DEFAULT_OUTSIDE_HOLDINGS(state) {
        state.outsideHoldings.data = [];
        state.outsideHoldings.meta = {
            page: 1,
            limit: 10,
            itemCount: 0,
            pageCount: 0,
            hasPreviousPage: false,
            hasNextPage: false,
        };
    },
    
    SET_LIBRARY(state, data_library) {
        state.libraries.data = data_library.data;
        state.libraries.meta = data_library.meta;

        let categories = data_library.data.map(item => item.categoryID);
        categories = categories.filter((category, index) => index === categories.findIndex((obj) => obj._id === category._id));
        
        state.libraries.categories = categories;
    },

    SET_DEFAULT_LIBRARY(state) {
        state.libraries.data = [];
        state.libraries.categories = [];
        state.libraries.search = "";
        state.libraries.category = null;
        state.libraries.meta = {
            page: 1,
            limit: 10,
            itemCount: 0,
            pageCount: 0,
            hasPreviousPage: false,
            hasNextPage: false,
        };
    },

    SET_QUERY_LIBRARY(state, value) {
        state.libraries.search = value;
    },

    SET_EXTERNAL_PARTICIPATIONS(state, data_participations) {
        state.participations = data_participations;
    }
}