<template>
    <div class="full-width full-height" style="position: relative">

        <Headers
            :showLine=headers.line
            :title=headers.title
            align="left"
        />


        <div class="containerParticipations full-width">
            <splide :options="slideOptions" :slides="outsideHoldings.data">
                <splide-slide v-for="outsideHolding of outsideHoldings.data" :key="outsideHolding._id">
                    <div class="flex-column center-column-center participation shadow">
                        <a-space direction="vertical" :size="4" class="full-width">
                            <span class="title text--level-2 cl--primary-blue">
                                {{ outsideHolding.title }}
                            </span>

                            <span class="text text--level-3 cl--primary-blue label">
                                {{ $t('HOME.EVENTS.PARTICIPATION.ORGANIZERS') }}
                            </span>
                            <span class="title text--level-3 cl--primary-blue">
                                {{ outsideHolding.institute }}
                            </span>

                            <span class="text text--level-3 cl--primary-blue label">
                                {{ $t('HOME.EVENTS.PARTICIPATION.WE_PARTICIPATE_AS') }}
                            </span>
                            <span class="title text--level-3 cl--primary-blue bold">
                                 {{ $t(`HOME.EVENTS.${outsideHolding.type}`) }}
                            </span>

                            <span class="text text--level-3 cl--primary-blue label">
                                {{ $t('HOME.EVENTS.PARTICIPATION.MODALITY') }}
                            </span>
                            <span class="title text--level-3 cl--primary-blue">
                                {{ $t(`HOME.EVENTS.${outsideHolding.modality}`) }}
                            </span>
                            
                            <span class="text text--level-3 cl--primary-blue label">
                                {{ $t('HOME.EVENTS.PARTICIPATION.EVENT_DATE') }}
                            </span>
                            <span class="title text--level-3 cl--primary-blue date">
                                {{ formatDate(outsideHolding.eventDate) }}
                            </span>
                        </a-space>
                        <span
                            class="title text--level-3 cl--primary-blue link full-width center"
                            @click="goToOutsideHolding(outsideHolding.externalLink)" 
                            >
                            {{ $t('HOME.EVENTS.PARTICIPATION.GO_TO_EVENT')}}
                        </span>
                    </div>
                </splide-slide>
            </splide>
        </div>
    </div>
</template>

<script>
require("vue-simple-calendar/static/css/default.css");
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
    
import { mapActions, mapGetters } from "vuex";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb'
import 'moment/locale/pt-br'

export default {
    
    data: () => {
        return {
            headers: {
                title: "HOME.EVENTS.EXTERNAL_PARTICIPATIONS",
                line: true,
            },
            params: {
                limit: 100,
            },
            loading: true,
            slideOptions: {
                rewind: true,
                autoplay: true,
                perMove: 1,
                gap: "1rem",
                breakpoints: {
                    576: {
                        perPage: 1,
                    },
                    992: {
                        perPage: 2,
                    },
                    1600: {
                        perPage: 3,
                    },
                },
                perPage: 3,
                trimSpace: false,
            },
        }
    },

    components: {
        Splide,
        SplideSlide,
    },

    async created() {
        await this.initialData();
    },

    computed: {
        ...mapGetters("landing", {
			outsideHoldings: "getOutsideHoldings",
		}),
    },

    methods: {

        ...mapActions("landing", {
			getOutsideHoldingsStore: "getOutsideHoldings",
		}),
        
        async initialData() {
            if (this.outsideHoldings.data.length === 0) {
                await this.getOutsideHoldingsStore(this.params);
                this.loading = false;
            };
        },

        goToOutsideHolding(link) {
            window.open(link, '_blank');
        },

        formatDate (date) {
            moment.locale(this.$i18n.locale);
            return moment(date).format('LLLL');
        }
    }
}

</script>

<style lang="scss" scoped>
    @import '@/styles/scss/config.scss';
    @import '@/styles/scss/components/boardEvents.scss';

    .splide__arrow--prev {
        left: -3em !important;
    }

    .splide__arrow--next {
        right: -3em !important;
    }

    .splide__pagination {
        position: relative !important;
        bottom: 0 !important;
        margin-bottom: 24px !important;
    }
</style>