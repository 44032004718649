export const BASE_URL = process.env.VUE_APP_CENTRAL_BACKEND
export const API_URL = process.env.VUE_APP_RECTORAL_API
export const DASHBOARD_API_URL = process.env.VUE_APP_RECTORAL_DASHBOARD_API

export const routes = {
    //SESSION
    SIGNIN: '/signInLanding',
    GET_DATA_USER: '/getDataUser',
    REGISTER_USER: '/registerUser',

    //HOME
    GET_WELCOME_PHRASE: '/getWelcomePhrases',
    GET_LINE_BACKERS: '/getLineBackers',
    GET_SELF_EVENTS: '/getSelfEvents',
    GET_KPIS: '/getKPIS',
    GET_BOARD_NEWS: '/getBoardNews',
    GET_WEBINARS: '/getWebinars',
    GET_WEBINARS_BY_CATEGORY: '/getWebinarsByCategory',
    GET_PARTNERS: '/getPartners',
    GET_LIBRARY: '/getLibrary',
    GET_ARTICLES: '/getArticles',
    GET_CONTRIBUTIONS: '/getContributions',
    SEND_EMAIL_CONTACT: '/sendEmailContact',
    GET_EXTERNAL_PARTICIPATIONS: '/getExternalParticipations',
    GET_ARTICLE_DETAIL: '/getArticleDetail',

}

export const paths = {
    LANDING: '/landing',
    USER: '/users',
    SENDS: '/sends'
}