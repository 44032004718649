<template>
    <div class="comments--actions flex-row start-row-center">
        <a-space :size="4">
            <a-avatar
                :src="clap"
                :size="16"
                shape="square"
                style="vertical-align: middle;"
            />
            <span>
                {{ likes }}
            </span>
        </a-space>
        <a-space :size="4" v-if="opinions > 0">
            <a-avatar
                :src="opinion"
                :size="16"
                shape="square"
                style="vertical-align: middle;"
            />
            <span>
                {{ opinions }}
            </span>
            <span>
                {{ opinions === 1 ? $t('KTDRA_CHANNEL.OPINION') : $t('KTDRA_CHANNEL.OPINIONS') }}
            </span>
        </a-space>
        <a-space :size="4" v-if="shares">
            <a-avatar
                :src="shares"
                :size="16"
                shape="square"
                style="vertical-align: middle;"
            />
            <span>
                {{ shares }}
            </span>
            <span>
                {{ shares === 1 ? $t('KTDRA_CHANNEL.SHARED') : $t('KTDRA_CHANNEL.SHARES') }}
            </span>
        </a-space>
    </div>
</template>

<style lang="scss">
@import '@/styles/scss/config.scss';
</style>

<script>
import { IMAGES } from '@/constants/images';

export default {
    data: () => ({
        loaded: true,
        clap: IMAGES.dashboard.clap,
        opinion: IMAGES.dashboard.opinion,
    }),

    props: {
        likes: {
			type: Number,
			default: 0
		},
        opinions: {
			type: Number,
			default: 0
		},
        shares: {
			type: Number,
			default: 0
		}
    },
}
</script>