<template>
    <div class="mainContainer sections full-height full-width bg--secondary-grey" style="position:relative">

        <div
            :style="getBackground()"
            class="background full-width full-height"
        />

        <a-row
            class="full-width full-height center-row-center containerColumns"
            :gutter="[24,32]"
        >
            <a-col :span="12" :md="12" :sm="24" :xs="24" class="flex-column center-column-center ">

                <div class="styleImage">
                    <div class="circle"/>
                    <img 
                        :src="webinars.section"
                        class="image"
                    />
                </div>
            </a-col>

            <a-col :span="12" :md="12" :sm="24" :xs="24">

                <a-avatar
                    :size="70"
                    shape="square"
                    :src="webinars.icon"
                    class="icon"
                />

                <a-space :size="16" direction="vertical">
                    <Headers
                        :showLine=headers.line
                        :title=headers.title
                        :description=headers.description
                        :align="'right'"
                    />
                    <a-button
                        type="primary"
                        size="large"
                        style="float: right"
                        @click="goToSection()"
                    >
                        <span class="tp-regular uppercase">
                            {{ $t('HOME.WEBINARS.LINK')}}
                        </span>
                    </a-button>
                </a-space>

            </a-col>
        </a-row>
    </div>
</template>

<script>
import { IMAGES } from "@/constants/images.js";

export default {
    data: function() {
        return {
            headers: {
                title: "HOME.WEBINARS.WEBINARS_RECTORAL",
                description: "HOME.WEBINARS.DESCRIPTION",
                line: true
            }
        };
    },

    computed: {
        background () {
            return IMAGES.backgrounds.home
        },
        webinars () {
            return IMAGES.home.webinars
        }
    },
    methods: {
        goToSection () {
            this.$router.push("/webinars");
        },
        getBackground () {
            return {
                "background-image": "url(" + this.background + ")",
            };
        },
    },
};
</script>


<style lang="scss" scoped>
@import '@/styles/scss/config.scss';

.containerColumns {
    display: block;

    @include breakpoint(md) {
        display: flex;
    }
}

.background {
    left: 0;
    opacity: 0.2;
    position: absolute;
    top: 0;
}

.icon {
    display: none;
    position: absolute;
    right: -70px;
    top: 70px;

    @include breakpoint(lg) {
        display: flex;
    }
}

.styleImage {
    border: solid 2px $color-primary;
    padding: 8px;
    border-radius: 100%;
    width: 200px;
    height: 200px;
    position: relative;

    .circle {
        background: $color-primary;
        border-radius: 50%;
        height: 20px;
        position: absolute;
        right: 10px;
        top: 40px;
        width: 20px;
    }

    @include breakpoint(md) {
        width: 250px;
        height: 250px;
    }
    
    img {
        border-radius: 50%;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

</style>