<template>
    <div class="mainContainer sections full-width full-height bg--secondary-grey-alt-1">
        <Headers
            :showLine="headers.line"
            :title="headers.title"
            align="left"
        />

        <a-divider style="background: #1b4484"/>

        <a-row align="top" justify="space-between" :gutter="[32, 48]">
            <a-col :xs="24" :md="12">
                <a-space style="width: 100%" :size="16" direction="vertical">
                    <span class="title title--level-3 cl--primary-blue">
                        {{ $t(sections.sectionA.title) }}
                    </span>
                    <span class="text text--level-3 cl--primary-blue justify">
                        {{ $t(sections.sectionA.summary) }}
                    </span>
                </a-space>
            </a-col>
            <a-col :xs="24" :md="12">
                <a-space style="width: 100%" :size="16" direction="vertical">
                    <span class="title title--level-3 cl--primary-blue">
                        {{ $t(sections.sectionB.title) }}
                    </span>
                    <span class="text text--level-3 cl--primary-blue justify">
                        {{ $t(sections.sectionB.summary) }}
                    </span>
                </a-space>
            </a-col>
        </a-row>
    </div>
</template>

<script>
export default {
  data: function() {
    return {
        headers: {
            title: "INSTITUTIONAL.INSTITUTIONAL_POLITICS.HEADER",
            line: false
        },

        sections: {
            sectionA: {
                title: "INSTITUTIONAL.INSTITUTIONAL_POLITICS.CONFIDENTIALITY_POLITICS",
                summary: "INSTITUTIONAL.INSTITUTIONAL_POLITICS.CONFIDENTIALITY_POLITICS_SUMMARY"
            },
            sectionB: {
                title: "INSTITUTIONAL.INSTITUTIONAL_POLITICS.QA_POLITICS",
                summary: "INSTITUTIONAL.INSTITUTIONAL_POLITICS.QA_POLITICS_SUMMARY"
            }
        }
    };
  }
};
</script>
