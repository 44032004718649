var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar',{on:{"updateSection":_vm.updateSection,"handleNavbarMobile":(value) => _vm.handleNavbarMobile(value)}}),_c('NavbarMobile',{attrs:{"active":_vm.activeNavbarMobile},on:{"updateSection":(value) => _vm.updateSection(value),"close":() => _vm.activeNavbarMobile = false}}),_c('div',{staticClass:"first sections full-width full-height offer"},[_c('div',{staticClass:"header flex-column start-column-start"},[_c('a-space',{staticClass:"full-width",attrs:{"direction":"vertical","size":24}},[_c('span',{staticClass:"title title--level-2 cl--primary-blue left"},[_vm._v(" "+_vm._s(_vm.$t(_vm.header))+" ")]),_c('span',{staticClass:"title title--level-2 cl--primary-blue left"},[_vm._v(" "+_vm._s(_vm.$t(_vm.subHeader))+" ")])])],1),_c('a-row',{staticClass:"noPadding",attrs:{"justify":"space-between","align":"middle"}},_vm._l((_vm.sections),function(section){return _c('a-col',{key:`${section.data.keyName}`,class:`cardCategory ${section.category}`,attrs:{"span":8,"md":8,"sm":24,"xs":24},on:{"click":function($event){return _vm.goToRegister(section.category)}}},[_c('a-space',{attrs:{"direction":"vertical","size":24}},[_c('a-list-item-meta',{attrs:{"description":_vm.$t(`${section.data.target}`)}},[_c('span',{staticClass:"title title--level-3 left head",class:section.category,attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t(`MEMBERSHIPS.${section.category}`))+" ")]),_c('a-avatar',{class:`iconMembership ${section.category}`,attrs:{"slot":"avatar","src":section.logo,"shape":"square","size":64},slot:"avatar"})],1),_c('a-button',{class:`full-width button ${section.category}`,attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('ACTIONS.JOIN_ME'))+" ")]),_c('div',{staticClass:"benefits"},_vm._l((_vm.benefits),function(benefit,index){return _c('div',{key:`${section.category}_${index}`,class:{
                                'benefit': true,
                                'flex-column': true,
                                'center-column-center': true
                            }},[_c('a-icon',{class:{
                                    'icon': true,
                                    'no-active': !section.benefits.includes(benefit),
                                },style:(`color: ${section.data.color}`),attrs:{"type":"check-circle","theme":"filled"}}),_c('span',{staticClass:"text text--level-3"},[_vm._v(" "+_vm._s(_vm.$t(`OFFER.${benefit}`))+" ")])],1)}),0)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }